import { css, styled } from 'styled-components'
import { WhiteBoardType, mixin } from 'theme/mixin'
import { StyledPageTitleContainer } from '../PageTitle'
type WrapperType = {
    $form: 'main' | 'detail'
    $whiteBoard?: boolean
}
export const Wrapper = styled.div<WrapperType & WhiteBoardType>`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    ${(p) =>
        p.$whiteBoard &&
        css`
            ${mixin.WhiteBoard}
        `}
    ${(p) =>
        p.$form === 'main' &&
        css`
            padding: 3rem;
        `}
  ${(p) =>
        p.$form === 'detail' &&
        css`
            gap: 6rem;
            padding: 3rem 3.4rem 4rem;
            ${StyledPageTitleContainer} {
                padding-block-end: 0rem;
            }
        `}
`

export const WrapperTitle = styled.div`
    color: ${(p) => p.theme.grayScale.gray10};
    padding-block-end: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    span {
        font-size: inherit;
        font-weight: inherit;
    }
`
