import ArtFigk from "pages/projects/Figk/ArtFigk";
import ArtFigkDetail from "pages/projects/Figk/ArtFigk/Detail";
import ArtFigkRegist from "pages/projects/Figk/ArtFigk/Regist";
import { default as FigkWriter } from "pages/projects/Figk/Member";
import FigkWriterDetail from "pages/projects/Figk/Member/Detail";
import FigkNotice from "pages/projects/Figk/Notice";
import FigkNoticeDetail from "pages/projects/Figk/Notice/Detail";
import FigkNoticeRegist from "pages/projects/Figk/Notice/Regist";
import DetailSettlement from "pages/projects/Figk/Settlement/Detail";
import FigkTagManagement from "pages/projects/Figk/Tag";
import FigkTermsDetail from "pages/projects/Figk/Terms/Detail";
import FigkTermsRegist from "pages/projects/Figk/Terms/Regist";
import InstagramUpload from "pages/projects/Figk/TextFigk/InstagramUpload";
import TextFigkSending from "pages/projects/Figk/TextFigk/Sending";
import TextFigkSendingDetail from "pages/projects/Figk/TextFigk/Sending/Detail";
import TurnDown from "pages/projects/Figk/TextFigk/Sending/TurnDown";
import TotalTextFigk from "pages/projects/Figk/TextFigk/TotalText";
import TotalTextDetail from "pages/projects/Figk/TextFigk/TotalText/Detail";
import FigkVoting from "pages/projects/Figk/Voting/CurrentVoting";
import AdminVoting from "pages/projects/Figk/Voting/CurrentVoting/AdminVoting";
import MobileAdminVoting from "pages/projects/Figk/Voting/CurrentVoting/AdminVoting/MobileAdminVoting";
import AuditVoting from "pages/projects/Figk/Voting/CurrentVoting/AuditVoting";
import FigkVotingDetail from "pages/projects/Figk/Voting/CurrentVoting/Detail";
import FigkPastVoting from "pages/projects/Figk/Voting/PastVoting";
import { Outlet } from "react-router-dom";
import { ROUTER_PATH } from "shared/Router/router";

const ROOT = `${ROUTER_PATH.COMMON.DASHBOARD}/${ROUTER_PATH.PROJECT_ROOT.FIGK}`;
export const ZER01NE_CONSTANT = {
  TOTAL: "total",

  PROGRAM: "program",

  PROJECT: "project",
};

export const FIGK_CONSTANT = {
  REGIST: "regist",
  // 텍스트픽
  TEXT_FIGK: "text_figk",
  // 텍스트픽 서브
  TOTAL: "total",
  SENDING: "sending",
  TURNDOWN: "turndown",
  VOTING: "voting",
  ADMIN_VOTING: "admin_voting",
  AUDIT_VOTING: "audit_voting",
  UPLOAD_IMAGE: "upload_image",
  // 아트픽
  ART_FIGK: "art_figk",
  //투표관리
  CURRENT_VOTING: "current_voiting",
  PAST_VOTING: "past_voiting",
  //태그 관리
  FIGK_TAG_MANAGEMENT: "figk_tag",
  //정산 관리
  FIGK_SETTLEMENT: "settlement",
  //공지 관리
  FIGK_NOTICE: "notice",
  //약관 관리
  FIGK_TERMS: "terms",
  //회원 관리
  FIGK_MEMBER: "member",
  FIGK_WRITER: "writer",
  FIGK_INVITE: "invite",
};

export const FIGK_PATH = {
  // TEXT_FIGK 메뉴
  TOTAL: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.TOTAL}`,
  SENDING: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.SENDING}`,
  TURNDOWN: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.SENDING}/${FIGK_CONSTANT.TURNDOWN}`,
  VOTING: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.VOTING}`,
  ADMIN_VOTING: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.ADMIN_VOTING}`,
  MOBILE_ADMIN_VOTING: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.ADMIN_VOTING}/mobile`,
  AUDIT_VOTING: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.AUDIT_VOTING}`,
  UPLOAD_IMAGE: `${ROOT}/${FIGK_CONSTANT.TEXT_FIGK}/${FIGK_CONSTANT.UPLOAD_IMAGE}`,
  //정산관리 메뉴
  SETTLEMENT: `${ROOT}/${FIGK_CONSTANT.FIGK_SETTLEMENT}`,
  //투표 관리 메뉴
  CURRENT_VOTING: `${ROOT}/${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.CURRENT_VOTING}`,
  PAST_VOTING: `${ROOT}/${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.PAST_VOTING}`,
  //ART_FIGK 메뉴
  ART_FIGK: `${ROOT}/${FIGK_CONSTANT.ART_FIGK}`,
  ART_FIGK_REGIST: `${ROOT}/${FIGK_CONSTANT.ART_FIGK}/${FIGK_CONSTANT.REGIST}`,
  //공지 관리 메뉴
  FIGK_NOTICE: `${ROOT}/${FIGK_CONSTANT.FIGK_NOTICE}`,
  FIGK_NOTICE_REGIST: `${ROOT}/${FIGK_CONSTANT.FIGK_NOTICE}/${FIGK_CONSTANT.REGIST}`,
  // 약관 관리 메뉴
  FIGK_TERMS: `${ROOT}/${FIGK_CONSTANT.FIGK_TERMS}`,
  FIGK_TERMS_REGIST: `${ROOT}/${FIGK_CONSTANT.FIGK_TERMS}/${FIGK_CONSTANT.REGIST}`,
  //회원 관리 메뉴
  FIGK_MEMBER: `${ROOT}/${FIGK_CONSTANT.FIGK_MEMBER}`,
  FIGK_WRITER: `${ROOT}/${FIGK_CONSTANT.FIGK_MEMBER}/${FIGK_CONSTANT.FIGK_WRITER}`,
  FIGK_INVITE: `${ROOT}/${FIGK_CONSTANT.FIGK_MEMBER}/${FIGK_CONSTANT.FIGK_INVITE}`,
};

export const FIGK_ROUTER: any = [
  {
    path: ROUTER_PATH.PROJECT_ROOT.FIGK,
    element: <Outlet />,
    children: [
      // {
      //     index: true,
      //     element: <div>픽 대시보드인데용??</div>,
      //     title: '대시보드',
      //     icon: 'menu_dashboard',
      // },
      {
        path: FIGK_CONSTANT.TEXT_FIGK,
        element: <Outlet />,
        title: "TEXT FIGK",
        icon: "menu_writer",
        children: [
          {
            path: FIGK_CONSTANT.TOTAL,
            element: <TotalTextFigk />,
            title: "전체 글 관리",
          },
          {
            path: `${FIGK_CONSTANT.TOTAL}/:id/:p`,
            element: <TotalTextDetail />,
          },
          {
            path: FIGK_CONSTANT.SENDING,
            element: <TextFigkSending />,
            title: "송고 관리",
          },
          {
            path: `${FIGK_CONSTANT.SENDING}/:id`,
            element: <TextFigkSendingDetail />,
          },
          {
            path: `${FIGK_CONSTANT.SENDING}/${FIGK_CONSTANT.TURNDOWN}`,
            element: <TurnDown />,
          },

          {
            path: `${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.ADMIN_VOTING}`,
            element: <AdminVoting />,
          },
          {
            path: `${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.ADMIN_VOTING}/mobile`,
            element: <MobileAdminVoting />,
          },
          {
            path: `${FIGK_CONSTANT.VOTING}/${FIGK_CONSTANT.AUDIT_VOTING}`,
            element: <AuditVoting />,
          },
          {
            path: `${FIGK_CONSTANT.UPLOAD_IMAGE}`,
            element: <InstagramUpload />,
            title: "업로드 이미지 관리",
          },
        ],
      },
      {
        path: FIGK_CONSTANT.VOTING,
        title: "투표 관리",
        icon: "menu_video",
        children: [
          {
            path: FIGK_CONSTANT.CURRENT_VOTING,
            element: <FigkVoting />,
            title: "현재 투표 관리",
          },
          {
            path: `${FIGK_CONSTANT.CURRENT_VOTING}/detail/:id`,
            element: <FigkVotingDetail />,
          },
          {
            path: FIGK_CONSTANT.PAST_VOTING,
            element: <FigkPastVoting />,
            title: "지난 투표 관리",
          },
          {
            path: `${FIGK_CONSTANT.PAST_VOTING}/detail/:id`,
            element: <FigkVotingDetail />,
          },
        ],
      },
      {
        path: FIGK_CONSTANT.ART_FIGK,
        element: <ArtFigk />,
        title: "ART FIGK",
        icon: "menu_video",
      },
      {
        path: `${FIGK_CONSTANT.ART_FIGK}/:id`,
        element: <ArtFigkDetail />,
      },
      {
        path: `${FIGK_CONSTANT.ART_FIGK}/${FIGK_CONSTANT.REGIST}`,
        element: <ArtFigkRegist />,
      },
      {
        path: FIGK_CONSTANT.FIGK_TAG_MANAGEMENT,
        element: <FigkTagManagement />,
        title: "태그 관리",
        icon: "menu_tag",
      },
      // {
      //   path: FIGK_CONSTANT.FIGK_SETTLEMENT,
      //   element: <Settlement />,
      //   title: "정산 관리",
      //   icon: "menu_finance",
      // },
      {
        path: `${FIGK_CONSTANT.FIGK_SETTLEMENT}/:id`,
        element: <DetailSettlement />,
      },
      {
        path: FIGK_CONSTANT.FIGK_NOTICE,
        element: <FigkNotice />,
        title: "공지 관리",
        icon: "menu_notice",
      },
      {
        path: `${FIGK_CONSTANT.FIGK_NOTICE}/:id`,
        element: <FigkNoticeDetail />,
      },
      {
        path: `${FIGK_CONSTANT.FIGK_NOTICE}/${FIGK_CONSTANT.REGIST}`,
        element: <FigkNoticeRegist />,
      },
      // {
      //   path: FIGK_CONSTANT.FIGK_TERMS,
      //   element: <FigkTermsManagement />,
      //   title: "약관 관리",
      //   icon: "menu_document",
      // },
      {
        path: `${FIGK_CONSTANT.FIGK_TERMS}/:id`,
        element: <FigkTermsDetail />,
      },
      {
        path: `${FIGK_CONSTANT.FIGK_TERMS}/${FIGK_CONSTANT.REGIST}`,
        element: <FigkTermsRegist />,
      },
      {
        path: FIGK_CONSTANT.FIGK_MEMBER,
        element: <FigkWriter />,
        title: "작가 관리",
        icon: "menu_member",
      },
      {
        path: `${FIGK_CONSTANT.FIGK_MEMBER}/:id`,
        element: <FigkWriterDetail />,
      },
    ],
  },
];

export const ZER01NE_ROUTER: any = [
  {
    path: ROUTER_PATH.PROJECT_ROOT.ZER01NE,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <div>제로원 대시보드</div>,
        title: "대시보드",
        icon: "menu_dashboard",
      },
      {
        path: ZER01NE_CONSTANT.PROGRAM,
        element: <Outlet />,
        title: "PROGRAM",
        icon: "menu_writer",
        children: [
          {
            path: ZER01NE_CONSTANT.TOTAL,
            element: <TotalTextFigk />,
            title: "전체 글 관리",
          },
        ],
      },
      {
        path: ZER01NE_CONSTANT.PROJECT,
        element: <Outlet />,
        title: "PROJECT",
        icon: "menu_member",
        children: [
          {
            path: ZER01NE_CONSTANT.TOTAL,
            element: <TotalTextFigk />,
            title: "전체 글 관리",
          },
        ],
      },
    ],
  },
];
