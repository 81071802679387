import { baseApi } from 'api/Common'
import { GenericResponse } from 'models/Common'
import { apiHeader } from 'shared/function'
import { RequestLoginModel, UserPermission } from './../../models/Auth/index'
const auth = {
    login: async (data: RequestLoginModel) => {
        const response = await baseApi.post<GenericResponse>(`login`, data)
        return response.data
    },

    ping: async () => {
        const response = await apiHeader(true, 'b').post<GenericResponse>(`auth/ping`)
        return response.data
    },

    refreshAccess: async () => {
        const response = await apiHeader(false, 'b').post<GenericResponse>('auth/refreshAuth')
        return response.data
    },

    checkPermission: async (req: UserPermission) => {
        const response = await apiHeader(false, 'b').post<GenericResponse>('auth/check-permission')
        return response.data
    },
}

export default auth
