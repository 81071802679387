import AppendItem from 'components/atoms/AppendItem'
import { StyledAppendContents } from 'components/oraganisms/Append'
import { ChangeEvent, ForwardedRef, forwardRef, useRef, useState } from 'react'
import { formatBytes } from 'shared/function'
import { styled } from 'styled-components'

const StyledInputFile = styled.div`
    input[type='file'] {
        display: none;
    }
    label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 0.6rem;
        font-weight: 500;
        height: 2.6rem;
        border-radius: 0.4rem;
        padding-inline: 1rem;
        font-size: 1.2rem;
        border: 1px solid;
        border-color: #373b42;
        color: #373b42;
    }
`

interface InputFileProps {
    id: string
    fileLimitSize: number
    onChange?: (e: File | null) => void
    defaultValue?: string
}
const InputFile = ({ id, onChange, defaultValue, fileLimitSize }: InputFileProps, ref: ForwardedRef<HTMLInputElement>) => {
    const fileInput = useRef<HTMLInputElement>(null)

    const [files, setFiles] = useState<File | null>(null)
    const [cleaner, setCleaner] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)

    const cleanFile = () => {
        if (fileInput.current) fileInput.current.value = ''
        setCleaner(true)
        setFiles(null)
        onChange && onChange(null)
    }

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.currentTarget
        const files = (target.files as FileList)[0]

        if (files.size > fileLimitSize) {
            if (fileInput.current) fileInput.current.value = ''
            setFiles(null)
            setErrorMsg(`업로드 가능한 최대 용량은 ${formatBytes(fileLimitSize)} 입니다.`)
            return
        }

        onChange && onChange(files)
        setFiles(files ?? null)
        setErrorMsg(null)
    }

    return (
        <StyledInputFile>
            <input
                ref={fileInput}
                type='file'
                id={id}
                accept='video/*'
                onChange={onChangeFile}
            />
            <label htmlFor={id}>파일 업로드</label>
            {errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
            {files && (
                <StyledAppendContents>
                    <AppendItem
                        text={files.name}
                        onClick={cleanFile}
                    />
                </StyledAppendContents>
            )}
            {defaultValue && !cleaner && (
                <StyledAppendContents>
                    <AppendItem
                        text={defaultValue.split('/')[1]}
                        onClick={cleanFile}
                    />
                </StyledAppendContents>
            )}
        </StyledInputFile>
    )
}

export default forwardRef(InputFile)
