import { styled } from 'styled-components'

const CurrentRound = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    .current {
        font-weight: 500;
        color: ${(p) => p.theme.grayScale.gray8};
        padding-inline-end: 0.4rem;
        strong {
            font-weight: 700;
            font-size: 1.6rem;
            color: ${(p) => p.theme.color.primary};
            padding-inline-start: 0.6rem;
        }
    }
    .group {
        border: 1px solid #dfe9fd;
        border-radius: 999px;
        background-color: #f0f6fe;
        padding-inline: 0.8rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(p) => p.theme.color.primary};
    }
`

export const FigkCommon = {
    CurrentRound,
}
