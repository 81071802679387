import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import ImgFileUpload from 'components/atoms/Input/ImgFileUpload'
import InputFile from 'components/atoms/Input/InputFile'
import InputText from 'components/atoms/Input/InputText'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import Append from 'components/oraganisms/Append'
import { NOTICE_CONTEXT } from 'components/template/GlobalNotice/context'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { ArtFigkRegistModel } from 'models/ArtFigk'
import { GenericResponse } from 'models/Common'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { loadingState } from 'recoil/atom/modal'
import { FIGK_PATH } from 'shared/Router/Figk'
import { IMG_SIZE_MAX_LIMIT, VIDEO_SIZE_MAX_LIMIT } from 'shared/common'
import { isEmptyObj } from 'shared/function'

const ArtFigkRegist = () => {
    const { user } = useFigAuth()
    const { Notice, closeNotice } = useModal()
    const navigate = useNavigate()

    const setLoading = useSetRecoilState(loadingState)

    const fileRef = useRef<HTMLInputElement>(document.createElement('input'))
    const imgFileRef = useRef<HTMLInputElement>(document.createElement('input'))
    const [exitFlag, setExitFlag] = useState<boolean>(true)

    const [registData, setRegistData] = useState<ArtFigkRegistModel>({
        title: '',
        tags: '',
        jacketFileId: imgFileRef.current?.files?.length ? imgFileRef.current.files[0] : null,
        videoFileId: fileRef.current?.files?.length ? fileRef.current.files[0] : null,
    })

    const createArtFigkSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            icon_color: 'primary',
            content: '등록이 완료되었습니다.',
            confirmHandler: () => {
                setExitFlag(false)
                closeNotice()
                navigate(FIGK_PATH.ART_FIGK)
            },
        })
    }

    const {
        mutate: tryCreateArtFigk,
        error: createArtFigkError,
        isLoading,
    } = useTMutation(
        ['@CreateArtFigk', 'C'],
        (data: ArtFigkRegistModel) => API.artFigk.createArtFigk(data),
        (rs: GenericResponse) => createArtFigkSuccessCallback(rs)
    )

    // 저장하기 토글 함수
    const registrationHandler = () => {
        Notice({
            type: 'confirm',
            icon: 'notice_bang_mark',
            icon_color: 'primary',
            content: '작성한 내용을 등록하시겠습니까?',
            confirm_button_text: '등록하기',
            confirmHandler: () => {
                tryCreateArtFigk(registData)
            },
        })
    }

    const getTags = (e: string) => {
        setRegistData((prev) => ({
            ...prev,
            tags: e,
        }))
    }

    const getFile = (e: File | null, type: 'videoFileId' | 'jacketFileId') => {
        setRegistData((prev) => ({
            ...prev,
            [type]: e,
        }))
    }

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        setRegistData((prev) => ({
            ...prev,
            title: val,
        }))
    }

    const goBack = () => {
        Notice({
            type: 'confirm',
            content: NOTICE_CONTEXT.WARNNING_FOR_PAGE_EXIT,
            cancel_button_text: '취소',
            confirm_button_text: '확인',
            confirmHandler: () => {
                setExitFlag(false)
                navigate(FIGK_PATH.ART_FIGK)
                closeNotice()
            },
        })
    }

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(true)
    useWarnningGoBack(FIGK_PATH.ART_FIGK, exitFlag)

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        console.log(isEmptyObj(registData), registData.jacketFileId, registData.videoFileId)
    }, [registData])

    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title='ART FIGK 등록'>
                <PageInfo>
                    <span>등록자 : {user?.email}</span>
                </PageInfo>
            </PageTitle>

            <Detail.Container>
                <Detail.Row>
                    <Detail.Col
                        title='제목'
                        required>
                        <InputText
                            name='title'
                            value={registData.title}
                            onChange={onChangeTitle}
                            placeholder='최대 40자 이내'
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='영상 업로드'
                        note='(200MB이하)'
                        required>
                        <InputFile
                            onChange={(e) => getFile(e, 'videoFileId')}
                            fileLimitSize={VIDEO_SIZE_MAX_LIMIT}
                            id='upload'
                            ref={fileRef}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='썸네일 설정'
                        note='(5MB 이하)'
                        required>
                        <ImgFileUpload
                            id='imgUpload'
                            fileLimitSize={IMG_SIZE_MAX_LIMIT}
                            ref={imgFileRef}
                            onChange={(e) => getFile(e, 'jacketFileId')}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='태그'
                        color='gray'
                        required>
                        <Append
                            limit={5}
                            handler={getTags}
                        />
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>
            <ButtonBox>
                <Button
                    $style='line_secondary'
                    onClick={goBack}>
                    돌아가기
                </Button>
                <Button
                    $style='solid_primary'
                    disabled={
                        isEmptyObj(registData) || registData.jacketFileId == null || registData.videoFileId == null
                    }
                    $loading={isLoading}
                    onClick={registrationHandler}>
                    등록하기
                </Button>
            </ButtonBox>
        </Wrapper>
    )
}

export default ArtFigkRegist
