import { styled } from 'styled-components'
import { InputContainerProps, mixin } from 'theme/mixin'

const ValueArea = styled.div<InputContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.4rem;
    min-width: 150px;
    ${mixin.InputBoxStyle}
    cursor: pointer;

    &.placeholder {
        color: ${(p) => p.theme.input.placeholder};
    }
    &.disabled {
        pointer-events: none;
        background-color: ${(p) => p.theme.input.disabled};
        color: ${(p) => p.theme.grayScale.gray6};
    }
    &.readOnly {
        border: 0;
        padding-inline: 0;
        pointer-events: none;
        svg {
            display: none;
        }
    }
`
const OptionArea = styled.ul`
    --item-height: 32px;
    --padding-block: 0.8rem;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 1;
    ${mixin.InputBoxStyle}
    padding-block: var(--padding-block);
    max-height: calc(var(--item-height) * 5 + calc(var(--padding-block) * 2));
    overflow: overlay;
    box-shadow: 0 4px 8px 0 rgba(82, 86, 92, 0.05);

    li {
        display: flex;
        align-items: center;
        height: var(--item-height);
        color: ${(p) => p.theme.grayScale.gray7};
        cursor: pointer;
        font-weight: 300;
        &:hover {
            background-color: ${(p) => p.theme.color.list_hover};
            margin-inline: -1.4rem;
            padding-inline: 1.4rem;
            color: ${(p) => p.theme.color.primary};
        }
        &.current {
            font-weight: 500;
            color: ${(p) => p.theme.color.secondary};
        }
    }
`
const Container = styled.div<InputContainerProps>`
    position: relative;
    ${ValueArea} {
        ${mixin.InputHeight};
    }
`
export const StyleSelect = {
    Container,
    ValueArea,
    OptionArea,
}
