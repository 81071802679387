import addImg from 'assets/img/addImg.svg'
import Delete from 'assets/img/imgupload_delete.svg'
import { ForwardedRef, forwardRef, useRef, useState } from 'react'
import { IMG_URL } from 'shared/common'
import { formatBytes } from 'shared/function'
import { styled } from 'styled-components'
const StyledInputFile = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    input[type='file'] {
        display: none;
    }
    label {
        width: 72px;
        aspect-ratio: 1/1;
        border: 1px dashed ${(p) => p.theme.grayScale.gray3};
        border-radius: 6px;
        background-image: url(${addImg});
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
`

const StyledAppendContents = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.2rem;
`

const AppendImg = styled.div`
    position: relative;
    img {
        height: 72px;
        aspect-ratio: 16/9;
        border-radius: 6px;
        border: 1px solid ${(p) => p.theme.grayScale.gray3};
        object-fit: scale-down;
    }
    button {
        width: 22px;
        height: 22px;
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 50%;
        background-image: url(${Delete});
    }
`
//수정할때 미리보기
export const AppendImgPreview = styled.div`
    position: relative;
    img {
        height: 72px;
        aspect-ratio: 16/9;
        border-radius: 6px;
        border: 1px solid ${(p) => p.theme.grayScale.gray3};
        object-fit: scale-down;
        margin-block-end: 1rem;
    }
    p {
        font-size: 1.2rem;
        color: ${(p) => p.theme.grayScale.gray6};
    }
`

interface InputFileProps {
    id: string
    fileLimitSize: number
    onChange?: (e: File | null) => void
    defaultValue?: string
}
const ImgFileUpload = ({ id, onChange, defaultValue, fileLimitSize }: InputFileProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [fileImage, setFileImage] = useState(defaultValue ? IMG_URL + defaultValue : '')
    const [errorMsg, setErrorMsg] = useState<string | null>(null)

    const fileInput = useRef<HTMLInputElement>(null)

    const saveFileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.currentTarget
        const files = target.files?.[0]

        if (files === undefined) {
            return
        }
        // @ts-ignore

        if (files && files.size > fileLimitSize) {
            if (fileInput.current) fileInput.current.value = ''

            URL.revokeObjectURL('')
            setFileImage('')
            onChange && onChange(null)
            setErrorMsg(`업로드 가능한 최대 용량은 ${formatBytes(fileLimitSize)} 입니다.`)
            return
        }
        setFileImage(URL.createObjectURL(files))
        onChange && onChange(files ?? null)
        setErrorMsg(null)
    }
    const deleteFileImage = () => {
        if (fileInput.current) fileInput.current.value = ''

        URL.revokeObjectURL('')
        onChange && onChange(null)
        setFileImage('')
    }

    return (
        <StyledInputFile>
            <input
                // style={{ display: 'none' }}
                type='file'
                accept='image/*'
                id={id}
                ref={fileInput}
                // onInput={saveFileImage}
                onChange={saveFileImage}
            />
            <label htmlFor={id} />
            {fileImage && (
                <StyledAppendContents>
                    <AppendImg>
                        <img
                            src={fileImage}
                            alt='이미지 미리보기'
                        />
                        <button
                            type='button'
                            onClick={deleteFileImage}></button>
                    </AppendImg>
                    {/* {files.map((_, index) => {
                        return (
                            <AppendImg key={`${_.name}_${index}`}>
                                <img
                                    ref={imageRef}
                                    alt='이미지 미리보기'
                                />
                            </AppendImg>
                        )
                    })} */}
                </StyledAppendContents>
            )}
            {errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
        </StyledInputFile>
    )
}
export default forwardRef(ImgFileUpload)
