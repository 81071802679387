import datepicker_select_arrow from "assets/icons/datepicker_select_arrow.svg";
import { styled } from "styled-components";
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-block-end: 2.4rem;
`;

const Arrow = styled.button.attrs((props) => ({ type: "button" }))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  flex: 1;
  select {
    appearance: none;
    background-image: url(${datepicker_select_arrow});
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 14px;
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
export const ReactDatePickerHeader = {
  Container,
  SelectBox,
  Arrow,
};

const Div = styled.div``;
