const createFormData = (data: any) => {
    let form = new FormData()
    for (let i in data) {
        if (data[i] !== undefined && data[i] !== null) form.append(`${i}`, data[i])
    }

    return form
}

export default createFormData
