import { styled } from "styled-components";
const HtmlList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  li {
    display: flex;
    flex-direction: column;
    width: 468px;
    height: 585px;
    background-color: #fff;
    padding: 40px 34px 25px;

    .text_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 22px;

      img {
        width: 50px;
      }
      &:after {
        content: attr(data-week);
        font-size: 1.2rem;
        color: #b9bcc1;
        font-weight: 300;
      }
    }

    h3 {
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 700;
      color: #222326;
      padding-bottom: 12px;
    }
    blockquote {
      font-size: 1.4rem;
      color: #838990;
      font-weight: 300;
      padding-bottom: 30px;
    }
    p {
      flex: 1;
      font-size: 1.7rem;
      font-weight: 300;
      color: #393c40;
      padding-bottom: 12px;
      text-align: justify;
      word-break: break-all;
    }
    span {
      display: inline-block;
      width: 100%;

      font-size: 1.5rem;
      text-align: right;
      font-weight: 500;
      color: #222326;
      &:before {
        content: "by";
        padding-right: 4px;
        color: #838990;
        font-weight: 200;
      }
    }
  }
`;
const HtmlItem = styled.li``;
export const PS = {
  HtmlList,
  HtmlItem,
};
