import { GenericResponse } from "models/Common";
import { apiHeader } from "shared/function";
const uploadImg = {
  getLastestWeek: async () => {
    const response = await apiHeader(true, "b").get<GenericResponse>(
      `figk-common/week`
    );
    return response.data;
  },

  getPublishedTextFigk: async (week: number | undefined | null) => {
    const response = await apiHeader(true, "a").get<GenericResponse>(
      `/text-figk/upload-image`,
      { params: { week } }
    );
    return response.data;
  },
};

export default uploadImg;
