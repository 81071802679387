import Icon from 'components/atoms/Icon'
import { styled } from 'styled-components'

const StyledNoData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .iconBg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        background-color: ${(p) => p.theme.color.none_bg};
        border-radius: 50%;
        margin-block-end: 1.8rem;
    }
    p {
        color: ${(p) => p.theme.grayScale.gray4};
    }
`
interface ModalNoDataProps {
    text: string
}
const ModalNoData = ({ text }: ModalNoDataProps) => {
    return (
        <StyledNoData>
            <div className='iconBg'>
                <Icon
                    icon={'notice_bang_mark'}
                    color={'gray4'}
                />
            </div>
            <p>{text}</p>
        </StyledNoData>
    )
}

export default ModalNoData
