// conflick
export const ROUTER_PATH = {
    COMMON: {
        HUB: '/hub',
        DASHBOARD: '/dashboard',
    },
    PROJECT_ROOT: {
        FIGK: 'figk',
        ZER01NE: '2023',
    },
}
