import Icon from "components/atoms/Icon";
import { IconName } from "components/atoms/Icon/icon";
import { Fragment, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { globalContestFilter, globalFilter } from "recoil/atom/globalFilter";
import { RouterWithoutTitle } from "router";
import { ROUTER_PATH } from "shared/Router/router";
import { S } from "./index.styled";

interface Props {
  type: "FIGK" | "ZER01NE";
  router: any;
  MainMenuClickHandler: (e: number) => void;
  setSubPath: (e: string) => void;
  index: number;
}

const Box = ({
  router,
  MainMenuClickHandler,
  type,
  index,
  setSubPath,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const KEY = "current_menu";

  const getFormValue = localStorage.getItem(KEY);

  const PROJECT_DASHBOARD_INDEX = RouterWithoutTitle[0].children.length - 1;
  const PROJECT_DASHBOARD =
    RouterWithoutTitle[0].children![PROJECT_DASHBOARD_INDEX].children![0];
  //프로젝트 라우터들
  // const ROUTER_MAP = RouterWithoutTitle[0].children![1].children![0].children!

  const resetGlobalFilter = useResetRecoilState(globalFilter);
  const resetGlobalContestFilter = useResetRecoilState(globalContestFilter);

  const menuActiveCondition = router.path
    ? router.path.includes(location.pathname.split("/")[3])
    : false;
  const resetFilter = () => {
    resetGlobalContestFilter();
    resetGlobalFilter();
  };

  return (
    <>
      <div className="box">
        <S.MainMenu
          className={menuActiveCondition ? "active" : ""}
          onClick={() => MainMenuClickHandler(index + 1)}
        >
          <Link
            to={`${ROUTER_PATH.COMMON.DASHBOARD}/${PROJECT_DASHBOARD.path}/${router.path}`}
            onClick={(e) => {
              if (router.children) e.preventDefault();
              else resetFilter();
            }}
          >
            <Icon
              icon={router.icon as IconName}
              color={menuActiveCondition ? "white70" : "gray3"}
            />
            {router.title}
          </Link>
          {router.children && (
            <Icon
              icon={"arrow_18x18"}
              transform={
                Number(getFormValue) === index + 1 ? "rotate(180)" : ""
              }
              color={menuActiveCondition ? "white70" : "gray3"}
            />
          )}
        </S.MainMenu>
        {router.children && Number(getFormValue) === index + 1 && (
          <S.SubMenu>
            {router.children.map((sub: any, sub_index: number) => {
              const ACTIVE_SUB_MENU = (url: string) => {
                return location.pathname.includes(url);
              };
              const SUB_MENU = `${ROUTER_PATH.COMMON.DASHBOARD}/${PROJECT_DASHBOARD.path}/${router.path}/${sub.path}`;
              return (
                <Fragment key={`${sub.title}_${sub_index}`}>
                  {sub.title && (
                    <li onClick={() => setSubPath(sub.path)}>
                      <Link
                        className={ACTIVE_SUB_MENU(SUB_MENU) ? "active" : ""}
                        to={`${ROUTER_PATH.COMMON.DASHBOARD}/${PROJECT_DASHBOARD.path}/${router.path}/${sub.path}`}
                        onClick={() => resetFilter()}
                      >
                        {sub.title}
                      </Link>
                    </li>
                  )}
                </Fragment>
              );
            })}
          </S.SubMenu>
        )}
      </div>
    </>
  );
};

export default Box;
