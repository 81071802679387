import { StyleLabelBox } from "components/molecules/LabelBox/index.styled";
import { ComponentPropsWithRef, PropsWithChildren } from "react";

interface LabelBoxProps
  extends ComponentPropsWithRef<"label">,
    PropsWithChildren {
  label: string;
}

const LabelBox = ({ label, children, ...rest }: LabelBoxProps) => {
  return (
    <StyleLabelBox.Container>
      <StyleLabelBox.Label $label={label} {...rest} />
      {children}
    </StyleLabelBox.Container>
  );
};

export default LabelBox;
