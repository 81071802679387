import { Root } from "App";
import Dashboard from "components/template/Dashboard";
import Hub from "pages/common/Hub";
import Login from "pages/common/Login";
import MobileAdminVoting from "pages/projects/Figk/Voting/CurrentVoting/AdminVoting/MobileAdminVoting";
import { createBrowserRouter } from "react-router-dom";
import { FIGK_ROUTER, ZER01NE_ROUTER } from "shared/Router/Figk";
import { ROUTER_PATH } from "shared/Router/router";

export const RouterWithoutTitle = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: ROUTER_PATH.COMMON.HUB,
        element: <Hub />,
      },
      {
        path: `figk/mobile`,
        element: <MobileAdminVoting />,
      },
      {
        path: ROUTER_PATH.COMMON.DASHBOARD,
        element: <Dashboard />,
        children: [...FIGK_ROUTER, ...ZER01NE_ROUTER],
      },
    ],
  },
];

const PageRouter = createBrowserRouter(RouterWithoutTitle);
export default PageRouter;
