import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { ActiveMemberModel, MemberUpdateGroup } from 'models/Member'
import { TextFigkEditRequest } from 'models/TextFigk'
import { apiHeader } from 'shared/function'
import { baseApi } from './../Common/index'
const member = {
    getMember: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`author${req}`)
        return response.data
    },

    getMemberInfo: async (id?: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`author?id=${id}`)
        return response.data
    },

    isActiveMember: async (req: ActiveMemberModel) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`author/status`, req)
        return response.data
    },

    updateMemberGroup: async (group: MemberUpdateGroup) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`author`, group)
        return response.data
    },
    deleteMember: async (i: number) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`author`, { data: { authorId: i } })
        return response.data
    },

    getGrouopInTextFigk: async () => {
        const response = await baseApi.get<GenericResponse>(`figk-common/group`)
        return response.data
    },

    updateMember: async (data: TextFigkEditRequest) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`text-figk`, data)
        return response.data
    },
}

export default member
