import API from "api";
import Button from "components/atoms/Button";
import { Detail } from "components/atoms/DetailTable";
import PageTitle, { PageInfo } from "components/atoms/PageTitle";
import { TBody, THead, Table, Td, Th, Tr } from "components/atoms/Table";
import { Wrapper } from "components/atoms/Wrapper/index.styled";
import TableNoData from "components/molecules/Nodata/Table";
import useGetQuery from "hook/useTQuery";
import { GenericResponse } from "models/Common";
import { SettlementDetailResponse } from "models/Settlement";
import { useParams } from "react-router-dom";
import { SettlementDetailColWidth, SettlementDetailTH } from "shared/common";
import {
  SettlementDetailStatus,
  SettlementStatus,
  SettlementStatusColor,
  turnNumbering,
} from "shared/function";
import { styled } from "styled-components";
import { mixin } from "theme/mixin";

const EditorLabel = styled.span`
  ${mixin.SmallRoundLabel}
`;

const DetailSettlement = () => {
  const { id: detailId } = useParams();
  const { data: detailData, refetch: getSettlementDetail } = useGetQuery(
    ["@getTextFigkDetail"],
    () => API.settlement.getSettlementDetail(detailId ?? ""),
    {
      select: (res: GenericResponse) => {
        const data: SettlementDetailResponse = res.data;

        return data;
      },
    }
  );

  return (
    <>
      <Wrapper $form="detail" $whiteBoard radius={12}>
        <PageTitle title="정산관리 상세">
          {}
          <PageInfo>
            {detailData?.authorInfo[0]?.status === "C" && (
              <span>작성일: {detailData?.authorInfo[0]?.completedAt}</span>
            )}
          </PageInfo>
        </PageTitle>
        <Detail.Container>
          <Detail.Title title="작가 정보" />
          <Detail.Row>
            <Detail.Col title="회차">{`Vol.${turnNumbering(
              detailData?.authorInfo[0].week
            )}`}</Detail.Col>
            {detailData?.authorInfo[0]?.status && (
              <Detail.Col
                title="정산 상태"
                weight="600"
                color={SettlementStatusColor(detailData?.authorInfo[0]?.status)}
              >
                {SettlementStatus(detailData?.authorInfo[0]?.status)}
              </Detail.Col>
            )}
          </Detail.Row>
          <Detail.Row>
            <Detail.Col title="이름">
              {detailData?.authorInfo[0]?.authorName}
              {detailData?.authorInfo[0]?.isEditor === "Y" && (
                <EditorLabel>편집 위원</EditorLabel>
              )}
            </Detail.Col>
            <Detail.Col title="조별">
              {detailData?.authorInfo[0]?.groupName}
            </Detail.Col>
          </Detail.Row>
          <Detail.Row>
            <Detail.Col title="필명">
              {detailData?.authorInfo[0]?.nickname}
            </Detail.Col>
            <Detail.Col title="ID">
              {detailData?.authorInfo[0]?.email}
            </Detail.Col>
          </Detail.Row>
          <Detail.Row>
            <Detail.Col title="휴대폰번호">
              {detailData?.authorInfo[0]?.phone}
            </Detail.Col>
            <Detail.Col title="계좌 정보">
              {detailData?.authorInfo[0]?.bankInfo}
            </Detail.Col>
          </Detail.Row>
        </Detail.Container>
        <Detail.Container>
          <Detail.Title title="정산 내역">
            {/* 정산 실패 시 */}
            {detailData?.authorInfo[0]?.status === "R" && (
              <Button $style="solid_secondary" $size="26">
                정산 재전송
              </Button>
            )}
            {/* 정산 완료 시  */}
            {detailData?.authorInfo[0]?.status === "C" && (
              <PageInfo>
                <span>재전송자: 관리자명</span> <span>재전송일: 관리자명</span>
              </PageInfo>
            )}
          </Detail.Title>
          <Table>
            <colgroup>
              {SettlementDetailColWidth.map((_, i) => {
                return <col width={_} key={i} />;
              })}
            </colgroup>
            <THead>
              <Tr>
                {SettlementDetailTH.map((_, i) => {
                  return <Th key={`textFigkTh_${i}`}>{_}</Th>;
                })}
              </Tr>
            </THead>
            <TBody>
              {detailData?.list && detailData?.list.length > 0 ? (
                detailData?.list.map((_, i) => {
                  return (
                    <Tr key={`${_.id}_${i}`}>
                      <Td>{SettlementDetailStatus(_.type)}</Td>
                      <Td>{_.type === "V" ? "투표 완료" : _.title}</Td>
                      <Td align="right">{_.amount.toLocaleString()}</Td>
                      <Td color="date">{_.registeredAt}</Td>
                    </Tr>
                  );
                })
              ) : (
                <TableNoData />
              )}
            </TBody>
          </Table>
        </Detail.Container>
      </Wrapper>
    </>
  );
};

export default DetailSettlement;
