import { styled } from 'styled-components'
import { InputContainerProps, mixin } from 'theme/mixin'

const TextareaContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    border: 1px solid ${(p) => p.theme.input.line};
    border-radius: 0.4rem;
    background-color: #fff;

    cursor: text;
    &:has(textarea:focus) {
        border-color: ${(p) => p.theme.input.line_focus};
    }
    &:has(textarea:read-only) {
        border: none;
        textarea {
            padding: 0;
            cursor: default;
            overflow: visible;
        }
    }
`

const Textarea = styled.textarea`
    display: block;
    appearance: none;
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    font-weight: 300;
    border-radius: 0.4rem;
    padding: 0.8rem 1.4rem;
    color: ${(p) => p.theme.grayScale.gray9};
    &::placeholder {
        color: ${(p) => p.theme.input.placeholder};
    }
`

const Container = styled.div<InputContainerProps>`
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    border: 1px solid ${(p) => p.theme.input.line};
    border-radius: 0.4rem;
    background-color: #fff;
    padding-inline: 1.4rem;
    cursor: text;
    &:has(input:focus) {
        border-color: ${(p) => p.theme.input.line_focus};
    }
    &:has(input:read-only) {
        border: none;
        padding-inline: 0;
        input {
            cursor: default;
        }
        .input_delete_value {
            display: none;
        }
    }
    /* height */
    ${mixin.InputHeight}
`

const Text = styled.input`
    flex: 1;
    height: 100%;
    color: ${(p) => p.theme.grayScale.gray9};
    font-weight: 300;
    &::placeholder {
        color: ${(p) => p.theme.input.placeholder};
    }
`
const Error = styled.p`
    padding-block-start: 8px;
    color: ${(p) => p.theme.color.negative};
    font-size: 1.3rem;
`

export const Input = {
    Text,
    Container,
    Textarea,
    TextareaContainer,
    Error,
}
