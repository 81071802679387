import API from "api";
import { AxiosError } from "axios";
import { useModal } from "hook/useModal";
import useGetQuery from "hook/useTQuery";
import { UserLoginModel } from "models/Auth";
import { FigResponseModel } from "models/Common";
import React, { useContext, useEffect, useState } from "react";
import {
  clearStorage,
  getCookie,
  getStorage,
  setCookie,
} from "util/storage/index";

interface FigAuthContextType {
  user: undefined | null | UserLoginModel;
  device: undefined | "PC" | "IOS" | "AOS";
  login: () => void;
  logout: () => void;
  pingExceptUrl: Array<string>;
}

declare const window: any;
const AuthContext = React.createContext<FigAuthContextType>(null!);

export const useFigAuth = () => {
  return useContext(AuthContext);
};

export const FigAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { Notice, Modal, closeNotice } = useModal();

  const [loginData, setLoginData] = useState<undefined | null | UserLoginModel>(
    undefined
  );
  const [device, setDevice] = useState<undefined | "PC" | "IOS" | "AOS">(
    undefined
  );
  const exceptUrl = ["/", "/login"];

  // 로그인 감지
  const { refetch: pingProccess } = useGetQuery(
    ["@PING"],
    () => API.auth.ping(),
    {
      onSuccess: (res: FigResponseModel) => pingSuccessCallback(res, "process"),
      onError: (err: AxiosError) => loginFailCallback(err),
      enabled: !!getCookie("access"),
      refetchInterval: 10000,
    }
  );

  const { refetch: refreshToken } = useGetQuery(
    ["@REFRESH"],
    () => API.auth.refreshAccess(),
    {
      onSuccess: (res: FigResponseModel) => refreshSuccessCallback(res),
      onError: (err: AxiosError) => refreshFailCallback(err),
      enabled: false,
    }
  );

  // ping 실패시 사용자에게 알림 후 재로그인 시키기
  const loginFailCallback = (err: AxiosError) => {
    Notice({
      type: "alert",
      icon: "notice_bang_mark",
      title: "로그인 정보 만료",
      content: "로그인 정보가 만료되었어요.\n 다시 로그인해 주세요.",
      confirmHandler: () => {
        closeNotice();
        removeToken();
      },
    });
  };

  const setPingProccessSuccessCallback = (d: UserLoginModel) => {
    setLoginData(d);
  };

  const pingSuccessCallback = (
    res: FigResponseModel,
    type: "login" | "process"
  ) => {
    let apiData: UserLoginModel = res.data;

    // 유효하지 않은 토큰 -> 재로그인
    if (type === "process" && res.code === 40001) {
      removeToken();
      return;
    }

    // 토큰 만료 -> 리프레쉬
    if (type === "process" && res.code === 40002) {
      refreshToken();
      return;
    }

    return setPingProccessSuccessCallback(apiData);
  };

  // 로그아웃
  const logoutCallback = () => {
    let finishTime = new Date();
    finishTime.setDate(finishTime.getDate() - 100);

    removeToken();
  };

  // 페이지 리렌더시 로그인 감지
  const loginManager = () => {
    const accessToken = getCookie("access");
    if (exceptUrl.includes(window.location.pathname)) return;

    if (accessToken) {
      pingProccess();
    }
  };

  const removeToken = () => {
    clearStorage();
    setLoginData(null);

    // 토큰 없을시 로그인으로 강제 이동
    // 종속됨
    window.location.replace("/");
  };

  // 리프레시 만료로 인한 FAIL CB
  const refreshSuccessCallback = (res: FigResponseModel) => {
    if (res.code !== 200) {
      Notice({
        type: "alert",
        icon: "notice_bang_mark",
        title: "로그인 정보 만료",
        content: "로그인 정보가 만료되었어요.\n 다시 로그인해 주세요.",
        confirmHandler: () => {
          closeNotice();
          removeToken();
        },
      });
      return;
    }

    setCookie("access", res.data.access);
    // 리프레쉬 성공시 새로고침하여 logindata 갱신
    window.location.reload();

    // 새로고침으로 대체
    // pingProccess()
  };

  // 현재 서버에서 response json 을 만들어서 보내주기 때문에 error 제어는
  // 서버 에러 등 피할 수 없는 상황일 때만 fail callback 호출
  const refreshFailCallback = (err: AxiosError) => {
    console.log("refresh Error");
  };

  const deviceManager = () => {
    // Native Token Data
    if (
      typeof window.AndroidScript != "undefined" &&
      window.AndroidScript != null
    ) {
      setDevice("AOS");
      return;
    }

    try {
      window.webkit.messageHandlers.loginRequest.postMessage("getToken");
      setDevice("IOS");
    } catch {}

    setDevice("PC");
  };

  useEffect(() => {
    if (loginData) {
      loginManager();
      deviceManager();
    }
  }, []);

  // 뒤로가기 감지
  useEffect(() => {
    window.addEventListener("popstate", pingProccess);

    return () => {
      window.removeEventListener("popstate", pingProccess);
    };
  }, []);

  useEffect(() => {
    const currentProjectId = Number(getStorage("project"));

    if (
      loginData &&
      currentProjectId !== 0 &&
      !loginData.projectIds.includes(currentProjectId)
    ) {
      Notice({
        type: "alert",
        icon: "notice_bang_mark",
        title: "권한 오류",
        content:
          "현재 프로젝트에 대한 권한이 없어요. \n 관리자에게 문의하세요.",
        confirmHandler: () => {
          window.location.replace("/");
        },
      });
    }
  }, [loginData]);

  let value = {
    user: loginData,
    device,
    login: pingProccess,
    logout: logoutCallback,
    pingExceptUrl: exceptUrl,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
