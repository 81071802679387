import API from "api";
import { AxiosError } from "axios";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import PageTitle from "components/atoms/PageTitle";
import { useFigAuth } from "figAuthProvider";
import { useModal } from "hook/useModal";
import useTMutation from "hook/useTMutation";
import useGetQuery from "hook/useTQuery";
import { GenericResponse } from "models/Common";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { returnVoting } from "shared/QueryOption/textFigk";
import { 에디터특수기호변경 } from "shared/common";
import { turnNumbering } from "shared/function";
import { S } from "./index.styled";
import { mediaQuery } from "theme/mediaQuery";
import useWidth from "hook/useWidth";

const AdminVoting = () => {
  const { media } = useWidth();
  const { user } = useFigAuth();
  const { Notice, closeNotice } = useModal();
  const navigate = useNavigate();
  const [active, setActive] = useState<number>(0);

  const [authorList, setAuthorList] = useState<number[]>([]);

  const { data: vote, refetch: getVote } = useGetQuery(
    ["@getVoteList"],
    () => API.vote.getVotingList(),
    returnVoting
  );
  const { mutate: doVote, isError: voteError } = useTMutation(
    ["@DoVote", "U"],
    (s: number[]) => API.vote.doVote(s),
    (data: GenericResponse) => voteSuccessCallback(data),
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 409) {
          Notice({
            icon: "notice_bang_mark",
            type: "alert",
            content: "이미 투표에 참여했습니다.",
          });
        }
      },
    }
  );

  const voteSuccessCallback = (res: GenericResponse) => {
    if (res.code === 200) {
      Notice({
        icon: "notice_checked",
        type: "alert",
        content: "투표가 완료되었습니다.",
        confirmHandler: () => {
          closeNotice();
          navigate(-1);
        },
      });
    }
  };

  const tryVote = () => {
    if (vote && authorList.length === vote.summary.maxVote) {
      doVote(authorList);
    }
  };
  const listActiveHandler = (idx: number) => {
    setActive(idx);
    if (active === idx) {
      setActive(0);
    }
  };

  const pushToAuthorList = (
    e: React.MouseEvent<HTMLButtonElement>,
    i: number
  ) => {
    e.stopPropagation();
    // if (vote && authorList.length >= vote.summary.maxVote) return

    let prev = [...authorList];

    prev.push(i);

    if (authorList.includes(i)) {
      setAuthorList(prev.filter((_) => _ !== i));
      return;
    }

    setAuthorList(prev.filter((v, i) => prev.findIndex((x) => x === v) === i));
  };

  return (
    <S.RootContainer>
      <div className="pagetitle__wrapepr">
        <PageTitle
          title={`Vol.${turnNumbering(user?.textWeek)} ${
            user?.groupName
          }조 투표`}
        />
      </div>
      <S.ListControlBox>
        <S.VotingCounter>
          투표 수 :
          <span>
            <strong>{authorList.length}</strong> / {vote?.summary.maxVote}개
          </span>
        </S.VotingCounter>
        <Button
          $size="36"
          $style="solid_secondary"
          disabled={vote && authorList.length < vote.summary.maxVote}
          onClick={() =>
            Notice({
              type: "confirm",
              title: "투표를 완료하시겠습니까?",
              content: "투표 후에는 수정이 불가능합니다.",
              confirm_button_text: "완료하기",
              confirmHandler: tryVote,
            })
          }
        >
          투표 완료하기
        </Button>
      </S.ListControlBox>
      <S.ListBox>
        {vote?.list.map((_, i) => {
          const ACTIVE = active === _.id;
          return (
            <S.AccordionContainer key={`vote_list_${i}`}>
              <S.AccordionHead
                className={ACTIVE ? "open" : ""}
                onClick={() => listActiveHandler(_.id)}
              >
                <p className={`title ${ACTIVE ? "" : "ellipsis"}`}>
                  {에디터특수기호변경(_.title)}
                </p>
                <div className="right">
                  {/* 투표했을때 일때 버튼 $스타일 line_primary */}
                  {!media.tablet && (
                    <Button
                      type="button"
                      $size="30"
                      onClick={(e) => pushToAuthorList(e, _.id)}
                      disabled={
                        authorList.length >= vote.summary.maxVote &&
                        !authorList.includes(_.id)
                      }
                      $style={
                        authorList.includes(_.id)
                          ? "line_primary"
                          : `line_toggle`
                      }
                    >
                      투표하기
                    </Button>
                  )}
                  <div className="arrow__wrap">
                    <Icon icon={"arrow_14x14"} color="gray8" />
                  </div>
                </div>
              </S.AccordionHead>
              {ACTIVE && (
                <S.AccordionBody>
                  <a
                    href={_.link}
                    target="_blank"
                    rel="noreferrer"
                    className="subject"
                  >
                    {에디터특수기호변경(_.subTitle)}
                  </a>
                  <p className="contents">{에디터특수기호변경(_.content)}</p>
                  <div className="tags">
                    {_.tag &&
                      _.tag.map((_, i) => {
                        return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>;
                      })}
                  </div>
                  <span className="writer">{_.authorName}</span>
                </S.AccordionBody>
              )}
              {media.tablet && (
                <S.MobileVotingButtonWrap className={ACTIVE ? "open" : ""}>
                  <Button
                    type="button"
                    $size="40"
                    $display="block"
                    onClick={(e) => pushToAuthorList(e, _.id)}
                    disabled={
                      authorList.length >= vote.summary.maxVote &&
                      !authorList.includes(_.id)
                    }
                    $style={
                      authorList.includes(_.id) ? "line_primary" : `line_toggle`
                    }
                  >
                    투표하기
                  </Button>
                </S.MobileVotingButtonWrap>
              )}
            </S.AccordionContainer>
          );
        })}
      </S.ListBox>
    </S.RootContainer>
  );
};

export default AdminVoting;
