import { GenericResponse } from 'models/Common'
import { MemberInfoModel, MemberResponseModel } from 'models/Member'
import { TextFigkResponse } from 'models/TextFigk'

export const returnMember = {
    select: (res: GenericResponse) => {
        const data: MemberResponseModel = res.data

        return data
    },
}

export const returnMemberInfo = {
    select: (res: GenericResponse) => {
        const data: MemberInfoModel = res.data

        return data
    },
}

export const returnMemberTextFigk = {
    select: (res: GenericResponse) => {
        const data: TextFigkResponse = res.data

        return data
    },
}
