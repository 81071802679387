import { AUTH_URL, BASE_URL } from 'api/Common'
import axios from 'axios'
import { ArtFigkSummaryModel } from 'models/ArtFigk'
import { SummaryType } from 'models/Common'
import { SettlementSummaryModel } from 'models/Settlement'
import { TextFigkSummaryModel } from 'models/TextFigk'
import { SendingSummaryModel } from 'models/TextFigk/sending'
import { VoteSummaryModel } from 'models/TextFigk/vote'
import { getCookie, getStorage } from 'util/storage'

export const turnNumbering = (value?: number) => {
    if (!value) return '0'
    return value.toString().padStart(3, '0')
}
export const combinationSummary = (data?: TextFigkSummaryModel) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_all',
            color: 'primary',
            title: '총 글 수',
            data: `${data.totalCount}개`,
            $background: 'primary_bg',
        },
        {
            icon: 'summary_checked',
            color: 'positive',
            title: '총 발행완료',
            data: `${data.publishedCount}개`,
            $background: 'positive_bg',
        },
        {
            icon: 'summary_x',
            color: 'none',
            title: '총 미발행',
            data: `${data.notPublishedCount}개`,
            $background: 'none_bg',
        }
    )
    return c
}

// n 시간 데이터 ms 단위 데이터로 반환
export const getHour = (e: number): number => {
    return 1000 * 60 * 60 * e
}

export function ScrollPositionTop() {
    window.scrollTo({ top: 0, left: 0 })
}

export const isActive = (s?: string) => {
    return s === 'Y' ? '활성' : '비활성'
}

export const isPublishedColor = (s?: string) => {
    return s === 'W' ? 'progress' : s === 'Y' ? 'black' : 'gray'
}

export const isPublished = (s?: string) => {
    return s === 'Y' ? '발행완료' : s === 'W' ? '발행대기' : '미발행'
}

export const isAppliedColor = (s?: string) => {
    return s === 'T' ? 'negative' : s === 'N' || s === 'F' ? 'gray' : 'black'
}

export const SettlementStatusColor = (s: string) => {
    return s === 'W' ? 'gray' : s === 'C' ? 'black' : 'negative'
}

//공모상태 (N: 미송고, E: 지원, C: 투표 마감, P: 합격, F: 불합격 )
export const isApplied = (s?: string) => {
    return s === 'N'
        ? '없음'
        : s === 'E'
        ? '송고완료'
        : s === 'C'
        ? '투표마감'
        : s === 'P'
        ? '당선'
        : s === 'F'
        ? '낙선'
        : s === 'T'
        ? '반려'
        : ''
}

// Figk Process 상태 (V: 투표 진행중, C: 투표 마감, A: 지원(공모) 가능, P: 공모 중지)
export const isVoting = (s: string) => {
    return s === 'V' ? '투표중' : s === 'C' ? '심사중' : s === 'A' ? '발행완료' : '중지'
}
//작가 정산관리 상태 (C: 완료 W: 대기 R: 실패)
export const SettlementStatus = (s: string) => {
    return s === 'W' ? '대기' : s === 'C' ? '완료' : '실패'
}

//작가 정산관리 상태 (V: 공모 투표 A: 공모 지원 P: 작품 발행)
export const SettlementDetailStatus = (s: string) => {
    return s === 'V' ? '투표' : s === 'A' ? '송고' : '발행'
}

export const createWeek = (w?: number, reverse?: boolean, placeholder?: string, hasDefault: boolean = true) => {
    if (!w) return []
    let defaultArr: { status: string | null; title: string }[] = []

    if (hasDefault) defaultArr.push({ status: null, title: placeholder ?? '전체' })
    let joinArr: { status: string | null; title: string }[] = []

    for (let s = 1; s <= w; s++) {
        joinArr.push({ status: s + '', title: `vol.${turnNumbering(s)}` })
    }
    if (reverse) {
        defaultArr.push(...joinArr.reverse())
    } else {
        defaultArr.push(...joinArr)
    }
    return defaultArr
}

//약관 유형, 1: 서비스 이용약관, 2: 개인정보 활용 동의서, 3: 저작권 정책, 4: 마케팅 정보 수신동의
export const convertTermsType = (s?: number) => {
    return s === 1 ? '서비스 이용약관' : s === 2 ? '개인정보 활용 동의서' : s === 3 ? '저작권 정책' : '마케팅 정보 수신동의'
}
export const combinationSendingSummary = (data?: SendingSummaryModel) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_all',
            color: 'primary',
            title: '총 송고 지원 수',
            data: `${data.totalCount}개`,
            $background: 'primary_bg',
        },
        {
            icon: 'summary_checked',
            color: 'positive',
            title: '총 송고 당선 수',
            data: `${data.passCount}개`,
            $background: 'positive_bg',
        },
        {
            icon: 'summary_x',
            color: 'gray7',
            title: '총 송고 낙선 수',
            data: `${data.nonPassCount}개`,
            $background: 'none_bg',
        }
    )
    return c
}

export const combinationVoteSummary = (data?: VoteSummaryModel, p?: VoidFunction, np?: VoidFunction) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_clock',
            color: 'primary',
            title: '진행 상태',
            data: isVoting(data.status),
            $background: 'primary_bg',
        },
        {
            icon: 'summary_pencil',
            color: 'purple',
            title: '투표 참여작',
            data: data.totalCount + '개',
            $background: 'purple_bg',
        },
        {
            icon: 'summary_user_checked',
            color: 'positive',
            title: '투표 참여 인원',
            data: data.voter + '명',
            $background: 'positive_bg',
            onClick: p,
        },
        {
            icon: 'summary_x',
            color: 'none',
            title: '투표 미참여 인원',
            data: data.unVoter + '명',
            $background: 'none_bg',
            onClick: np,
        }
    )
    return c
}

export const combinationPastVoteSummary = (data?: VoteSummaryModel, p?: VoidFunction, np?: VoidFunction) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_pencil',
            color: 'purple',
            title: '투표 참여작',
            data: data.totalCount + '개',
            $background: 'purple_bg',
        },
        {
            icon: 'summary_user_checked',
            color: 'positive',
            title: '투표 참여 인원',
            data: data.voter + '명',
            $background: 'positive_bg',
            onClick: p,
        },
        {
            icon: 'summary_x',
            color: 'none',
            title: '투표 미참여 인원',
            data: data.unVoter + '명',
            $background: 'none_bg',
            onClick: np,
        }
    )
    return c
}

export const combinationArtFigkSummary = (data?: ArtFigkSummaryModel, p?: VoidFunction, np?: VoidFunction) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_all',
            color: 'primary',
            title: '총 글 수',
            data: `${data.totalCount}개`,
            $background: 'primary_bg',
        },
        {
            icon: 'summary_checked',
            color: 'positive',
            title: '총 발행완료',
            data: `${data.publishedCount}개`,
            $background: 'positive_bg',
        },
        {
            icon: 'summary_x',
            color: 'gray7',
            title: '총 미발행',
            data: `${data.notPublishedCount}개`,
            $background: 'none_bg',
        }
    )
    return c
}

export const combinationSettlementSummary = (data?: SettlementSummaryModel, p?: VoidFunction, np?: VoidFunction) => {
    let c: SummaryType[] = []

    if (!data) return []

    c.push(
        {
            icon: 'summary_all',
            color: 'primary',
            title: '총 정산금',
            data: `${data.totalPay.toLocaleString()}원`,
            $background: 'primary_bg',
        },
        {
            icon: 'summary_checked',
            color: 'positive',
            title: '총 정산 완료금',
            data: `${data.completedPay.toLocaleString()}원`,
            $background: 'positive_bg',
        },
        {
            icon: 'summary_pause',
            color: 'progress',
            title: '총 정산 대기금',
            data: `${data.waitingPay.toLocaleString()}개`,
            $background: 'progress_bg',
        },
        {
            icon: 'summary_pencil',
            color: 'gray7',
            title: '총 송고 정산금',
            data: `${data.applyPay.toLocaleString()}원`,
            $background: 'none_bg',
        },
        {
            icon: 'summary_document',
            color: 'gray7',
            title: '총 투표 정산금',
            data: `${data.votePay.toLocaleString()}원`,
            $background: 'none_bg',
        },
        {
            icon: 'summary_medal',
            color: 'gray7',
            title: '총 발행 정산금',
            data: `${data.publishPay.toLocaleString()}원`,
            $background: 'none_bg',
        }
    )
    return c
}

// 빈값 ('') 만 체크
export const isEmptyObj = (obj: any) => {
    let v = false
    Object.values(obj).forEach((key) => {
        if (key === '') {
            v = true
        }
    })

    return v
}
export const apiHeader = (isAccess: boolean, uType: 'a' | 'b', isForm?: boolean) => {
    return axios.create({
        baseURL: uType === 'a' ? AUTH_URL : BASE_URL,
        headers: {
            Authorization: `Bearer ${isAccess ? getCookie('access') ?? '' : getStorage('refresh')}`,
            'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
            'Cache-Control': 'no-cache',
        },
    })
}

// 파일 사이즈 변환
export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const dateToStringFormat = (date: Date | null | undefined) => {
    if (!date) return ''

    let dateFormat2 =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 9 ? '0' + date.getDate() : date.getDate())
    return dateFormat2
}
