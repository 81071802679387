import NotoSansKR_Black_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Black_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Black.woff";
import NotoSansKR_Black_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Black.woff2";

import NotoSansKR_Bold_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Bold_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Bold.woff";
import NotoSansKR_Bold_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Bold.woff2";

import NotoSansKR_Medium_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Medium_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Medium.woff";
import NotoSansKR_Medium_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Medium.woff2";

import NotoSansKR_Regular_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Regular_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Regular.woff";
import NotoSansKR_Regular_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Regular.woff2";

import NotoSansKR_Light_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Light_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Light.woff";
import NotoSansKR_Light_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Light.woff2";

import NotoSansKR_Thin_otf from "assets/fonts/NotoSansKR/otf/NotoSansKR-Black.otf";
import NotoSansKR_Thin_woff from "assets/fonts/NotoSansKR/woff/NotoSansKR-Thin.woff";
import NotoSansKR_Thin_woff2 from "assets/fonts/NotoSansKR/woff2/NotoSansKR-Thin.woff2";

import Pretendard_Black_woff from "assets/fonts/Pretendard/woff/Pretendard-Black.woff";
import Pretendard_Black_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Black.woff2";

import Pretendard_ExtraBold_woff from "assets/fonts/Pretendard/woff/Pretendard-ExtraBold.woff";
import Pretendard_ExtraBold_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2";

import Pretendard_Bold_woff from "assets/fonts/Pretendard/woff/Pretendard-Bold.woff";
import Pretendard_Bold_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Bold.woff2";

import Pretendard_SemiBold_woff from "assets/fonts/Pretendard/woff/Pretendard-SemiBold.woff";
import Pretendard_SemiBold_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-SemiBold.woff2";

import Pretendard_Medium_woff from "assets/fonts/Pretendard/woff/Pretendard-Medium.woff";
import Pretendard_Medium_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Medium.woff2";

import Pretendard_Regular_woff from "assets/fonts/Pretendard/woff/Pretendard-Regular.woff";
import Pretendard_Regular_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Regular.woff2";

import Pretendard_Light_woff from "assets/fonts/Pretendard/woff/Pretendard-Light.woff";
import Pretendard_Light_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Light.woff2";

import Pretendard_ExtraLight_woff from "assets/fonts/Pretendard/woff/Pretendard-ExtraLight.woff";
import Pretendard_ExtraLight_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2";

import Pretendard_Thin_woff from "assets/fonts/Pretendard/woff/Pretendard-Thin.woff";
import Pretendard_Thin_woff2 from "assets/fonts/Pretendard/woff2/Pretendard-Thin.woff2";

import { createGlobalStyle } from "styled-components";
export const GlobalFont = createGlobalStyle`
/* NotoSansKR */
@font-face {
	font-family: 'NotoSansKR';
	font-weight: 900;
	font-display: swap;
	src: local('NotoSansKR Black'), url(${NotoSansKR_Black_woff2}) format('woff2'), url(${NotoSansKR_Black_woff}) format('woff') , url(${NotoSansKR_Black_otf}) format('otf');
}


@font-face {
	font-family: 'NotoSansKR';
	font-weight: 700;
	font-display: swap;
	src: local('NotoSansKR Bold'), url(${NotoSansKR_Bold_woff2}) format('woff2'), url(${NotoSansKR_Bold_woff}) format('woff'), url(${NotoSansKR_Bold_otf}) format('otf');
}


@font-face {
	font-family: 'NotoSansKR';
	font-weight: 500;
	font-display: swap;
	src: local('NotoSansKR Medium'), url(${NotoSansKR_Medium_woff2}) format('woff2'), url(${NotoSansKR_Medium_woff}) format('woff'), url(${NotoSansKR_Medium_otf}) format('otf');
}
@font-face {
	font-family: 'NotoSansKR';
	font-weight: 400;
	font-display: swap;
	src: local('NotoSansKR Regular'), url(${NotoSansKR_Regular_woff2}) format('woff2'), url(${NotoSansKR_Regular_woff}) format('woff'), url(${NotoSansKR_Regular_otf}) format('otf');
}

@font-face {
	font-family: 'NotoSansKR';
	font-weight: 300;
	font-display: swap;
	src: local('NotoSansKR Light'), url(${NotoSansKR_Light_woff2}) format('woff2'), url(${NotoSansKR_Light_woff}) format('woff'), url(${NotoSansKR_Light_otf}) format('otf');
}


@font-face {
	font-family: 'NotoSansKR';
	font-weight: 100;
	font-display: swap;
	src: local('NotoSansKR Thin'), url(${NotoSansKR_Thin_woff2}) format('woff2'), url(${NotoSansKR_Thin_woff}) format('woff'), url(${NotoSansKR_Thin_otf}) format('otf');
}


/* Pretendard */
@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url(${Pretendard_Black_woff2}) format('woff2'), url(${Pretendard_Black_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	-fontweight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url(${Pretendard_ExtraBold_woff2}) format('woff2'), url(${Pretendard_ExtraBold_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url(${Pretendard_Bold_woff2}) format('woff2'), url(${Pretendard_Bold_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url(${Pretendard_SemiBold_woff2}) format('woff2'), url(${Pretendard_SemiBold_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url(${Pretendard_Medium_woff2}) format('woff2'), url(${Pretendard_Medium_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url(${Pretendard_Regular_woff2}) format('woff2'), url(${Pretendard_Regular_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url(${Pretendard_Light_woff2}) format('woff2'), url(${Pretendard_Light_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url(${Pretendard_ExtraLight_woff2}) format('woff2'), url(${Pretendard_ExtraLight_woff}) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url(${Pretendard_Thin_woff2}) format('woff2'), url(${Pretendard_Thin_woff}) format('woff');
}

`;
