import { styled } from 'styled-components'
export const StyledPageTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-end: 2rem;
`
const Title = styled.h1`
    color: ${(p) => p.theme.grayScale.gray10};
    font-size: 2rem;
    font-weight: 600;
`

const Contents = styled.div`
    display: flex;
    align-items: center;
    /* gap: 2.4rem; */

    button + button {
        margin-left: 12px;
    }
`

export const PageInfo = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #b9bcc1;
        font-weight: 300;
        font-size: 1.2rem;
        &:not(:last-of-type):after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 8px;

            background-color: ${(p) => p.theme.line.line2};
            margin-inline: 0.8rem;
        }
    }
`

interface PageTitleProps {
    title: string
    children?: React.ReactNode
}
const PageTitle = ({ title, children }: PageTitleProps) => {
    return (
        <StyledPageTitleContainer>
            <Title>{title}</Title>
            <Contents>{children}</Contents>
        </StyledPageTitleContainer>
    )
}
export default PageTitle
