import { GenericResponse } from 'models/Common'
import { TextFigkDetailResponse, TextFigkResponse } from 'models/TextFigk'
import { SendingResponse, TurndownResponse } from 'models/TextFigk/sending'
import { TextFigkModel } from 'models/TextFigk/uploadImg'
import { IsVotedManModel, VoteResponse } from 'models/TextFigk/vote'

export const returnTextFigk = {
    select: (res: GenericResponse) => {
        const data: TextFigkResponse = res.data

        return data
    },
}

export const returnGroupInTextFigk = {
    select: (res: GenericResponse) => {
        const data: Array<{ id: number; name: string }> = res.data
        let list: {
            status: string | null
            title: string
        }[] = [{ status: null, title: '전체' }]
        for (let a = 0; a < data.length; a++) {
            list.push({ status: data[a].id + '', title: data[a].name })
        }
        return list
    },
}

export const returnTextFigkDetailOptions = (id?: string) => {
    return {
        select: (res: GenericResponse) => {
            const data: TextFigkDetailResponse = res.data

            return data
        },
        enabled: !!id,
    }
}

export const returnSending = {
    select: (res: GenericResponse) => {
        const data: SendingResponse = res.data

        return data
    },
}

export const returnVoting = {
    select: (res: GenericResponse) => {
        const data: VoteResponse = res.data

        return data
    },
}

export const returnVoteMan = {
    select: (res: GenericResponse) => {
        const data: IsVotedManModel = res.data

        return data
    },
    enabled: false,
}

export const returnPublished = {
    select: (res: GenericResponse) => {
        const data: IsVotedManModel = res.data

        return data
    },
}

export const returnTurnDown = {
    select: (res: GenericResponse) => {
        const data: TurndownResponse = res.data

        return data
    },
}

interface LastestWeekResponse {
    week: {
        maxTextWeek: number
        maxArtWeek: number
    }
}

export const returnLastestWeek = {
    select: (res: GenericResponse) => {
        const data: LastestWeekResponse = res.data

        return data
    },
    retry: 0,
    refetchOnWindowFocus: false,
}

export const returnUploadImg = {
    select: (res: GenericResponse) => {
        const data: TextFigkModel[] = res.data

        return data
    },
    retry: 0,
    refetchOnWindowFocus: false,
}
