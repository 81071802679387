import { ComponentPropsWithRef } from 'react'
import { S } from './index.styled'
interface SwitchProps extends ComponentPropsWithRef<'input'> {
    label?: string
    checked: boolean
}
const Switch = ({ label, checked, ...rest }: SwitchProps) => {
    return (
        <S.Container>
            {label && <span className={checked ? 'checked' : ''}>{label}</span>}
            <S.Box>
                <S.Input
                    id='switch'
                    type='checkbox'
                    checked={checked}
                    {...rest}
                />
                <S.Circle htmlFor='switch' />
            </S.Box>
        </S.Container>
    )
}

export default Switch
