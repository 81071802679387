import { css, styled } from "styled-components";
import { BackgroundVariationType, WhiteBoardType, mixin } from "theme/mixin";
const Icon = styled.span<BackgroundVariationType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 1.8rem;
  ${mixin.BackgroundVariation};
`;

const Head = styled.span`
  display: flex;
  align-items: center;
  gap: 14px;
  color: ${(p) => p.theme.grayScale.gray7};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
`;

const Body = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 600;
  color: ${(p) => p.theme.grayScale.gray10};
`;

const Card = styled.div<WhiteBoardType>`
  display: flex;
  align-items: center;
  ${mixin.WhiteBoard};
  padding: 1.8rem 2.5rem;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;
`;

const Container = styled.div<{ $length: number }>`
  display: flex;
  flex-wrap: wrap;
  padding-block-end: 2rem;
  gap: 20px;
  ${(p) =>
    p.$length >= 5 &&
    css`
      ${Card} {
        flex: 1 1 calc(calc(100% / 3) - calc(2rem * 2 / 3));
      }
    `}
  ${(p) =>
    p.$length >= 7 &&
    css`
      ${Card} {
        flex: 1 1 calc(calc(100% / 4) - calc(2rem * 3 / 4));
      }
    `}

  ${(p) =>
    p.$length <= 4 &&
    css`
      ${Card} {
        flex: 1 0 calc(calc(100% / 4) - calc(2rem * 3 / 4));
      }
    `}
`;
export const S = {
  Container,
  Contents,
  Card,
  Head,
  Icon,
  Body,
};
