import { ReactComponent as Double } from 'assets/icons/pagination_double.svg'
import { ReactComponent as Single } from 'assets/icons/pagination_single.svg'
import { S } from 'components/atoms/Pagination/index.styled'
import { useEffect, useState } from 'react'
interface Props {
    totalItem: number // Item 의 총 갯수
    currentPage: number // 현재 페이지 default 1
    setCurrentPage: (e: number) => void // 클릭 페이지 set
    limit: number // limit
    isLast: boolean
}
const Pagination = ({ isLast, totalItem, currentPage, setCurrentPage, limit }: Props) => {
    const next = limit
    const total = totalItem ? totalItem : 1
    const totalPage = totalItem % limit !== 0 ? Math.floor(totalItem / next) + 1 : Math.floor(totalItem / next)

    const pagePre = 5

    //const [page, setPage] = useState(currentPage ? currentPage : 1)
    const [page, setPage] = useState(currentPage ? currentPage : 1)
    const [pageList, setPageList] = useState<Array<number>>([])

    const onClickPage = (e: number) => {
        if (e <= 0) return

        setPage(e)
        setCurrentPage(e)
    }

    const onClickNextPage = () => {
        let current = page % pagePre !== 0 ? Math.floor(page / pagePre) + 1 : Math.floor(page / pagePre)
        const nextPage = current * pagePre + 1 < totalPage ? current * pagePre + 1 : totalPage

        onClickPage(page + 1)
    }

    useEffect(() => {
        let current = page % pagePre !== 0 ? Math.floor(page / pagePre) + 1 : Math.floor(page / pagePre)
        current = current > 0 ? current : 1

        const pl = []
        for (let i = 0; i < pagePre; i++) {
            const p = i + 1 + (current - 1) * pagePre

            if (p <= totalPage) pl.push(p)
        }
        setPageList(pl)
    }, [page, total])

    useEffect(() => {
        setPage(currentPage)
    }, [currentPage])

    return (
        <S.Container>
            <div className='arrowBox prev'>
                <S.ArrowButton
                    disabled={totalItem < 1 || page <= 1}
                    onClick={() => onClickPage(1)}>
                    <Double />
                </S.ArrowButton>
                <S.ArrowButton
                    disabled={totalItem < 6 || page <= 1}
                    onClick={() => onClickPage(page - 1)}>
                    <Single />
                </S.ArrowButton>
            </div>
            <div className='pageNumBox'>
                {pageList.length > 0 ? (
                    pageList.map((_, i) => {
                        return (
                            <S.PageNum
                                key={`page_${_}`}
                                $current={page === _}
                                onClick={() => onClickPage(_)}>
                                {_}
                            </S.PageNum>
                        )
                    })
                ) : (
                    <S.PageNum $current>1</S.PageNum>
                )}
                {/* <S.PageNum $current>2</S.PageNum>
                <S.PageNum>4</S.PageNum>
                <S.PageNum>5</S.PageNum> */}
            </div>
            <div className='arrowBox next'>
                <S.ArrowButton
                    onClick={onClickNextPage}
                    disabled={isLast}>
                    <Single />
                </S.ArrowButton>
                <S.ArrowButton
                    onClick={() => onClickPage(totalPage)}
                    disabled={isLast}>
                    <Double />
                </S.ArrowButton>
            </div>
        </S.Container>
    )
}

export default Pagination
