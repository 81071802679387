import API from 'api'
import Button from 'components/atoms/Button'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import Pagination from 'components/atoms/Pagination'
import Select from 'components/atoms/Select'
import Summary from 'components/atoms/Summary'
import { Table, TableControlBox, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { FigkCommon } from 'components/common/Figk'
import LabelBox from 'components/molecules/LabelBox'
import TableNoData from 'components/molecules/Nodata/Table'
import Filter from 'components/oraganisms/Filter'
import RangeDatePicker from 'components/oraganisms/RangeDatePicker'
import { useFigAuth } from 'figAuthProvider'
import { useDebounce } from 'hook/useDebounce'
import useGetQuery from 'hook/useTQuery'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { globalFilter, globalFilterReset } from 'recoil/atom/globalFilter'
import { ArtFigkColWidth, ArtFigkTh, TextFigkPublishSeleceData } from 'shared/common'
import { combinationArtFigkSummary, createWeek, isPublished, turnNumbering } from 'shared/function'
import { returnArtFigk } from 'shared/QueryOption/artFigk'
import { FIGK_PATH } from 'shared/Router/Figk'
const ArtFigk = () => {
    const { user } = useFigAuth()
    const navigate = useNavigate()

    const [filter, setFilter] = useRecoilState(globalFilter)
    const resetGlobalFilter = useResetRecoilState(globalFilter)
    const globalReset = useRecoilValue(globalFilterReset)

    const [word, setWord] = useState<string>(filter.word || '')

    const { data: artFigk, refetch: getArtFigk } = useGetQuery(
        ['@getArtFigk'],
        () => API.artFigk.getArtFigk(filter),
        returnArtFigk
    )
    const onChangeSelectFilter = (e: string | null, type: string) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            [type]: e,
        }))
    }

    const debounceWordInFilter = useCallback(
        useDebounce(
            (e: string) =>
                setFilter((prev) => ({
                    ...prev,
                    word: e,
                    page: 1,
                })),
            300
        ),
        []
    )

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setWord(val)

        debounceWordInFilter(val)
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,
            page: e,
        }))
    }

    useEffect(() => {
        getArtFigk()
    }, [filter])

    useEffect(() => {
        if (globalReset) {
            setWord('')
            resetGlobalFilter()
        }
    }, [globalReset])

    return (
        <>
            <PageTitle title='ART FIGK'>
                <FigkCommon.CurrentRound>
                    <span className='current'>
                        회차 정보:<strong>Vol.{turnNumbering(user?.artWeek)}</strong>
                    </span>
                </FigkCommon.CurrentRound>
                {/* 중지하기 클릭 후 다음회차 넘어가기 전까지 버튼 비활성> */}
                <Button
                    $size='36'
                    $style='solid_secondary'
                    onClick={() => navigate(FIGK_PATH.ART_FIGK_REGIST)}>
                    ART FIGK 등록하기
                </Button>
            </PageTitle>

            <Summary summary={combinationArtFigkSummary(artFigk?.summary)} />

            <Wrapper
                $form='main'
                $whiteBoard
                radius={12}>
                <Filter
                    search={
                        <InputText
                            activate_search
                            activate_delete
                            value={word}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }>
                    <LabelBox label='회차'>
                        <Select
                            options={createWeek(user?.artWeek, true)}
                            defaultStatus={filter.week}
                            handler={(e) => onChangeSelectFilter(e, 'week')}
                        />
                    </LabelBox>
                    <LabelBox label='발행 상태'>
                        <Select
                            options={TextFigkPublishSeleceData}
                            defaultStatus={filter.isPublished}
                            handler={(e) => onChangeSelectFilter(e, 'isPublished')}
                        />
                    </LabelBox>
                    <LabelBox label='기간'>
                        <RangeDatePicker filterStatus={globalFilter} />
                    </LabelBox>
                </Filter>
                <Table form='default'>
                    <colgroup>
                        {ArtFigkColWidth.map((_, i) => {
                            return (
                                <col
                                    width={_}
                                    key={i}
                                />
                            )
                        })}
                    </colgroup>
                    <THead>
                        <Tr>
                            {ArtFigkTh.map((_, i) => {
                                return <Th key={`artFigkTh_${i}`}>{_}</Th>
                            })}
                        </Tr>
                    </THead>

                    <TBody>
                        {artFigk?.list && artFigk.list.length > 0 ? (
                            artFigk?.list.map((_, i) => {
                                return (
                                    <Tr
                                        key={`artfigk_list_${_.id}`}
                                        onClick={() => navigate(`${FIGK_PATH.ART_FIGK}/${_.id}`)}>
                                        <Td color='darkgray'>{_.week ? `Vol.${turnNumbering(_.week)}` : '-'}</Td>
                                        <Td
                                            color={
                                                _.isPublished === 'W'
                                                    ? 'progress'
                                                    : _.isPublished === 'Y'
                                                    ? 'black'
                                                    : 'gray'
                                            }>
                                            {isPublished(_.isPublished)}
                                        </Td>
                                        <Td align='left'>{_.title}</Td>
                                        <Td>{_.email}</Td>
                                        <Td color='date'>{_.updatedAt}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <TableNoData />
                        )}
                    </TBody>
                </Table>
                <TableControlBox>
                    <Pagination
                        currentPage={filter.page}
                        limit={filter.per}
                        setCurrentPage={setPage}
                        isLast={artFigk?.isLast || false}
                        totalItem={artFigk?.totalCount ?? 0}
                    />
                </TableControlBox>
            </Wrapper>
        </>
    )
}

export default ArtFigk
