import { NOTICE_CONTEXT } from 'components/template/GlobalNotice/context'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from './useModal'
/**
 * path - 경고창에서 확인 눌러서 뒤로 갈 주소
 * boolean - 동작할 boolean 없으면 true
 */
const useWarnningGoBack = (path: string, boolean?: boolean) => {
    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()
    useEffect(() => {
        const preventGoBack = () => {
            // change start
            window.history.pushState(null, '', window.location.href)
            // change end
            console.log('prevent go back!')
            Notice({
                type: 'confirm',
                content: NOTICE_CONTEXT.WARNNING_FOR_PAGE_EXIT,
                cancel_button_text: '취소',
                confirm_button_text: '확인',
                confirmHandler: () => {
                    navigate(path)
                    closeNotice()
                },
            })
        }
        if (boolean ?? true) {
            window.history.pushState(null, '', window.location.href)
            window.addEventListener('popstate', preventGoBack)
        }

        return () => window.removeEventListener('popstate', preventGoBack)
    }, [boolean ?? true])
}

export default useWarnningGoBack
