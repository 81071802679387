import API from "api";
import logo from "assets/img/fig_logo.svg";
import Button from "components/atoms/Button";
import InputText from "components/atoms/Input/InputText";
import { Input } from "components/atoms/Input/index.styled";
import useTMutation from "hook/useTMutation";
import { RequestLoginModel, UserLoginModel } from "models/Auth";
import { GenericResponse } from "models/Common";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginErrorHandle } from "shared/QueryOption/login";
import { ROUTER_PATH } from "shared/Router/router";
import { styled } from "styled-components";
import {
  removeCookie,
  removeStorage,
  setCookie,
  setStorage,
} from "util/storage";
const Login = () => {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState<RequestLoginModel>({
    email: "",
    password: "",
    isForever: true,
  });

  const LoginSuccessCallback = (res: GenericResponse) => {
    const data: UserLoginModel = res.data;
    setCookie("access", data.access);
    setStorage("refresh", data.refresh);
    navigate(ROUTER_PATH.COMMON.HUB);

    localStorage.setItem("current_menu", "1");

    if (res.code === 200) window.location.reload();
  };

  const initToken = () => {
    removeStorage("refresh");
    removeCookie("access");
  };

  const onLogin = () => {
    tryLogin(loginForm);
  };

  const { mutate: tryLogin, error: LoginError } = useTMutation(
    ["@UserVerifiedAndAuthentication", "T"],
    (data: RequestLoginModel) => API.auth.login(data),
    (data: GenericResponse) => LoginSuccessCallback(data),
    loginErrorHandle
  );

  const onChangeInput = (
    e: ChangeEvent<HTMLInputElement>,
    type: "email" | "password"
  ) => {
    const v = e.target.value;

    setLoginForm((prev) => ({
      ...prev,
      [type]: v,
    }));
  };

  const error = LoginError?.response?.data as GenericResponse | undefined;

  useEffect(() => {
    initToken();
  }, []);
  return (
    <Container>
      <Contents>
        <div className="logo">
          <img src={logo} alt="fig operation logo" />
        </div>
        <LoginFormBox>
          <div className="row">
            <InputText
              $form="form"
              value={loginForm.email}
              onChange={(e) => onChangeInput(e, "email")}
              placeholder="아이디를 입력해주세요."
            />
            {/* <Input.Error>아이디를 입력해주세요.</Input.Error> */}
          </div>
          <div className="row">
            <InputText
              $form="form"
              type="password"
              value={loginForm.password}
              onChange={(e) => onChangeInput(e, "password")}
              placeholder="비밀번호를 입력해주세요."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin();
                }
              }}
            />
            {error && <Input.Error>{error.message}</Input.Error>}
          </div>
        </LoginFormBox>
        <Button
          $size="52"
          $display="block"
          $style="solid_secondary"
          onClick={onLogin}
        >
          로그인
        </Button>
      </Contents>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  height: 100%;
  padding-block-start: 20vh;
  padding-block-end: 10vh;
`;

const Contents = styled.div`
  width: min(calc(100% - 32px), 375px);
  margin-inline: auto;
  .logo {
    padding-block-end: 60px;
    img {
      display: block;
      width: 74px;
      height: 27px;
      margin-inline: auto;
    }
  }
`;

const LoginFormBox = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-block-end: 50px;
`;
