import Icon from 'components/atoms/Icon'
import { Input } from 'components/atoms/Input/index.styled'
import { ComponentPropsWithRef, useRef } from 'react'
import { InputContainerType } from 'theme/mixin'
interface InputTextProps extends ComponentPropsWithRef<'input'> {
    $form?: InputContainerType
    type?: 'text' | 'password'
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    activate_search?: boolean
    activate_delete?: boolean
}
const InputText = ({
    type = 'text',
    value,

    onChange,
    activate_search,
    activate_delete,
    $form = 'dashboard',
    ...rest
}: InputTextProps) => {
    const inputRef = useRef<HTMLInputElement>(document.createElement('input'))
    const focusHandler = () => {
        inputRef.current.focus()
    }
    return (
        <Input.Container
            $form={$form}
            onClick={focusHandler}>
            {activate_search && (
                <Icon
                    icon='search_18x18'
                    color='gray8'
                />
            )}
            <Input.Text
                ref={inputRef}
                type={type}
                value={value}
                onChange={onChange}
                {...rest}
            />

            {
                // Reset 버튼
                activate_delete && value && value.length > 0 && (
                    <Icon
                        className='input_delete_value cursor_pointer'
                        icon='input_delete'
                        color='gray3'
                        onClick={() => {}}
                    />
                )
            }
        </Input.Container>
    )
}

export default InputText
