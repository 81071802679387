import { ComponentPropsWithRef, useRef } from 'react'
import { Input } from '../index.styled'

interface TextareaProps extends ComponentPropsWithRef<'textarea'> {
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    rows?: number
}
const Textarea = ({ value, onChange, rows = 15, ...rest }: TextareaProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(document.createElement('textarea'))
    const focusHandler = () => {
        textareaRef.current.focus()
    }
    return (
        <Input.TextareaContainer onClick={focusHandler}>
            <Input.Textarea
                ref={textareaRef}
                rows={rows}
                value={value}
                onChange={onChange}
                {...rest}
            />
        </Input.TextareaContainer>
    )
}

export default Textarea
