import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import TextEditor from 'components/atoms/Editor'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import Select from 'components/atoms/Select'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { GenericResponse } from 'models/Common'
import { TermUpdateModel } from 'models/Term'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { returnTermsDetail } from 'shared/QueryOption/terms'
import { FIGK_PATH } from 'shared/Router/Figk'
import { TermSelectData } from 'shared/common'
import { ScrollPositionTop, convertTermsType } from 'shared/function'

export interface IEditorModel {
    id: string
    content: string
}
export const InitialIssueData: IEditorModel = {
    id: '',
    content: '',
}
const FigkTermsDetail = () => {
    const { id: detailId } = useParams()

    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)
    useWarnningGoBack(FIGK_PATH.FIGK_TERMS, edit)

    const [regist, setRegist] = useState<TermUpdateModel>({
        ...InitialIssueData,
        id: Number(detailId),
        // id: Number(detailId),
        // content: '',
    })

    const { mutate: tryUpdate, error: updateError } = useTMutation(
        ['@updateTerm', 'U'],
        (data: TermUpdateModel) => API.term.updateTerm(data),
        (data: GenericResponse) => updateTermSuccessCallback(data)
    )

    const { mutate: tryDel, error: delError } = useTMutation(
        ['@delTerm', 'D'],
        (id: number[]) => API.term.deleteTerm(id),
        (res: GenericResponse) => deleteTermSuccessCallback(res)
    )

    const { data: term } = useGetQuery([`@getTermDetail_${detailId}`], () => API.term.getTermDetail(detailId), {
        ...returnTermsDetail,
        // refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setRegist({ id: Number(detailId), content: data.content ?? '' })
        },
    })

    const deleteTermSuccessCallback = (rs: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '삭제 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_TERMS)
            },
        })
    }

    const updateTermSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '저장이 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_TERMS)
            },
        })
    }

    const onChangeRegistForm = (e: string) => {
        setRegist((prev) => ({
            ...prev,
            content: e,
        }))
    }

    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(true)
        ScrollPositionTop()
    }

    // useEffect(() => {
    //     getTermDetail()
    // }, [])
    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? '약관 수정' : '약관 상세'}>
                {!edit && (
                    <PageInfo>
                        <span> 등록일: {term?.registeredAt}</span>
                        {term?.updatedAt && <span>수정일: {term.updatedAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>
            <Detail.Container>
                <Detail.Row>
                    <Detail.Col title='버전'>Vol {term?.version}.0</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='유형'>
                        <Select
                            readOnly
                            placeholder={convertTermsType(term?.type)}
                            options={TermSelectData}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='내용'
                        required={edit}>
                        <TextEditor
                            disabled={edit}
                            initialValue={term?.content}
                            value={regist.content}
                            onChange={(e) => onChangeRegistForm(e)}
                        />
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>

            <ButtonBox>
                {edit ? (
                    <>
                        <Button
                            $style='line_secondary'
                            onClick={() => {
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '변경사항이 저장되지 않습니다.',
                                    confirmHandler: () => {
                                        setEdit(false)
                                        closeNotice()
                                    },
                                })
                            }}>
                            돌아가기
                        </Button>

                        <Button
                            $style='solid_primary'
                            onClick={() =>
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '작성한 약관을 저장할까요?',
                                    confirm_button_text: '저장하기',
                                    confirmHandler: () => {
                                        tryUpdate(regist)
                                    },
                                })
                            }>
                            저장하기
                        </Button>
                    </>
                ) : (
                    <>
                        {/* -0719 약관 삭제 방지 */}
                        {/* <Button
                            $style='line_secondary'
                            onClick={() =>
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '이 공지를 삭제하시겠습니까?',
                                    confirm_button_text: '삭제하기',
                                    confirmHandler: () => {
                                        tryDel([detailId ? Number(detailId) : -1])
                                    },
                                })
                            }>
                            삭제하기
                        </Button> */}

                        <Button
                            $style='line_primary'
                            onClick={editToggleHandler}>
                            수정하기
                        </Button>
                    </>
                )}
            </ButtonBox>
        </Wrapper>
    )
}

export default FigkTermsDetail
