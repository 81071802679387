import { useEffect } from 'react'

const usePreventRefresh = (condition?: boolean) => {
    // 새로고침 막기 변수
    const preventClose = (e: BeforeUnloadEvent) => {
        if (condition) {
            e.preventDefault()
            e.returnValue = '' // chrome에서는 설정이 필요해서 넣은 코드
        }
    }
    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        if (condition ?? true) {
            ;(() => {
                window.addEventListener('beforeunload', preventClose)
            })()

            return () => {
                window.removeEventListener('beforeunload', preventClose)
            }
        }
    }, [condition ?? true])
}

export default usePreventRefresh
