import { css, styled } from "styled-components";

const commonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${commonStyle}
  gap: 24px;
  padding-block-start: 14px;
  button {
    min-width: 24px;
    height: 24px;
  }
  .pageNumBox {
    ${commonStyle}
    gap: 4px;
  }
  .arrowBox {
    ${commonStyle}
    gap: 6px;
    &.next {
      button {
        transform: rotate(180deg);
      }
    }
  }
`;
type PageNumType = {
  $current?: boolean;
};
const PageNum = styled.button.attrs((props) => ({
  type: "button",
}))<PageNumType>`
  ${commonStyle}
  font-weight:400;
  font-size: 1.3rem;
  color: ${(p) => p.theme.grayScale.gray6};
  &:hover {
    color: ${(p) => p.theme.grayScale.gray9};
  }
  ${(p) =>
    p.$current &&
    css`
      font-weight: 600;
      color: ${(p) => p.theme.color.primary} !important;
    `}
`;
const ArrowButton = styled.button.attrs((props) => ({ type: "button" }))`
  ${commonStyle}
  border: 1px solid ${(p) => p.theme.line.line2};
  border-radius: 0.4rem;
  cursor: pointer;
  svg {
    path {
      fill: ${(p) => p.theme.grayScale.gray8};
    }
  }
  &.next {
    transform: rotate(180deg);
  }
  &:hover {
    border-color: ${(p) => p.theme.grayScale.gray8};
  }
  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${(p) => p.theme.line.line2};
    svg {
      path {
        fill: ${(p) => p.theme.grayScale.gray3};
      }
    }
  }
`;
export const S = {
  Container,
  ArrowButton,
  PageNum,
};
