import { styled } from 'styled-components'
import { FontWeightType, StatusColorType, mixin } from 'theme/mixin'
import { Input } from '../Input/index.styled'

const StyledContainer = styled.div``

const StyledRow = styled.div`
    &.detail_row {
        display: flex;
        flex: 1;
        border-bottom: 1px solid ${(p) => p.theme.table.line};
        border-top: 1px solid ${(p) => p.theme.table.line};
        &:not(:last-of-type) {
            border-bottom: 0;
        }
    }
`

const StyledTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.6rem;
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        color: ${(p) => p.theme.grayScale.gray8};
    }
`

const StyledSummary = styled.div``

const StyledSummaryItem = styled.span<StatusColorType>`
    font-size: 1.3rem;
    color: ${(p) => p.theme.grayScale.gray6};
    strong {
        font-weight: 600;
        font-size: inherit;
        ${mixin.StatusColor}
    }
    &:not(:last-of-type):after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: ${(p) => p.theme.line.line2};
        margin-inline: 12px;
    }
`

const StyledCol = styled.div<StatusColorType & FontWeightType>`
    display: flex;
    flex: 1;

    .head {
        display: flex;
        align-items: center;
        flex: 0 0 220px;
        padding: 1.8rem 1.2rem;
        background-color: ${(p) => p.theme.table.th_background};
        font-size: 1.3rem;
        font-weight: 400;
        color: ${(p) => p.theme.table.th_color};
        &.required:after {
            content: '*';
            font-size: inherit;
            color: ${(p) => p.theme.color.negative};
        }
        .note {
            font-size: 1.2rem;
            color: ${(p) => p.theme.grayScale.gray4};
        }
    }
    .desc {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        padding: 0.8rem 1.2rem;
        color: ${(p) => p.theme.grayScale.gray9};
        ${mixin.StatusColor}
        ${mixin.FontWeight}
     
        a {
            color: ${(p) => p.theme.color.primary};
            &:hover {
                text-decoration: underline;
            }
        }
        &:has(input[text]:read-only) {
            height: 5.6rem;
        }

        &:has(input[text]:read-only),
        &:has(textarea:read-only) {
            padding: 1.8rem 1.2rem;
            ${Input.Container},${Input.TextareaContainer} {
                font-weight: inherit;
                color: inherit;
                input,
                textarea {
                    font-weight: inherit;
                    color: inherit;
                }
            }
        }
    }
`

export const S = {
    StyledContainer,
    StyledTitle,
    StyledSummary,
    StyledSummaryItem,
    StyledRow,
    StyledCol,
}
