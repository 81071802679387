import { createGlobalStyle } from 'styled-components'

export const EditorStyle = createGlobalStyle`
.ck.ck-editor {
    width: 100%;

}
.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 400px;
}

.ck .ck-placeholder:before{
    color: ${(p) => p.theme.input.placeholder};
}

/* .ck .ck-reset .ck-editor .ck-rounded-corners {
    width: 100%;
    min-height: 400px;
    margin-bottom: 20px;
} */
`
