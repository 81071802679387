import API from 'api'
import logo from 'assets/img/fig_logo.svg'
import placeholderLogo from 'assets/img/placeholder_logo.png'
import Icon from 'components/atoms/Icon'
import { SelectState } from 'components/atoms/Select'
import { LoginBox } from 'components/template/GlobalHeader/index.styled'
import { useFigAuth } from 'figAuthProvider'
import useGetQuery from 'hook/useTQuery'
import { ProjectListModel } from 'models/Common'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { toggleMenuValue } from 'recoil/atom'
import { returnProjectList } from 'shared/QueryOption/common'
import { ROUTER_PATH } from 'shared/Router/router'
import { IMG_URL } from 'shared/common'
import { getStorage, setStorage } from 'util/storage'
import { S } from './index.style'
const Hub = () => {
    const navigate = useNavigate()
    const { user, logout } = useFigAuth()
    const [activeMenuValue, setAtiveMenuValue] = useRecoilState(toggleMenuValue)

    const [select, setSelect] = useState<Omit<SelectState, 'value'> & { value: ProjectListModel }>({
        isOpen: false,
        value: { id: -1, name: '' } ?? null,
    })
    const { data: project, refetch: getProjectList } = useGetQuery(['@getProjectList'], () => API.project.getProject(), {
        ...returnProjectList,

        onSuccess: (data) => {
            const getProjectId = Number(getStorage('project')) ?? -1

            setAtiveMenuValue(getProjectId)
        },
        enabled: false,
    })

    type PROJECT_PATH_TYPE = (typeof ROUTER_PATH.PROJECT_ROOT)[keyof typeof ROUTER_PATH.PROJECT_ROOT]

    const PROJECT_PATH: any = {
        figk: 'figk',
        ZER01NE: '2023',
    }
    const setProject = () => {
        const projectId = Number(getStorage('project')) ?? -1
        const projectName = getStorage('pn') ?? ''

        const projectValue: ProjectListModel = { id: projectId, name: projectName }
        setSelect({ isOpen: false, value: projectValue })
    }
    const getValue = (e: ProjectListModel) => {
        setStorage('project', e.id + '')
        setStorage('pn', e.name)
        setSelect({
            isOpen: false,
            value: e,
        })
        setAtiveMenuValue(e.id)
        navigate(e.name === 'figk' ? '/dashboard/figk/text_figk/total' : '/dashboard/figk/text_figk/total')
    }

    const filterProject = project && user ? project.filter((i) => user.projectIds.includes(i.id)) : []

    useEffect(() => {
        getProjectList()
        setProject()
    }, [])
    return (
        <>
            <S.HubHeader>
                <LoginBox>
                    <div className='account'>
                        <span>{user?.email}</span>
                    </div>
                    <Icon
                        icon='logout'
                        color='gray5'
                        onClick={logout}
                    />
                </LoginBox>
            </S.HubHeader>
            <S.Container>
                <S.Contents>
                    <div className='logo'>
                        <img
                            src={logo}
                            alt='fig operation logo'
                        />
                    </div>
                    <p>프로젝트 선택</p>
                    {filterProject && (
                        <S.ProjectListBox className={filterProject?.length > 4 ? '' : 'center'}>
                            {filterProject.map((_: ProjectListModel, i) => {
                                return (
                                    <S.ProjectItem
                                        key={_.id}
                                        onClick={() => {
                                            getValue(_)
                                        }}>
                                        <img
                                            src={_.logo ? IMG_URL + _.logo : placeholderLogo}
                                            alt='project 이미지'
                                        />

                                        <span>{_.name.toUpperCase()}</span>
                                    </S.ProjectItem>
                                )
                            })}
                        </S.ProjectListBox>
                    )}
                </S.Contents>
            </S.Container>
        </>
    )
}

export default Hub
