import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import InputText from 'components/atoms/Input/InputText'
import Textarea from 'components/atoms/Input/Textarea'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import Append from 'components/oraganisms/Append'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { TextFigkDetailResponse, TextFigkEditRequest } from 'models/TextFigk'
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { 에디터특수기호변경 } from 'shared/common'
import { ScrollPositionTop, isApplied, turnNumbering } from 'shared/function'

const TextFigkSendingDetail = () => {
    const navigate = useNavigate()
    const { id: detailId } = useParams()
    const { p: page } = useParams()

    const { Notice, closeNotice } = useModal()
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)
    const EDIT = !edit

    const [textEditData, setTextEditData] = useState<TextFigkEditRequest>({
        id: 0,
        title: '',
        subTitle: '',
        content: '',
        link: '',
    })
    const [sendingDetail, setSendingDetail] = useState<TextFigkDetailResponse>()

    // // react-query runtime 오류로 인한 axios 핸들링으로 대체
    // const getSendingDetail = () => {
    //     API.textFigk.getTextFigkDetail(detailId ?? '').then((res: GenericResponse) => {
    //         const data: TextFigkDetailResponse = res.data
    //         if (res.code === 200) {
    //             setSendingDetail(data)
    //             setTextEditData({
    //                 id: data.id,
    //                 title: data.title,
    //                 subTitle: data.subTitle,
    //                 content: data.content,
    //                 tags: data.tag.map((i) => i.name).toString(),
    //                 link: data.link,
    //             })
    //         }
    //     })
    // }

    const record: Record<string, unknown> = { id: detailId } as { [key: string]: string | undefined; id: string }
    const { data: detailData, refetch: getDetail } = useGetQuery(['@getTextFigkDetail', record], () => API.textFigk.getTextFigkDetail(detailId ?? ''), {
        select: (res: GenericResponse) => {
            const data: TextFigkDetailResponse = res.data

            return data
        },
        onSuccess: (data: TextFigkDetailResponse) => {
            setSendingDetail(data)
            setTextEditData({
                id: data.id,
                title: data.title,
                subTitle: data.subTitle,
                content: data.content,
                tags: data.tag.map((i) => i.name).toString(),
                link: data.link,
            })
        },
        enabled: false,
    })

    const { mutate: trySave, error: saveError } = useTMutation(
        ['@UpdateSending', detailId ?? 'U'],
        (data: TextFigkEditRequest) => API.textFigk.updateTextFigk(data),
        (data: GenericResponse) => editSuccessCallback(data)
    )

    const { mutate: tryDel, error: delError } = useTMutation(
        ['@DeleteSending', detailId ?? 'D'],
        (i: number[]) => API.textFigk.deleteTextFigk(i),
        (data: GenericResponse) => delSuccessCallback(data)
    )

    const delSuccessCallback = (res: GenericResponse) => {
        if (res.code === 200) {
            closeNotice()
            navigate(-1)
        } else {
            alert('error')
        }
    }
    const editSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            content: res.code === 200 ? '저장이 완료되었어요.' : res.message,
            cancel_button_text: '확인',
            confirmHandler: () => {
                return res.code === 200 ? window.location.reload() : () => {}
            },
        })
        setEdit(false)
    }

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)

    const onChangeInput = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, type: string) => {
        setTextEditData((prev) => ({
            ...prev,
            [type]: e.target.value,
        }))
    }
    // 태그 가져옴
    const getTags = (e: string) => {
        setTextEditData((prev) => ({
            ...prev,
            tags: e,
        }))
    }
    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(!edit)
        ScrollPositionTop()
    }

    const DeleteTextHandler = () => {
        Notice({
            type: 'confirm',
            content: '이 글을 삭제하시겠습니까?',
            confirm_button_text: '삭제하기',
            confirmHandler: () => {
                tryDel([sendingDetail?.id ?? -1])
            },
        })
    }

    const save = () => {
        Notice({
            type: 'confirm',
            content: '변경한 내용으로 저장하시겠습니까?',
            confirm_button_text: '저장하기',
            confirmHandler: () => {
                trySave(textEditData)
            },
        })
    }

    //수정하기 버튼 렌더링 조건 : 투표 마감, 당선 ,낙선
    const EditBtnRenderCondition = sendingDetail?.contestStatus === 'N' || sendingDetail?.contestStatus === 'E'

    useEffect(() => {
        if (detailId) {
            // getSendingDetail()
            getDetail()
        }
    }, [])
    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? '글 수정' : '글 상세'}>
                {EDIT && (
                    <PageInfo>
                        <span>작성일: {sendingDetail?.registeredAt}</span>
                        {sendingDetail?.updatedAt && <span>수정일: {sendingDetail.updatedAt}</span>}
                        {sendingDetail?.registeredAt && <span>송고일: {sendingDetail.registeredAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>
            <Detail.Container>
                <Detail.Title title='작가 정보' />
                <Detail.Row>
                    <Detail.Col title='필명'>{sendingDetail?.authorName}</Detail.Col>
                    <Detail.Col title='조별'>{sendingDetail?.groupName}조</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='ID'>{sendingDetail?.authorEmail}</Detail.Col>
                </Detail.Row>
            </Detail.Container>
            <Detail.Container>
                <Detail.Title title='글 정보' />
                <Detail.Row>
                    <Detail.Col
                        title='회차'
                        color='darkgray'>
                        Vol.{turnNumbering(sendingDetail?.week)}
                    </Detail.Col>
                </Detail.Row>
                {EDIT && (
                    <Detail.Row>
                        <Detail.Col
                            title='송고 상태'
                            weight='600'>
                            {isApplied(sendingDetail?.contestStatus)}
                        </Detail.Col>
                    </Detail.Row>
                )}

                <Detail.Row>
                    <Detail.Col title='제목'>
                        <InputText
                            readOnly={EDIT}
                            name='title'
                            onChange={(e) => onChangeInput(e, 'title')}
                            value={에디터특수기호변경(textEditData.title)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재'>
                        <InputText
                            readOnly={EDIT}
                            name='subject'
                            onChange={(e) => onChangeInput(e, 'subTitle')}
                            defaultValue={에디터특수기호변경(textEditData.subTitle)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='내용'>
                        <Textarea
                            readOnly={EDIT}
                            name='contents'
                            onChange={(e) => onChangeInput(e, 'content')}
                            defaultValue={에디터특수기호변경(textEditData.content)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재 URL'>
                        {EDIT ? (
                            <a
                                href={textEditData.link}
                                target='_blank'
                                rel='noreferrer'>
                                {에디터특수기호변경(textEditData.link)}
                            </a>
                        ) : (
                            <InputText
                                name='url'
                                onChange={(e) => onChangeInput(e, 'link')}
                                defaultValue={에디터특수기호변경(textEditData.link) || ''}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='태그'
                        color='gray'>
                        {EDIT ? (
                            <>
                                {sendingDetail?.tag.map((_, i) => {
                                    return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>
                                })}
                            </>
                        ) : (
                            <Append
                                // tags 초기값 예외처리
                                tags={textEditData.tags === '' ? [] : textEditData.tags?.split(',') || []}
                                handler={getTags}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>
            {!EditBtnRenderCondition && (
                <ButtonBox>
                    {edit ? (
                        <>
                            <Button
                                $style='line_secondary'
                                onClick={() => {
                                    Notice({
                                        type: 'confirm',
                                        icon: 'notice_bang_mark',
                                        content: '변경사항이 저장되지 않습니다.',
                                        confirmHandler: () => {
                                            setEdit(false)
                                            closeNotice()
                                        },
                                    })
                                }}>
                                돌아가기
                            </Button>

                            <Button
                                $style='solid_primary'
                                onClick={save}>
                                저장하기
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                $style='line_secondary'
                                onClick={DeleteTextHandler}>
                                삭제하기
                            </Button>
                            <Button
                                $style='line_primary'
                                onClick={editToggleHandler}>
                                수정하기
                            </Button>
                        </>
                    )}
                </ButtonBox>
            )}
        </Wrapper>
    )
}

export default TextFigkSendingDetail
