import { createGlobalStyle } from "styled-components";

export const Common = createGlobalStyle`
//svg onClick 이벤트 있을때 커서 모양 변경 
    svg[data-cursor='pointer']{
      cursor: pointer;
    }


    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

 
    .ellipsis2{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

    .cursor_pointer{
      cursor: pointer;
    }
    .cursor_default{
      cursor: default;
    }
    .cursor_not_allow{
      cursor: not-allowed;
    }

    .color_primary{
color: ${(p) => p.theme.color.primary};
    }
    .color_negative{
color: ${(p) => p.theme.color.negative};
    }
    .color_positive{
color: ${(p) => p.theme.color.positive};
    }

`;
