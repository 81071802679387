import { motion } from 'framer-motion'
import { css, styled } from 'styled-components'

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2100;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(113, 117, 126, 0.1);
    border: 1px solid ${(p) => p.theme.line.line1};
    padding: 1.6rem 2rem 2rem;
    width: 330px;
    border-radius: 12px;
    text-align: center;
    .title {
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 600;
        color: ${(p) => p.theme.grayScale.gray9};
        padding-block-end: 0.4rem;
    }
    p {
        font-size: 1.3rem;
        padding-block-end: 2rem;
        color: ${(p) => p.theme.grayScale.gray9};
        white-space: pre-line;
        strong {
            font-weight: 600;
            color: ${(p) => p.theme.grayScale.gray9};
        }
    }
`

const ButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const IconBg = styled.div<{ $bg: 'primary_bg' | 'negative_bg' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-block-end: 0.6rem;

    ${(p) =>
        p.$bg === 'primary_bg' &&
        css`
            background-color: ${(p) => p.theme.color.primary_bg};
        `}

    ${(p) =>
        p.$bg === 'negative_bg' &&
        css`
            background-color: ${(p) => p.theme.color.negative_bg};
        `}
`

export const Notice = {
    Container,
    Contents,
    ButtonBox,
    IconBg,
}
