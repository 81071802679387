import { styled } from 'styled-components'

const Container = styled.div`
    height: 100%;
    padding-block-start: 10vh;
    padding-block-end: 10vh;
`
const Contents = styled.div`
    width: 750px;
    margin-inline: auto;

    .logo {
        padding-block-end: 30px;
        img {
            display: block;
            width: 74px;
            height: 27px;
            margin-inline: auto;
        }
    }
    p {
        font-size: 2rem;
        font-weight: 600;
        color: ${(p) => p.theme.grayScale.gray10};
        text-align: center;
        padding-block-end: 100px;
    }
`

const ProjectListBox = styled.ul`
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
    &.center {
        justify-content: center;
    }
`
const ProjectItem = styled.li`
    --ulGap: 10px;
    --showListCount: 4;
    flex: 0 1 calc(100% / var(--showListCount) - calc(calc(var(--ulGap) * calc(var(--showListCount) - 1)) / var(--showListCount)));
    border: 1px solid ${(p) => p.theme.line.line1};
    border-radius: 12px;
    padding-block: 20px;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(113, 117, 126, 0.04);
    cursor: pointer;
    background-color: #fff;
    &:hover {
        border-color: ${(p) => p.theme.color.primary};
        box-shadow: 0 0 12px 0 rgba(178, 198, 238, 0.6);
    }

    img {
        width: 120px;
        margin-inline: auto;
        margin-block-end: 14px;
        object-fit: contain;
        aspect-ratio: 120/50;
    }
    span {
        font-size: 1.3rem;
        color: ${(p) => p.theme.grayScale.gray9};
    }
`

const HubHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    height: ${(p) => p.theme.layout.header_height};
    padding-inline: 3rem;
`
export const S = {
    Container,
    Contents,
    ProjectListBox,
    ProjectItem,
    HubHeader,
}
