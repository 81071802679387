import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
    layout: {
        header_height: '56px',
        sidebar_width: '260px',
        form_input_height: '44px',
        dashboard_input_height: '38px',
    },
    line: {
        line1: '#F0F2F4',
        line2: '#E6E8EC',
    },
    color: {
        primary: '#4870F6',
        primary_light_hover: '#F6FBFF',
        primary_deep_hover: '#335FF5',
        primary_bg: '#F0F6FE',
        secondary: '#161819',
        background: '#F8F8F9',
        list_hover: 'rgba(242,248,254,0.5)',
        negative: '#F4554A',
        negative_bg: '#FEF1F1',
        positive: '#6AC340',
        positive_bg: '#EDF8E8',
        purple: '#9E71FF',
        purple_bg: '#F3EEFF',
        date: '#626975',
        progress: '#EF8B2F',
        progress_bg: '#FFF4EA',
        none: '#626975',
        none_bg: '#F2F2F2',
        bluegray: '#BACCE0',
        link: '#488CE6',
        white70: 'rgba(255,255,255,0.7)',
    },
    grayScale: {
        gray1: '#F5F6F7',
        gray2: '#E6E8EC',
        gray3: '#CFD2D9',
        gray4: '#B5BAC4',
        gray5: '#A1A6B1',
        gray6: '#787F8F',
        gray7: '#626975',
        gray8: '#4D525C',
        gray9: '#373B42',
        gray10: '#222429',
    },
    input: {
        line: '#E6E8EC',
        line_focus: '#4D525C',
        placeholder: '#CFD2D9',
        disabled: '#F5F6F7',
        label: '#A1A6B1',
    },
    table: {
        th_color: '#9399a5',
        th_background: '#F4F6F9',
        line: '#F0F2F4',
    },
    button: {
        primary: '#4870F6',
        primary_hover: '#335FF5',

        light_primary: 'rgba(72, 112, 246, 0.07)',
        black: '#222429',
        black_hover: '#161819',
        darkgray: '#4D525C',
        darkgray_hover: '#373B42',
        gray: '#787F8F',
        gray_hover: '#626975',
        light_gray: '#F5F6F7',
        negative: '#F4554A',
        negative_hover: 'rgba(229, 79, 70, 1)',
        disabled: '#E6E8EC',
    },
}

declare module 'styled-components' {
    export interface DefaultTheme {
        layout: Subset<Keys<LAYOUT>>
        line: Subset<Keys<LINE>>
        color: Subset<Keys<COLOR>>
        grayScale: Subset<Keys<GRAY_SCALE>>
        input: Subset<Keys<INPUT>>
        table: Subset<Keys<TABLE>>
        button: Subset<Keys<BUTTON>>
    }
}

type Subset<T> = {
    [K in keyof T]?: T[K]
}
type Keys<T extends string | number | symbol> = { [K in T]: string }
export type LAYOUT = 'header_height' | 'sidebar_width' | 'form_input_height' | 'dashboard_input_height'
export type LINE = 'line1' | 'line2'
export type COLOR =
    | 'primary'
    | 'primary_light_hover'
    | 'primary_deep_hover'
    | 'primary_bg'
    | 'secondary'
    | 'background'
    | 'list_hover'
    | 'negative'
    | 'negative_bg'
    | 'positive'
    | 'positive_bg'
    | 'point_purple'
    | 'date'
    | 'progress'
    | 'progress_bg'
    | 'purple'
    | 'purple_bg'
    | 'none'
    | 'none_bg'
    | 'bluegray'
    | 'link'
    | 'white70'
export type GRAY_SCALE = 'gray1' | 'gray2' | 'gray3' | 'gray4' | 'gray5' | 'gray6' | 'gray7' | 'gray8' | 'gray9' | 'gray10'
export type INPUT = 'line' | 'line_focus' | 'placeholder' | 'disabled' | 'label'
export type TABLE = 'th_background' | 'line' | 'th_color'
export type BUTTON =
    | 'primary'
    | 'primary_hover'
    | 'light_primary'
    | 'black'
    | 'black_hover'
    | 'darkgray'
    | 'darkgray_hover'
    | 'gray'
    | 'gray_hover'
    | 'light_gray'
    | 'negative'
    | 'negative_hover'
    | 'disabled'
