import API from 'api'
import Button from 'components/atoms/Button'
import { Detail, SummaryTypes } from 'components/atoms/DetailTable'
import InputText from 'components/atoms/Input/InputText'
import Textarea from 'components/atoms/Input/Textarea'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import Append from 'components/oraganisms/Append'
import { NOTICE_CONTEXT } from 'components/template/GlobalNotice/context'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { GenericResponse } from 'models/Common'
import { TextFigkDetailResponse, TextFigkEditRequest } from 'models/TextFigk'
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FIGK_PATH } from 'shared/Router/Figk'
import { 에디터특수기호변경 } from 'shared/common'
import { ScrollPositionTop, isApplied, isPublished, turnNumbering } from 'shared/function'

const TotalTextDetail = () => {
    const navigate = useNavigate()
    const { id: detailId } = useParams()
    const { p: page } = useParams()
    const { user } = useFigAuth()
    const { Notice, closeNotice } = useModal()
    const [summary, setSummary] = useState<SummaryTypes[]>([])
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)
    //뒤로가기 경고 훅, 확인시 리스트로 이동
    useWarnningGoBack(FIGK_PATH.TOTAL, edit)
    const EDIT = !edit

    const [textEditData, setTextEditData] = useState<TextFigkEditRequest>({
        id: 0,
        title: '',
        subTitle: '',
        content: '',
        link: '',
    })

    const [textFigkDetail, setTextFigkDetail] = useState<TextFigkDetailResponse>()

    const { data: detailData, refetch: getDetail } = useGetQuery(['@getTextFigkDetail'], () => API.textFigk.getTextFigkDetail(detailId ?? ''), {
        select: (res: GenericResponse) => {
            const data: TextFigkDetailResponse = res.data

            return data
        },
        onSuccess: (data: TextFigkDetailResponse) => {
            const summaryArr: SummaryTypes[] = []
            if (data.contestStatus !== 'N' && data.contestStatus !== 'E')
                summaryArr.push({ text: '득표 수', data: `${data?.totalVote}`, data_color: 'positive' })

            if (data.isPublished === 'Y')
                summaryArr.push(
                    { text: '누적 좋아요 수', data: `${data?.like}` },
                    { text: '누적 공유 수', data: `${data?.shared}` },
                    { text: '누적 조회 수', data: `${data?.view}` }
                )

            setSummary(summaryArr)
            setTextFigkDetail(data)
            setTextEditData({
                id: data.id,
                title: data.title,
                subTitle: data.subTitle,
                content: data.content,
                tags: data.tag.map((i) => i.name).toString(),
                link: data.link,
            })
        },
        enabled: false,
    })

    const { mutate: trySave, error: saveError } = useTMutation(
        ['@UpdateTextFigk', detailId ?? 'U'],
        (data: TextFigkEditRequest) => API.textFigk.updateTextFigk(data),
        (data: GenericResponse) => editSuccessCallback(data)
    )

    const { mutate: tryDel, error: delError } = useTMutation(
        ['@DeleteTextFigk', detailId ?? 'D'],
        (i: number[]) => API.textFigk.deleteTextFigk(i),
        (data: GenericResponse) => delSuccessCallback(data)
    )

    const delSuccessCallback = (res: GenericResponse) => {
        navigate('/dashboard/figk/text_figk/total')
        closeNotice()
        return
    }
    const editSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            content: res.code === 200 ? '저장이 완료되었어요.' : res.message,
            cancel_button_text: '확인',
            confirmHandler: () => {
                return res.code === 200 ? window.location.reload() : () => {}
            },
        })
        setEdit(false)
    }

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)

    const onChangeInput = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, type: string) => {
        setTextEditData((prev) => ({
            ...prev,
            [type]: e.target.value,
        }))
    }
    // 태그 가져옴
    const getTags = (e: string) => {
        setTextEditData((prev) => ({
            ...prev,
            tags: e,
        }))
    }
    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(!edit)
        ScrollPositionTop()
    }

    const DeleteTextHandler = () => {
        Notice({
            type: 'confirm',
            content: NOTICE_CONTEXT.DELETE,
            confirm_button_text: '삭제하기',
            confirmHandler: () => {
                tryDel([textFigkDetail?.id ?? -1])
            },
        })
    }

    const back = () => {
        Notice({
            type: 'confirm',
            icon: 'notice_bang_mark',
            content: NOTICE_CONTEXT.WARNNING_FOR_PAGE_EXIT,
            confirmHandler: () => {
                setEdit(false)
                closeNotice()
            },
        })
    }

    const save = () => {
        Notice({
            type: 'confirm',
            content: NOTICE_CONTEXT.SAVE,
            confirm_button_text: '저장하기',
            confirmHandler: () => {
                trySave(textEditData)
            },
        })
    }
    //수정하기 버튼 렌더링 조건 : 투표 마감, 당선 ,낙선
    const EditBtnRenderCondition = textFigkDetail?.contestStatus === 'N' || textFigkDetail?.contestStatus === 'E'

    useEffect(() => {
        if (detailId) {
            // getTextFigkDetail()
            setSummary([])
            getDetail()
        }
    }, [])

    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? '글 수정' : '글 상세'}>
                {EDIT && (
                    <PageInfo>
                        <span>작성일: {textFigkDetail?.registeredAt}</span>
                        {textFigkDetail?.updatedAt && <span>수정일: {textFigkDetail?.updatedAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>
            <Detail.Container>
                <Detail.Title title='작가 정보' />
                <Detail.Row>
                    <Detail.Col title='필명'>{textFigkDetail?.authorName}</Detail.Col>
                    <Detail.Col title='조별'>{textFigkDetail?.groupName}조</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='ID'>{textFigkDetail?.authorEmail}</Detail.Col>
                </Detail.Row>
            </Detail.Container>
            <Detail.Container>
                <Detail.Title title='글 정보'>{summary.length > 0 && <Detail.Summary summary={summary} />}</Detail.Title>
                <Detail.Row>
                    <Detail.Col
                        title='회차'
                        color='darkgray'>
                        Vol.{turnNumbering(textFigkDetail?.week)}
                    </Detail.Col>
                </Detail.Row>
                {EDIT && (
                    <>
                        <Detail.Row>
                            <Detail.Col
                                title='발행 상태'
                                color='gray'
                                weight='500'>
                                {isPublished(textFigkDetail?.isPublished)}
                            </Detail.Col>
                        </Detail.Row>
                        <Detail.Row>
                            <Detail.Col
                                title='송고 상태'
                                weight='500'>
                                {isApplied(textFigkDetail?.contestStatus)}
                            </Detail.Col>
                        </Detail.Row>
                    </>
                )}

                <Detail.Row>
                    <Detail.Col title='제목'>
                        <InputText
                            readOnly={EDIT}
                            name='title'
                            onChange={(e) => onChangeInput(e, 'title')}
                            value={에디터특수기호변경(textEditData.title)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재'>
                        <InputText
                            readOnly={EDIT}
                            name='subject'
                            onChange={(e) => onChangeInput(e, 'subTitle')}
                            defaultValue={에디터특수기호변경(textEditData.subTitle)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='내용'>
                        <Textarea
                            readOnly={EDIT}
                            name='contents'
                            onChange={(e) => onChangeInput(e, 'content')}
                            defaultValue={에디터특수기호변경(textEditData.content)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재 URL'>
                        {EDIT ? (
                            <a
                                href={textEditData.link}
                                target='_blank'
                                rel='noreferrer'>
                                {에디터특수기호변경(textEditData.link)}
                            </a>
                        ) : (
                            <InputText
                                name='url'
                                onChange={(e) => onChangeInput(e, 'link')}
                                defaultValue={에디터특수기호변경(textEditData.link) || ''}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='태그'
                        color='gray'>
                        {EDIT ? (
                            <>
                                {textFigkDetail?.tag.map((_, i) => {
                                    return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>
                                })}
                            </>
                        ) : (
                            <Append
                                // tags 초기값 예외처리
                                tags={textEditData.tags === '' ? [] : textEditData.tags?.split(',') || []}
                                handler={getTags}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>
            {!EditBtnRenderCondition && (
                <ButtonBox>
                    {edit ? (
                        <>
                            <Button
                                $style='line_secondary'
                                onClick={back}>
                                돌아가기
                            </Button>

                            <Button
                                $style='solid_primary'
                                onClick={save}>
                                저장하기
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                $style='line_secondary'
                                onClick={DeleteTextHandler}>
                                삭제하기
                            </Button>

                            {EditBtnRenderCondition && (
                                <Button
                                    $style='line_primary'
                                    onClick={editToggleHandler}>
                                    수정하기
                                </Button>
                            )}
                        </>
                    )}
                </ButtonBox>
            )}
        </Wrapper>
    )
}

export default TotalTextDetail
