import API from 'api'
import { AxiosError } from 'axios'
import AppendItem from 'components/atoms/AppendItem'
import InputText from 'components/atoms/Input/InputText'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { ComponentPropsWithRef, PropsWithChildren, useEffect, useState } from 'react'
import { styled } from 'styled-components'

const StyledAppendContainer = styled.div`
    width: 100%;
    .errorMsg {
        font-size: 1.2rem;
        color: ${(p) => p.theme.color.negative};
        padding: 10px 14px 0;
    }
`

export const StyledAppendContents = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    padding-block-start: 1rem;
`

interface AppendProps extends ComponentPropsWithRef<'div'>, PropsWithChildren {
    tags?: string[]
    handler?: (e: string) => void
    limit?: number
    isRegist?: boolean
}

const Append = ({ tags, handler, limit, isRegist = false }: AppendProps) => {
    const [append, setAppend] = useState<string[]>(tags ? tags : [])
    const [value, setValue] = useState<string>(tags ? tags.toString() : '')
    const [errorMsg, setErrorMsg] = useState<string>('')

    let copyTags = new Set(append)
    let unique = [...Array.from(copyTags)]

    const { refetch: invalidTag } = useGetQuery(['@invalidTag'], () => API.tag.invalidTag(value.trim()), {
        select: (res: GenericResponse) => {
            return res.data
        },
        onSuccess: (data) => {
            setAppend([...append, value.trim()])
            setValue('')
        },
        onError: (err: AxiosError) => {
            const errData = err.response?.data as GenericResponse

            if (errData && errData.code === 409) {
                setErrorMsg(errData.message)
            }
        },
        enabled: false,
    })

    //태그 삭제 함수 핸드러
    const TagDeleteHandler = (value: string) => {
        setAppend(append.filter((filter) => filter !== value))
    }
    const onEnterHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (limit && unique.length >= limit) {
            return
        }
        if (e.key === 'Enter' && value.trim() !== '') {
            if (value.trim().length < 2) {
                setErrorMsg('태그명은 2자이상 입력해주세요.')
                return
            }

            if (isRegist) invalidTag()
            else {
                setAppend([...append, value.trim()])
                setValue('')
            }
        }
    }
    useEffect(() => {
        setValue('')

        // 초기값으로 인한 예외처리
        if (unique.toString() !== '') {
            handler && handler(unique.toString())
        }
    }, [append])

    return (
        <StyledAppendContainer>
            <InputText
                value={value}
                placeholder='엔터를 누르면 태그가 추가됩니다.(최대 5개)'
                onChange={(e) => {
                    setErrorMsg('')
                    setValue(e.target.value.replace(/ /g, ''))
                }}
                onKeyPress={(e) => onEnterHandler(e)}
            />
            {errorMsg && <p className='errorMsg'>{errorMsg}</p>}

            {unique.length > 0 && (
                <StyledAppendContents>
                    {unique.map((_, index) => {
                        return (
                            <AppendItem
                                text={_}
                                key={`${_}_${index}`}
                                onClick={() => TagDeleteHandler(_)}
                            />
                        )
                    })}
                </StyledAppendContents>
            )}
        </StyledAppendContainer>
    )
}

export default Append
