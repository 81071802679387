import { S } from 'components/atoms/Summary/index.styled'
import { SummaryType } from 'models/Common'
import Icon from '../Icon'
interface SummaryProps {
    summary: SummaryType[]
}
const Summary = ({ summary }: SummaryProps) => {
    return (
        <S.Container $length={summary.length}>
            {summary.map((_, index) => {
                const { icon, color, $background, title, data, onClick } = _
                return (
                    <S.Card
                        key={title}
                        radius={12}
                        onClick={onClick}
                        className={onClick ? 'cursor_pointer' : ''}>
                        <S.Icon $background={$background}>
                            <Icon
                                icon={icon}
                                color={color}
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                            />
                        </S.Icon>
                        <S.Contents>
                            <S.Head>{title}</S.Head>
                            <S.Body>{data}</S.Body>
                        </S.Contents>
                        {onClick && (
                            <Icon
                                icon='arrow_20x20'
                                color='gray4'
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                            />
                        )}
                    </S.Card>
                )
            })}
        </S.Container>
    )
}

export default Summary
