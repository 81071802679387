import API from "api";
import Button from "components/atoms/Button";
import { Detail } from "components/atoms/DetailTable";
import PageTitle from "components/atoms/PageTitle";
import Pagination from "components/atoms/Pagination";
import Select from "components/atoms/Select";
import Switch from "components/atoms/Switch";
import {
  TBody,
  THead,
  Table,
  TableControlBox,
  Td,
  Th,
  Tr,
} from "components/atoms/Table";
import { Wrapper } from "components/atoms/Wrapper/index.styled";
import { ButtonBox } from "components/molecules/ButtonBox/index.styled";
import TableNoData from "components/molecules/Nodata/Table";
import { useModal } from "hook/useModal";
import usePreventRefresh from "hook/usePreventRefresh";
import useTMutation from "hook/useTMutation";
import useGetQuery from "hook/useTQuery";
import useWarnningGoBack from "hook/ussWarnningGoBack";
import { GenericResponse, SearchParamModel } from "models/Common";
import { ActiveMemberModel, MemberUpdateGroup } from "models/Member";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  returnMemberInfo,
  returnMemberTextFigk,
} from "shared/QueryOption/member";
import { returnGroupInTextFigk } from "shared/QueryOption/textFigk";
import { FIGK_PATH } from "shared/Router/Figk";
import { MemberInfoColWidth, MemberInfoTh } from "shared/common";
import {
  isApplied,
  isAppliedColor,
  isPublished,
  isPublishedColor,
} from "shared/function";
import { maskingFunc } from "shared/masking";
const FigkWriterDetail = () => {
  const { id: detailId } = useParams();
  const navigate = useNavigate();
  const { Notice, closeNotice } = useModal();
  usePreventRefresh(true);
  useWarnningGoBack(FIGK_PATH.FIGK_MEMBER);

  const [filter, setFilter] = useState<SearchParamModel>({
    authorId: Number(detailId) ?? "",
    per: 5,
    page: 1,
  });

  const [memberStatusControl, setMemberStatusControl] =
    useState<ActiveMemberModel>({
      authorId: detailId ?? "",
      status: "",
    });

  const [memberGroup, setMemberGroup] = useState<MemberUpdateGroup>({
    groupId: filter.groupId ?? -1,
    authorId: Number(detailId) ?? -1,
  });

  const { data: member, refetch: getMemberInfo } = useGetQuery(
    [`@getMemberInfo${detailId + ""}`],
    () => API.member.getMemberInfo(detailId ?? ""),
    {
      ...returnMemberInfo,
      enabled: !!detailId,
      onSuccess: (data) => {
        setMemberStatusControl((prev) => ({
          ...prev,
          status: data.status,
        }));
      },
    }
  );

  const { data: group } = useGetQuery(
    ["@getTextFigkGroup"],
    () => API.textFigk.getGrouopInTextFigk(),
    returnGroupInTextFigk
  );

  const { data: memberTextFigk, refetch: getMemberTextFigk } = useGetQuery(
    [`@getMemberTextFigk${detailId + ""}`],
    () => API.textFigk.getTextFigk(filter),
    {
      ...returnMemberTextFigk,
      enabled: !!detailId,
    }
  );

  const { mutate: tryActivation, error: activeError } = useTMutation(
    ["@ActiveToggleMember", detailId ?? "U"],
    (s: ActiveMemberModel) => API.member.isActiveMember(s),
    (rs: GenericResponse) => activeMemberSuccessCallback(rs)
  );

  const { mutate: tryGroupUpdate, error: updateError } = useTMutation(
    ["@MemberGroup", detailId ?? "U"],
    (s: MemberUpdateGroup) => API.member.updateMemberGroup(s),
    (rs: GenericResponse) => updateMemberGroupSuccessCallback(rs)
  );

  const { mutate: tryDel, error: delError } = useTMutation(
    ["@MemberDel", detailId ?? "D"],
    (s: number) => API.member.deleteMember(s),
    (rs: GenericResponse) => delMemberSuccessCallback(rs)
  );

  const delMemberSuccessCallback = (rs: GenericResponse) => {
    closeNotice();
    navigate(FIGK_PATH.FIGK_MEMBER);
  };
  const updateMemberGroupSuccessCallback = (rs: GenericResponse) => {
    Notice({
      type: "alert",
      icon: "notice_bang_mark",
      content: "저장이 완료되었습니다.",
      confirmHandler: () => {
        closeNotice();
        navigate(FIGK_PATH.FIGK_MEMBER);
      },
    });
  };
  const activeMemberSuccessCallback = (res?: GenericResponse) => {
    Notice({
      type: "alert",
      icon: "notice_bang_mark",
      content: `계정이 ${
        memberStatusControl.status === "N" ? "활성화" : "비활성화"
      } 되었습니다.`,
      confirmHandler: () => {
        closeNotice();
      },
    });
  };

  const onChangeSelectFilter = (e: string | null, type: string) => {
    setMemberGroup((prev) => ({
      ...prev,
      groupId: Number(e),
    }));
  };

  const setPage = (e: number) => {
    setFilter((prev) => ({
      ...prev,
      page: e,
    }));
  };

  const toggleActive = useCallback(
    (s: boolean) => {
      setMemberStatusControl((prev) => ({
        ...prev,
        status: s ? "Y" : "N",
      }));

      tryActivation({ authorId: detailId ?? "", status: s ? "Y" : "N" });
    },
    [member, memberStatusControl.status]
  );

  useEffect(() => {
    getMemberTextFigk();
  }, [filter]);

  useEffect(() => {
    getMemberInfo();
    getMemberTextFigk();
  }, []);

  const defaultIndex =
    member && member.groupName !== "없음" && group
      ? group?.findIndex((i) => i.title === member?.groupName)
      : 0 ?? 0;

  return (
    <Wrapper $form="detail" $whiteBoard radius={12}>
      <PageTitle title="작가 상세">
        <Switch
          label={
            memberStatusControl.status === "Y" ? "계정 활성화" : "계정 비활성화"
          }
          checked={memberStatusControl.status === "Y"}
          onChange={(e) => toggleActive(e.target.checked)}
        />
      </PageTitle>
      <Detail.Container>
        <Detail.Title title="작가 정보" />

        <Detail.Row>
          <Detail.Col title="이름">{maskingFunc.name(member?.name)}</Detail.Col>
          <Detail.Col title="조별">
            <Select
              // defaultV={group && group[defaultIndex ?? 0] ? group[defaultIndex ?? 0].title ?? undefined : undefined}
              defaultStatus={member?.groupId ? String(member.groupId) : null}
              options={group ?? []}
              handler={(e) => onChangeSelectFilter(e, "groupId")}
            />
          </Detail.Col>
        </Detail.Row>
        <Detail.Row>
          <Detail.Col title="필명">{member?.nickname}</Detail.Col>
          <Detail.Col title="ID">{member?.email}</Detail.Col>
        </Detail.Row>
        <Detail.Row>
          <Detail.Col title="휴대폰번호">
            {maskingFunc.phone(member?.phone)}
          </Detail.Col>
          <Detail.Col title="인스타그램">{member?.instagram}</Detail.Col>
        </Detail.Row>
        {/* <Detail.Row>
                    <Detail.Col title='자기소개 파일'>
                        <a
                            href=''
                            download>
                            홍길동자기소개서.pdf
                        </a>
                    </Detail.Col>
                    <Detail.Col title='포트폴리오 파일'>
                        <a
                            href=''
                            download>
                            홍길동포트폴리오.pdf
                        </a>
                    </Detail.Col>
                </Detail.Row> */}
        <Detail.Row>
          <Detail.Col title="홈페이지">{member?.homepage}</Detail.Col>
        </Detail.Row>
        <Detail.Row>
          <Detail.Col title="블로그">{member?.blog}</Detail.Col>
        </Detail.Row>
      </Detail.Container>

      <Detail.Container>
        <Detail.Title title="TEXT FIGK 리스트" />
        <Table>
          <colgroup>
            {MemberInfoColWidth.map((_, i) => {
              return <col width={_} key={i} />;
            })}
          </colgroup>
          <THead>
            <Tr>
              {MemberInfoTh.map((_, i) => {
                return <Th key={`member_I_Th_${i}`}>{_}</Th>;
              })}
            </Tr>
          </THead>
          <TBody>
            {memberTextFigk &&
              memberTextFigk?.list.map((_, i) => {
                return (
                  <Tr key={`${_.authorName}_${i}`}>
                    <Td color={isPublishedColor(_.isPublished)}>
                      {isPublished(_.isPublished)}
                    </Td>
                    <Td color={isAppliedColor(_.contestStatus)}>
                      {isApplied(_.contestStatus)}
                    </Td>
                    <Td align="left">{_.title}</Td>
                    <Td color="date">{_.registeredAt}</Td>
                    <Td color="date">{_.updatedAt ?? "-"}</Td>
                  </Tr>
                );
              })}
            {!memberTextFigk?.list.length && <TableNoData />}
          </TBody>
        </Table>
        <TableControlBox>
          <Pagination
            currentPage={filter.page}
            limit={filter.per}
            setCurrentPage={setPage}
            isLast={memberTextFigk?.isLast || false}
            totalItem={memberTextFigk?.totalCount ?? 0}
          />
        </TableControlBox>
      </Detail.Container>

      <ButtonBox>
        <Button
          $style="line_secondary"
          onClick={() =>
            Notice({
              type: "confirm",
              icon: "notice_bang_mark",
              content: "해당 작가의 등록 정보를 삭제할까요?",
              confirm_button_text: "삭제하기",
              confirmHandler: () => {
                tryDel(Number(detailId) ?? 0);
              },
            })
          }
        >
          삭제하기
        </Button>

        <Button
          $style="solid_primary"
          disabled={memberGroup.groupId === -1}
          onClick={() =>
            Notice({
              type: "confirm",
              icon: "notice_bang_mark",
              content: "변경한 내용으로 저장할까요?",
              confirm_button_text: "저장하기",
              confirmHandler: () => {
                if (memberGroup) tryGroupUpdate(memberGroup);
              },
            })
          }
        >
          저장하기
        </Button>
      </ButtonBox>
    </Wrapper>
  );
};

export default FigkWriterDetail;
