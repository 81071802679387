import { S } from "components/template/GlobalNav/index.styled";
import { useFigAuth } from "figAuthProvider";
import { ProjectRouterList } from "models/Common";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { toggleMenuValue } from "recoil/atom";
import { FIGK_ROUTER, ZER01NE_ROUTER } from "shared/Router/Figk";
import { getStorage } from "util/storage";
import Box from "./box";

const GlobalNav = () => {
  const { user, login } = useFigAuth();
  const KEY = "current_menu";

  const getFormValue = localStorage.getItem(KEY);

  const activeMenuValue = useRecoilValue(toggleMenuValue);
  const [subMenuValue, setSubMenuValue] = useState<string>("");
  const [menuActive, setMenuActive] = useState<number>(
    () => Number(getFormValue) ?? 0
  );

  // 프로젝트 추가된 후 로직 변경 예정
  const [filterMenu, setFilterMenu] = useState<ProjectRouterList>({
    FIGK: FIGK_ROUTER[0].children,
    ZER01NE: ZER01NE_ROUTER[0].children,
  });

  const getLayoutFormState = (value: number) => {
    localStorage.setItem(KEY, String(value));
    setMenuActive(value);
  };

  const MainMenuClickHandler = (idx: number) => {
    getLayoutFormState(idx);
    if (menuActive === idx) {
      getLayoutFormState(0);
    }
  };

  const selectedRender =
    activeMenuValue === 6 || Number(getStorage("project")) === 6
      ? "FIGK"
      : activeMenuValue === 7 || Number(getStorage("project")) === 7
      ? "ZER01NE"
      : null;

  useEffect(() => {
    login();
  }, [menuActive, subMenuValue]);

  return (
    <S.Container id="globalNav">
      {/* DASHBOARD 작업 후 완전 삭제 */}
      {/* {(activeMenuValue === -1 || activeMenuValue === 0) && <div className='box'>프로젝트를 선택해주세요</div>} */}

      {selectedRender != null &&
        filterMenu[selectedRender].map((router: any, index: number) => {
          return (
            <Fragment key={`${router.title}_${index}`}>
              {router.title && (
                <Box
                  type={selectedRender}
                  index={index}
                  router={router}
                  setSubPath={(e) => setSubMenuValue(e)}
                  MainMenuClickHandler={MainMenuClickHandler}
                />
              )}
            </Fragment>
          );
        })}
    </S.Container>
  );
};

export default GlobalNav;
