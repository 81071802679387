import { Editor } from "@tinymce/tinymce-react";
import * as DOMPurify from "dompurify";
import { FC, useCallback, useRef, useState } from "react";
import { S } from "./index.styled";
interface ITextEditor {
  value: string;
  onChange: (e: string) => void;
  initialValue?: string;
  disabled?: boolean;
  placeholder?: string;
}
const TextEditor: FC<ITextEditor> = ({
  value,
  initialValue,
  onChange,
  disabled,
  placeholder,
}) => {
  const [newValue, setValue] = useState<string>("");
  const editorRef = useRef<Editor>(null);
  const renderRef = useRef<HTMLDivElement>(null);
  const onEditorChange = useCallback((value: string) => {
    onChange(value);
    setValue(value);
  }, []);
  const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

  return (
    <>
      {!disabled ? (
        <>
          <S.EditorArea
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
          />
        </>
      ) : (
        <>
          <input
            id="my-file"
            type="file"
            name="my-file"
            style={{ display: "none" }}
          />
          <Editor
            ref={editorRef}
            initialValue={initialValue ? initialValue : ""}
            apiKey={TINYMCE_API_KEY}
            value={value}
            onEditorChange={(e) => {
              onEditorChange(e);
            }}
            onInit={(evt, editor) => setValue(editor.getContent())}
            init={{
              referrer_policy: "origin",
              // export_cors_hosts: [`${BASE_URL}`],
              placeholder: placeholder ?? "내용을 입력해주세요.",
              width: "100%",
              height: 700,
              menubar: false,
              plugins: ["image"],
              paste_data_images: true,
              automatic_uploads: true,
              // images_upload_url: BASE_URL,
              toolbar:
                "undo redo | fontsize | anchor | forecolor | backcolor | styleselect  | fontsizeselect  | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ",
              // 'undo redo | image | fontsize | link | styleselect  | fontsizeselect  | bold italic  strikethrough    ',
              // resize: true,
              // file_picker_callback: (callback, value, meta) => {
              //     if (meta.filetype === 'image') {
              //         let input = document.getElementById('my-file') as HTMLInputElement
              //         if (!input) return
              //         input.click()
              //         input.onchange = function () {
              //             let file = (input as any)?.files[0]
              //             let reader = new FileReader()
              //             reader.onload = function (e: ProgressEvent<FileReader>) {
              //                 callback((e.target as any).result, {
              //                     alt: file.name,
              //                 })
              //             }
              //             reader.readAsDataURL(file)
              //         }
              //     }
              // },
              // images_upload_url: 'bucket-address',
              // images_upload_handler: async (blobInfo) => {
              //     let formData = new FormData()
              //     formData.append('image', blobInfo.blob())
              //     try {
              //         console.log(formData)
              //         const res = await apiHeader(true, 'b', true).post(
              //             `figk-common/image-upload`,
              //             formData,
              //             {
              //                 headers: {
              //                     'Content-Type': 'multipart/form-data',
              //                 },
              //             }
              //         )
              //         console.log(res)
              //         if (res.data) {
              //             return res.data.data
              //         } else {
              //             throw new Error('업로드 도중 오류 발생')
              //         }
              //     } catch (err) {
              //         throw new Error('업로드 도중 오류 발생')
              //     }
              // },
            }}
          />
        </>
      )}
    </>
  );
};

export default TextEditor;
