import { styled } from "styled-components";
import { mediaQuery } from "theme/mediaQuery";

const RootContainer = styled.div`
  --paddingInline: 3rem;
  ${mediaQuery("mobile")} {
    --paddingInline: 16px;
  }
  .pagetitle__wrapepr {
    ${mediaQuery("mobile")} {
      padding-inline: var(--paddingInline);
    }
  }
`;

const VotingCounter = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.grayScale.gray6};
  font-size: 1.3rem;
  font-weight: 600;

  span {
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.grayScale.gray9};
    font-size: 1.3rem;
    strong {
      color: ${(p) => p.theme.color.primary};
      font-size: 1.8rem;
      font-weight: 600;
      margin-inline: 1.2rem 0.4rem;
    }
  }
`;

const ListControlBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 2.2rem;
  ${mediaQuery("mobile")} {
    padding-inline: var(--paddingInline);
  }
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${mediaQuery("mobile")} {
    padding-inline: var(--paddingInline);
  }
`;

const AccordionContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.line.line1};
  box-shadow: 0px 0px 8px 0px rgba(113, 117, 126, 0.04);
`;

const AccordionHead = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* height: 50px; */
  cursor: pointer;
  padding-inline: var(--paddingInline);
  gap: 10px;
  padding-block: 15px;
  &.open {
    align-items: initial;
    height: initial;
    title {
      height: initial;
      line-height: 1.5;
    }
    .right {
      .arrow__wrap {
        transform: translateY(calc(1.5rem / 4));
        svg {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
      button {
        /* transform: translateY(calc(-1 * calc(1.5rem / 4))); */
      }
    }
  }
  .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${(p) => p.theme.grayScale.gray10};
    flex: 1;
    height: 30px;
    line-height: 30px;
    ${mediaQuery("tablet")} {
      height: initial;
      line-height: 1.5;
    }
  }
  .right {
    display: flex;
    gap: 2.4rem;
    .arrow__wrap {
      height: 14px;

      transform: translateY(calc(1.5rem / 4));
      svg {
        transform-origin: center;
      }
    }
    /* .voted {
      display: inline-block;
      border-radius: 999px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1;
      color: ${(p) => p.theme.color.positive};
      background-color: ${(p) => p.theme.color.positive_bg};
      padding: 6px 10px;
      margin-inline-end: -8px;
    } */
  }
`;

const AccordionBody = styled.div`
  padding-block: 0px 20px;
  padding-inline: var(--paddingInline);
  .subject {
    display: inline-block;
    font-size: 1.3rem;
    color: ${(p) => p.theme.grayScale.gray6};
    padding-block-end: 1.2rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .contents {
    /* 393C40 */
    color: ${(p) => p.theme.grayScale.gray9};
    padding-block-end: 2rem;
    text-align: justify;
    word-break: break-all;
  }
  .tags {
    font-size: 1.3rem;
    font-weight: 300;
    color: ${(p) => p.theme.grayScale.gray4};
    padding-block-end: 2rem;
  }
  .writer {
    font-size: 1.3rem;
    font-weight: 500;
    color: ${(p) => p.theme.grayScale.gray9};
    &:before {
      content: "by";
      color: #838990;
      padding-right: 4px;
      font-weight: 300;
      font-size: 1.3rem;
    }
  }
`;

const MobileVotingButtonWrap = styled.div`
  padding-inline: var(--paddingInline);
  padding-bottom: 12px;
`;

export const S = {
  RootContainer,
  VotingCounter,
  ListControlBox,
  ListBox,
  AccordionContainer,
  AccordionHead,
  AccordionBody,
  MobileVotingButtonWrap,
};
