import { ApexOptions } from 'apexcharts'
import { theme } from 'theme'
const BAR_COLOR = 'rgba(140,217,255,0.4)'
export const barChartConfig: ApexOptions = {
    chart: {
        type: 'bar',
        background: 'transparent',
        toolbar: { show: false },
        fontFamily: 'Pretendard',
    },
    plotOptions: {
        bar: {
            columnWidth: '30px',
        },
    },
    legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'right',
        floating: false,
        fontSize: '12px',
        fontFamily: 'Pretendard',
        fontWeight: 400,
        customLegendItems: ['득표 수'],
        labels: {
            colors: ['rgba(120, 127, 143, 1)'],
            useSeriesColors: false,
        },
        markers: {
            width: 20,
            height: 12,
            strokeWidth: 1,
            strokeColor: '#B8E7FF',
            fillColors: [BAR_COLOR],
            radius: 0,
            offsetX: -4,
            offsetY: 1.5,
        },
        itemMargin: {
            horizontal: 0,
            vertical: 0,
        },
        onItemClick: {
            toggleDataSeries: true,
        },
        onItemHover: {
            highlightDataSeries: true,
        },
    },

    states: {
        normal: {
            filter: {
                type: 'darken',
                value: 1,
            },
        },
        hover: {
            filter: {
                type: 'darken',
                value: 0.9,
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'darken',
                value: 1,
            },
        },
    },

    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: [BAR_COLOR],
        width: 1,
    },
    grid: {
        show: true,
        borderColor: '#f5f6f7',
        position: 'back',
        xaxis: {
            lines: {
                show: false,
            },
        },
    },

    xaxis: {
        axisTicks: { show: false },
        axisBorder: { show: true, color: '#d9dbe1', offsetY: -1 },
        labels: {
            show: true,
            style: {
                colors: theme.grayScale.gray6,
            },
        },
    },
    yaxis: {
        labels: {
            show: true,
            style: {
                colors: ['#cfd2d9'],
            },
            offsetY: 2,
        },
    },

    //바 스타일
    dataLabels: {
        enabled: false,
    },
}
