import { SearchParamModel } from "models/Common";
import { atom } from "recoil";
import { PAGE_PER } from "shared/common";

export const globalFilterDefaultValue: SearchParamModel = {
  page: 1,
  per: PAGE_PER,
  dateType: "U",
};

export const globalFilter = atom<SearchParamModel>({
  key: "globalFilter",
  default: globalFilterDefaultValue,
});

export const globalContestFilterDefaultValue: SearchParamModel = {
  page: 1,
  per: PAGE_PER,
  isContest: true,
  dateType: "A",
  orderType: "A",
};

export const globalContestFilter = atom<SearchParamModel>({
  key: "globalContestFilter",
  default: globalContestFilterDefaultValue,
});

export const globalFilterReset = atom<boolean>({
  key: "globalFilterReset",
  default: false,
});
