import createFormData from 'hook/useCreateFormData'
import createUrlParam from 'hook/useCreateUrlParam'
import { ArtFigkEditModel, ArtFigkRegistModel } from 'models/ArtFigk'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { apiHeader } from 'shared/function'
const artFigk = {
    getArtFigk: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`art-figk${req}`)
        return response.data
    },

    createArtFigk: async (d: ArtFigkRegistModel) => {
        const req = createFormData(d)

        const response = await apiHeader(true, 'a', true).post<GenericResponse>(`art-figk`, req)
        return response.data
    },

    updateArtFigk: async (d: ArtFigkEditModel) => {
        const req = createFormData(d)

        const response = await apiHeader(true, 'a', true).put<GenericResponse>(`art-figk`, req)
        return response.data
    },

    publishArtFigk: async (id: string) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`art-figk/publish`, { id: id })
        return response.data
    },

    deleteArtFigk: async (id: number[]) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`art-figk`, { data: { ids: id } })
        return response.data
    },
    getArtFigkDetail: async (id: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`art-figk?id=${id}`)
        return response.data
    },
}

export default artFigk
