import { styled } from 'styled-components'

const Container = styled.div``

const Label = styled.label<{ $label: string }>`
    display: inline-block;
    padding-block-end: 0.6rem;
    &:before {
        content: ${(p) => `"${p.$label}"`};
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 400;
        color: ${(p) => p.theme.input.label};
    }
`
export const StyleLabelBox = {
    Container,
    Label,
}
