import SimpleBar from 'components/atoms/Chart/Bar'
import { styled } from 'styled-components'
import './Bar/style.css'

const ChartContainer = styled.div`
    padding-bottom: 2.5rem;
`

export const Chart = {
    ChartContainer,
    SimpleBar,
}

export const ToolTipConstructor = (필명: string, 득표: number, 타이틀: string) => {
    return `<div class="tooltip_container"><div class="tooltip_head"><span>${필명}</span><span>${득표}표</span></div><p class="ellipsis23">${타이틀}</p></div>`
}
