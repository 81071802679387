import Icon from 'components/atoms/Icon'
import FilterSearch from 'components/molecules/FilterSearch/index'
import { S } from 'components/oraganisms/Filter/index.styled'
import { PropsWithChildren, useState } from 'react'
import { useRecoilState } from 'recoil'
import { globalFilterReset } from 'recoil/atom/globalFilter'

interface FilterProps extends PropsWithChildren {
    search: React.ReactNode
}
const Filter = ({ children, search }: FilterProps) => {
    const [show, setShow] = useState<boolean>(true)
    const [globalReset, setGlobalReset] = useRecoilState(globalFilterReset)

    const onFilterReset = () => {
        if (globalReset) return

        setGlobalReset(true)
        setTimeout(() => {
            setGlobalReset(false)
        }, 500)
    }
    return (
        <S.Container>
            <FilterSearch onClick={() => setShow(!show)}>{search}</FilterSearch>
            {show && (
                <S.Contents>
                    <div className='title'>
                        <h3>필터 선택</h3>
                        <S.Reset
                            type='button'
                            onClick={onFilterReset}>
                            <Icon
                                icon={'reset_13x13'}
                                color={'gray5'}
                            />{' '}
                            초기화
                        </S.Reset>
                    </div>
                    <div className='filterItem'>{children}</div>
                </S.Contents>
            )}
        </S.Container>
    )
}

export default Filter
