import { GenericResponse } from 'models/Common'
import { apiHeader } from 'shared/function'
const project = {
    getProject: async () => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`project`)
        return response.data
    },
}

export default project
