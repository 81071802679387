import { Outlet } from 'react-router-dom'
import { Common } from 'theme/common'
import { DatePickerStyle } from 'theme/datePicker'
import { EditorStyle } from 'theme/editor'
import { GlobalFont } from 'theme/globalFont'
import { GlobalStyle } from 'theme/globalStyle'
import { Layout } from 'theme/layout'

export const Root = () => {
    return <Outlet />
}
function App() {
    return (
        <>
            {/* <FigAuthProvider> */}
            {/* <FigPermissionProvider> */}
            <GlobalFont />
            <Layout />
            <GlobalStyle />
            <Common />
            <DatePickerStyle />
            <EditorStyle />
            <Root />
            {/* </FigPermissionProvider> */}
            {/* </FigAuthProvider> */}
        </>
    )
}

export default App
