import API from 'api'
import { AxiosError } from 'axios'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import PageTitle from 'components/atoms/PageTitle'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { Fragment, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { returnVoting } from 'shared/QueryOption/textFigk'
import { FIGK_PATH } from 'shared/Router/Figk'
import { 에디터특수기호변경 } from 'shared/common'
import { turnNumbering } from 'shared/function'
import { S } from './index.styled'

const TurnDown = () => {
    const { user } = useFigAuth()
    const { Notice, closeNotice } = useModal()
    const navigate = useNavigate()
    const [active, setActive] = useState<number>(0)

    const [ids, setIds] = useState<number[]>([])
    const queryClient = useQueryClient()

    const { data: turnDown, refetch: getTurnDown } = useGetQuery(
        ['@getTurnDownList'],
        () => API.sending.getTurnDownList(),
        returnVoting
    )

    const { mutate: doTurnDown, isError: turunDownError } = useTMutation(
        ['@DoTurnDown', 'U'],
        (idsList: Array<number>) => API.sending.completeTurnDown(idsList),
        (data: GenericResponse) => turnDownSuccessCallback(data),
        {
            onError: (err: AxiosError) => {
                if (err.response?.status === 409) {
                    Notice({
                        icon: 'notice_bang_mark',
                        type: 'alert',
                        content: '이미 투표에 참여했습니다.',
                    })
                }
            },
        }
    )

    const turnDownSuccessCallback = (res: GenericResponse) => {
        if (res.code === 200) {
            queryClient.invalidateQueries('@getTurnDownList')
            completeTurnDown()
        }
    }

    const completeTurnDown = () => {
        Notice({
            icon: 'notice_checked',
            type: 'alert',
            content: '송고 심사가 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(`${FIGK_PATH.SENDING}`)
            },
        })
    }

    const tryTurnDown = () => {
        if (ids.length > 0) {
            doTurnDown(ids)
        } else {
            completeTurnDown()
        }
    }
    const listActiveHandler = (idx: number) => {
        setActive(idx)
        if (active === idx) {
            setActive(0)
        }
    }

    const pushToAuthorList = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
        e.stopPropagation()
        // if (vote && authorList.length >= vote.summary.maxVote) return

        let prev = [...ids]

        prev.push(i)

        if (ids.includes(i)) {
            setIds(prev.filter((_) => _ !== i))
            return
        }

        setIds(prev.filter((v, i) => prev.findIndex((x) => x === v) === i))
    }

    return (
        <>
            <PageTitle title={`Vol.${turnNumbering(user?.textWeek)} ${user?.groupName}조 심사`} />
            <S.ListControlBox justifycontent='flex-end'>
                <Button
                    $size='36'
                    $style='solid_secondary'
                    onClick={() =>
                        Notice({
                            type: 'confirm',
                            title: '송고 심사를 완료하시겠습니까?',
                            content: '심사 후에는 수정이 불가능합니다.',
                            confirm_button_text: '완료하기',
                            confirmHandler: tryTurnDown,
                        })
                    }>
                    심사 완료하기
                </Button>
            </S.ListControlBox>
            <S.ListBox>
                {turnDown?.list.map((_, i) => {
                    const ACTIVE = active === _.id
                    return (
                        <S.AccordionContainer key={`vote_list_${i}`}>
                            <S.AccordionHead onClick={() => listActiveHandler(_.id)}>
                                <span className='title'>{에디터특수기호변경(_.title)}</span>
                                <div className='right'>
                                    {/* 투표했을때 일때 버튼 $스타일 line_primary */}
                                    <Button
                                        type='button'
                                        $size='30'
                                        onClick={(e) => pushToAuthorList(e, _.id)}
                                        $style={ids.includes(_.id) ? 'line_negative' : `line_toggle`}>
                                        반려하기
                                    </Button>
                                    <Icon
                                        icon={'arrow_14x14'}
                                        color={'gray8'}
                                        transform={ACTIVE ? 'rotate(180)' : ''}
                                    />
                                </div>
                            </S.AccordionHead>
                            {ACTIVE && (
                                <S.AccordionBody>
                                    <a
                                        href='javascript(0);'
                                        target='_blank'
                                        rel='noreferrer'
                                        className='subject'>
                                        {에디터특수기호변경(_.subTitle)}
                                    </a>
                                    <p className='contents'>{에디터특수기호변경(_.content)}</p>
                                    <div className='tags'>
                                        {_.tag &&
                                            _.tag.map((_, i) => {
                                                return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>
                                            })}
                                    </div>
                                    <span className='writer'>{_.authorName}</span>
                                </S.AccordionBody>
                            )}
                        </S.AccordionContainer>
                    )
                })}
            </S.ListBox>
        </>
    )
}

export default TurnDown
