import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { TextFigkEditRequest } from 'models/TextFigk'
import { apiHeader } from 'shared/function'
import { baseApi } from './../Common/index'
const textFigk = {
    getTextFigk: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk${req}`)
        return response.data
    },

    getTextFigkDetail: async (id: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk?id=${id}`)
        return response.data
    },

    getGrouopInTextFigk: async () => {
        const response = await baseApi.get<GenericResponse>(`figk-common/group`)
        return response.data
    },

    updateTextFigk: async (data: TextFigkEditRequest) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`text-figk`, data)
        return response.data
    },

    deleteTextFigk: async (i: number[]) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`text-figk`, { data: { ids: i } })
        return response.data
    },
}

export default textFigk
