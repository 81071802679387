import { IconColor, IconName } from 'components/atoms/Icon/icon'
import { ModalTypes } from 'hook/useModal'
import { atom } from 'recoil'

export interface ModalType {
    isOpen?: boolean
    content: React.ReactNode | string
    bottomSheet?: boolean
}

export interface NoticeType extends ModalType {
    icon?: IconName
    icon_color?: IconColor
    type?: ModalTypes
    title?: string
    loading?: boolean
    cancel_button_text?: string
    confirm_button_text?: string
    confirmHandler?: VoidFunction
}

export const modalState = atom<ModalType>({
    key: 'modalState',
    default: {
        isOpen: false,
        content: '',
        bottomSheet: false,
    },
})

export const noticeState = atom<NoticeType>({
    key: 'noticeState',
    default: {
        icon: 'notice_bang_mark',
        icon_color: 'primary',
        type: '',
        title: '',
        isOpen: false,
        loading: false,
        content: '',
        cancel_button_text: '',
        confirm_button_text: '',
        confirmHandler: () => {},
    },
})

export const loadingState = atom<boolean>({
    key: 'loading',
    default: false,
})
