import API from 'api'
import Button from 'components/atoms/Button'
import { Chart } from 'components/atoms/Chart'
import PageTitle from 'components/atoms/PageTitle'
import Summary from 'components/atoms/Summary'
import { Table, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { FigkCommon } from 'components/common/Figk'
import TableNoData from 'components/molecules/Nodata/Table'
import ModalNotVotig from 'components/template/Modal/Figk/ModalNotVoting'
import ModalVotig from 'components/template/Modal/Figk/ModalVoting'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import useGetQuery from 'hook/useTQuery'
import useWidth from 'hook/useWidth'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { ProccessStatusType } from 'models/Common/status'
import { VoteListModel, VoteResponse } from 'models/TextFigk/vote'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { INFINITY_PER, SendingFilterResetData, VotingColWidth, VotingTh, 에디터특수기호변경 } from 'shared/common'
import { combinationVoteSummary, turnNumbering } from 'shared/function'
import { returnGroupInTextFigk } from 'shared/QueryOption/textFigk'
import { FIGK_PATH } from 'shared/Router/Figk'

const FigkVoting = () => {
    const { width, media } = useWidth()
    const { user } = useFigAuth()
    const { Notice, Modal, closeNotice } = useModal()
    const navigate = useNavigate()

    const [detectProccessStatus, setDetectProccessStatus] = useState<ProccessStatusType>('V')

    // detectProccessStatus 상태 변화에 따른 리스트
    const [list, setList] = useState<VoteResponse | undefined>(undefined)

    const [filter, setFilter] = useState<SearchParamModel>({
        per: INFINITY_PER,
        page: 1,
    })

    // 투표중인 리스트 V
    const { refetch: getVote } = useGetQuery(['@getVVoteList'], () => API.vote.getVotingList(filter), {
        select: (res: GenericResponse) => {
            const data: VoteResponse = res.data

            return data
        },
        onSuccess: (data: VoteResponse) => {
            setList(data)
        },
        staleTime: Infinity,
        enabled: false,
    })

    // 심사 중인 리스트 C
    const { refetch: judgeVote } = useGetQuery(['@getCVoteList'], () => API.vote.getVoteResultList(filter), {
        select: (res: GenericResponse) => {
            const data: VoteResponse = res.data

            return data
        },
        onSuccess: (data: VoteResponse) => {
            setList(data)
        },
        staleTime: Infinity,
        enabled: false,
    })

    const { data: group } = useGetQuery(['@getGroup'], () => API.textFigk.getGrouopInTextFigk(), returnGroupInTextFigk)

    // 발행완료 리스트 A
    // const { refetch: getPublished } = useGetQuery(
    //     ['@getAVoteList'],
    //     () => API.vote.getPublishedList({ ...filter, week: user?.textWeek ? user.textWeek - 1 : undefined }),
    //     {
    //         select: (res: GenericResponse) => {
    //             const data: VoteResponse = res.data

    //             return data
    //         },
    //         onSuccess: (data: VoteResponse) => {
    //             if (detectProccessStatus === 'A' || detectProccessStatus === 'T') setList(data)
    //         },
    //         staleTime: Infinity,
    //         enabled: false,
    //     }
    // )

    // const { mutate: tryPause, error: pauseError } = useTMutation(
    //     ['@PauseSending', 'U'],
    //     (s: 'N') => API.sending.updateSendPause(s),
    //     (data: GenericResponse) => pauseSuccessCallback(data)
    // )

    const pauseSuccessCallback = (res: GenericResponse) => {
        // console.log(res)
    }
    const onChangeSelectFilter = (e: string | null, type: string) => {
        setFilter((prev) => ({
            ...prev,
            [type]: e,
        }))
    }

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setFilter((prev) => ({
            ...prev,
            word: val,
        }))
    }

    const onChangeDate = (s: Date | null | undefined, e: Date | null | undefined) => {
        setFilter((prev) => ({
            ...prev,
            startDate: s ? s.toLocaleDateString().replaceAll('. ', '-').replace('.', '') : null,
            endDate: e ? e.toLocaleDateString().replaceAll('. ', '-').replace('.', '') : null,
        }))
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,
            page: e,
        }))
    }

    const voteParticipants = () => {
        return Modal({
            content: <ModalVotig />,
        })
    }

    const notVoteParticipants = () => {
        return Modal({
            content: <ModalNotVotig />,
        })
    }

    const resetFilter = () => {
        setFilter(SendingFilterResetData)
    }

    const callAPI = () => {
        if (user?.processStatus === 'V') {
            getVote()
        } else if (user?.processStatus === 'C') {
            judgeVote()
        }
    }

    const moveVoting = () => {
        console.log(width)
    }

    useEffect(() => {
        // 투표관리 메인 리스트 변경감지 분기처리
        console.log(user)
        if (!user || !user.processStatus) return

        if (user.processStatus !== 'V' && user.processStatus !== 'C') {
            Notice({
                type: 'alert',
                content: '현재는 투표 기간이 아닙니다.',
                cancel_button_text: '확인',
                confirmHandler: () => {
                    closeNotice()
                    navigate(-1)
                },
            })
        } else {
            setDetectProccessStatus(user.processStatus)

            callAPI()
        }
    }, [user?.processStatus])

    return (
        <>
            {list && (
                <>
                    <PageTitle title='투표 관리'>
                        <FigkCommon.CurrentRound>
                            <span className='current'>
                                회차 정보:<strong>Vol.{turnNumbering(user?.textWeek)}</strong>
                            </span>
                            <span className='group'>{user?.groupName}조</span>
                        </FigkCommon.CurrentRound>

                        {user?.processStatus === 'V' && (
                            <Button
                                $size='36'
                                $style='solid_secondary'
                                onClick={() => {
                                    media.tablet ? navigate('/figk/mobile') : navigate(FIGK_PATH.ADMIN_VOTING)
                                }}>
                                투표하러 가기
                            </Button>
                        )}
                        {user?.processStatus === 'C' && list.summary.isPublished === 'N' && (
                            <Button
                                $size='36'
                                $style='solid_secondary'
                                onClick={() => navigate(FIGK_PATH.AUDIT_VOTING)}>
                                심사하러 가기
                            </Button>
                        )}
                    </PageTitle>

                    <Summary summary={combinationVoteSummary(list?.summary, voteParticipants, notVoteParticipants)} />

                    <Wrapper
                        $form='main'
                        $whiteBoard
                        radius={12}>
                        {/* <Filter
                    search={
                        <InputText
                           activate_search
                            activate_delete
                            value={filter?.word ?? ''}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }
                    resetHandler={resetFilter}>
                    <LabelBox label='회차'>
                        <Select
                            options={createWeek(user?.textWeek)}
                            // defaultValue={filter.week}
                            // defaultValue={0}
                            handler={(e) => onChangeSelectFilter(e, 'week')}
                        />
                    </LabelBox>
                    <LabelBox label='송고 상태'>
                        <Select
                            // defaultValue={filter.status}
                            // defaultValue={0}
                            options={TextFigkAppliedSelectData}
                            handler={(e) => onChangeSelectFilter(e, 'status')}
                        />
                    </LabelBox>
                    <LabelBox label='조별'>
                        <Select
                            // defaultValue={!filter.groupId ? null : ''}
                            // defaultValue={0}
                            options={group ?? [{ status: null, title: 'ALL' }]}
                            handler={(e) => onChangeSelectFilter(e, 'groupId')}
                        />
                    </LabelBox>
                    <LabelBox label='기간'>
                        <RangeDatePicker
                            getDate={onChangeDate}
                            defaultValue={filter.startDate}
                        />
                    </LabelBox>
                </Filter> */}
                        <Chart.SimpleBar
                            graph={list.graph}
                            chartTitle='참여작 리스트'
                        />
                        {}
                        <Table form='default'>
                            <colgroup>
                                {VotingColWidth.map((_, i) => {
                                    return (
                                        <col
                                            width={_}
                                            key={i}
                                        />
                                    )
                                })}
                            </colgroup>
                            <THead>
                                <Tr>
                                    {VotingTh.map((_, i) => {
                                        return <Th key={`votingTh_${i}`}>{_}</Th>
                                    })}
                                </Tr>
                            </THead>
                            <TBody>
                                {list?.list && list?.list.length > 0 ? (
                                    list?.list.map((_: VoteListModel, i: number) => {
                                        return (
                                            <Tr
                                                key={`${_.id}_${i}`}
                                                // onClick={() => navigate(`${FIGK_PATH.VOTING}/${_.id}`)}
                                                onClick={() => navigate(`/dashboard/figk/voting/current_voiting/detail/${_.id}`)}>
                                                <Td>{_.totalVote}</Td>
                                                <Td align='left'>{에디터특수기호변경(_.title)}</Td>
                                                <Td
                                                    align='left'
                                                    color='none'>
                                                    {_.tag.map((t) => '#' + t.name).join(' ')}
                                                </Td>
                                                <Td>{_.authorEmail}</Td>
                                                <Td>{_.authorName}</Td>
                                                <Td color='date'>{_.appliedAt}</Td>
                                            </Tr>
                                        )
                                    })
                                ) : (
                                    <TableNoData />
                                )}
                            </TBody>
                        </Table>
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default FigkVoting
