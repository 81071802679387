import API from 'api'
import logo from 'assets/img/figk_small_logo.svg'
import Button from 'components/atoms/Button'
import PageTitle from 'components/atoms/PageTitle'
import Select from 'components/atoms/Select'
import { useFigAuth } from 'figAuthProvider'
import saveAs from 'file-saver'
import useGetQuery from 'hook/useTQuery'
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import { SearchParamModel } from 'models/Common'
import { TextFigkModel } from 'models/TextFigk/uploadImg'
import { LegacyRef, forwardRef, useEffect, useRef, useState } from 'react'
import { returnLastestWeek, returnUploadImg } from 'shared/QueryOption/textFigk'
import { INFINITY_PER, 에디터특수기호변경 } from 'shared/common'
import { createWeek } from 'shared/function'
import { styled } from 'styled-components'
import { PS } from './index.styled'

const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

interface ItargetDom {
    data: TextFigkModel[]
}

const TargetDom = forwardRef(({ data }: ItargetDom, ref: LegacyRef<HTMLLIElement> | undefined) => {
    return (
        <PS.HtmlList>
            {data &&
                data.map((item: TextFigkModel, index: number) => {
                    return (
                        <li
                            ref={ref}
                            key={item.id}
                            className={`convert_target_dom_${index + 1}`}>
                            <div
                                className='text_head'
                                data-week={`Vol.${item.week}`}>
                                <img
                                    src={logo}
                                    alt=''
                                    crossOrigin='anonymous'
                                />
                            </div>
                            <h3>{에디터특수기호변경(item.title)}</h3>
                            <blockquote>{에디터특수기호변경(item.subTitle)}</blockquote>
                            <p>{에디터특수기호변경(item.content)}</p>
                            <span>{item.authorName}</span>
                        </li>
                    )
                })}
        </PS.HtmlList>
    )
})

const InstagramUpload = () => {
    const targetDomRef = useRef<HTMLLIElement>(null)
    const { user } = useFigAuth()
    const convertArr = useRef<any[]>([])
    const [endpoint, setEndPoint] = useState<boolean>(false)
    const [filter, setFilter] = useState<SearchParamModel>({
        per: INFINITY_PER,
        page: 1,
        isPublished: 'Y',
    })

    //가장 높은 회차 정보
    const { data: latestWeek } = useGetQuery(
        ['@getLastestWeek'],
        () => API.uploadImg.getLastestWeek(),
        returnLastestWeek
    )

    //당선 픽 정보들
    const { data: publishedTextFigk, refetch: getPublishedTextFigk } = useGetQuery(
        ['@getPublishedTextFigk'],
        () => API.uploadImg.getPublishedTextFigk(filter.week ? Number(filter.week) : undefined),
        returnUploadImg
    )

    const onChangeSelectFilter = (e: string | null, type: string) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            [type]: e,
        }))
    }

    const onClickDownload = async () => {
        if (endpoint) return
        if (!targetDomRef.current) return
        if (!publishedTextFigk || !publishedTextFigk.length) return

        setEndPoint(true)

        const zip = new JSZip()
        //텍스트 파일 추가할 수 있음
        // zip.file("Hello.txt", "Hello World\n");
        const imagesFolder = zip.folder(`figk_vol${filter.week}`)

        let arr: any[] = []

        for (let index = 0; index < publishedTextFigk.length; index++) {
            const target = document.querySelector(`.convert_target_dom_${index + 1}`) as unknown as HTMLElement

            arr.push(
                new Promise((resolve, reject) => {
                    html2canvas(target, {
                        scale: 2,
                        allowTaint: true,
                        useCORS: true,
                    }).then((canvas) => {
                        canvas.toBlob((blob) => {
                            if (blob && imagesFolder) {
                                imagesFolder.file(`vol${filter.week}_${index}.png`, blob)
                                resolve(true)
                            }
                        })
                    })
                })
            )
        }

        Promise.all(arr)
            .then(() => {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    // see FileSaver.js
                    saveAs(content, `figk_vol${filter.week}`)
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setEndPoint(false)
            })
    }
    useEffect(() => {
        getPublishedTextFigk()
    }, [filter.week])

    return (
        <>
            <PageTitle title='업로드 이미지 관리'>
                {publishedTextFigk && latestWeek && (
                    <Box>
                        <Select
                            options={createWeek(latestWeek?.week.maxTextWeek, true, '회차 선택')}
                            // options={weekOptions()}
                            placeholder='회차 선택'
                            // defaultStatus={latestWeek?.week.maxTextWeek}
                            handler={(e) => onChangeSelectFilter(e, 'week')}
                        />
                        <Button
                            $size='36'
                            disabled={endpoint}
                            $style='solid_secondary'
                            onClick={onClickDownload}>
                            이미지 내려받기
                        </Button>
                    </Box>
                )}
            </PageTitle>
            {publishedTextFigk && filter.week && (
                <TargetDom
                    ref={targetDomRef}
                    data={publishedTextFigk}
                />
            )}
        </>
    )
}
export default InstagramUpload
