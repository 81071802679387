import { useOutsideClick } from 'hook/useOutsideClick'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { globalFilterReset } from 'recoil/atom/globalFilter'
import { InputContainerType } from 'theme/mixin'
import Icon from '../Icon'
import { StyleSelect } from './index.styled'
type selectOptionType = { status: string | null; title: string | null }
interface SelectProps {
    form?: InputContainerType
    options: selectOptionType[]
    handler?: (e: string | null) => void
    /** 초기 설정 값 , options의 인덱스로 설정*/
    defaultV?: string
    placeholder?: string
    disabled?: boolean
    readOnly?: boolean
    defaultStatus?: string | undefined | null | number
}

export interface SelectState {
    isOpen: boolean
    value: selectOptionType
}
const Select = ({ readOnly, disabled, placeholder, defaultV, handler, form = 'dashboard', options, defaultStatus }: SelectProps) => {
    const NULL_VALUE = { status: null, title: null }
    const globalReset = useRecoilValue(globalFilterReset)
    const [select, setSelect] = useState<SelectState>({
        isOpen: false,
        value: defaultStatus ? options.filter((f) => f.status == defaultStatus)[0] : NULL_VALUE,
    })
    const { isOpen, value } = select

    const getValue = (e: selectOptionType) => {
        const { status, title } = e
        setSelect({
            isOpen: false,
            value: { status: status, title: title },
        })

        handler && handler(status)
    }

    const selectRef = useOutsideClick(() => setSelect((prev) => ({ ...prev, isOpen: false })))

    useEffect(() => {
        if (globalReset)
            setSelect((prev) => ({
                ...prev,
                value: NULL_VALUE,
            }))
    }, [globalReset])

    useEffect(() => {
        setSelect({
            ...select,
            value: defaultStatus ? options.filter((f) => f.status == defaultStatus)[0] : NULL_VALUE,
        })
    }, [defaultStatus])

    return (
        <StyleSelect.Container
            ref={selectRef}
            className='select_component'
            $form={form}>
            <StyleSelect.ValueArea
                className={`${value.status === null ? 'placeholder' : ''} ${disabled ? 'disabled' : ''} ${readOnly ? 'readOnly' : ''} `}
                onClick={() => setSelect((prev) => ({ ...prev, isOpen: !isOpen }))}>
                {value.title ? value.title : placeholder ? placeholder : '전체'}
                {/* ㅅㅂ 리뉴얼 필요 */}
                {/* {value.title === null && !defaultV && !placeholder
                    ? '선택'
                    : value.title === null && defaultV
                    ? defaultV
                    : value.title === null && placeholder
                    ? placeholder
                    : value.title} */}
                <Icon
                    icon={'arrow_18x18'}
                    color={'gray6'}
                    transform={`rotate(${isOpen ? '180' : '0'})`}
                />
            </StyleSelect.ValueArea>
            {isOpen && options && (
                <StyleSelect.OptionArea>
                    {options.map((option, index) => {
                        return (
                            <li
                                key={`${option.title}_${index}`}
                                className={value?.title === option.title ? 'current' : ''}
                                onClick={(e) => {
                                    getValue(option)
                                }}>
                                {option.title}
                            </li>
                        )
                    })}
                </StyleSelect.OptionArea>
            )}
        </StyleSelect.Container>
    )
}

export default Select
