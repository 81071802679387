import { baseApi } from "api/Common";
import createUrlParam from "hook/useCreateUrlParam";
import { GenericResponse, SearchParamModel } from "models/Common";
import { apiHeader } from "shared/function";

const settlement = {
  getSettlement: async (param?: SearchParamModel) => {
    const req = createUrlParam(param);
    const response = await apiHeader(true, "a").get<GenericResponse>(
      `author-pay${req}`
    );
    return response.data;
  },

  getSettlementDetail: async (id: string) => {
    const response = await apiHeader(true, "a").get<GenericResponse>(
      `author-pay/detail?id=${id}`
    );
    return response.data;
  },
};
export default settlement;
