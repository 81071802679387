import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import InputText from 'components/atoms/Input/InputText'
import Textarea from 'components/atoms/Input/Textarea'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import Append from 'components/oraganisms/Append'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { TextFigkDetailResponse, TextFigkEditRequest } from 'models/TextFigk'
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { 에디터특수기호변경 } from 'shared/common'
import { ScrollPositionTop, turnNumbering } from 'shared/function'

const FigkVotingDetail = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { id: detailId } = useParams()
    const { p: page } = useParams()

    const { Notice, closeNotice } = useModal()
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)
    const EDIT = !edit

    const [textEditData, setTextEditData] = useState<TextFigkEditRequest>({
        id: 0,
        title: '',
        subTitle: '',
        content: '',
        link: '',
    })
    const [voteDetail, setVoteDetail] = useState<TextFigkDetailResponse>()

    // react-query runtime 오류로 인한 axios 핸들링으로 대체
    // const getVotingDetail = () => {
    //     API.vote.getVoteDetail(detailId ?? '').then((res: GenericResponse) => {
    //         const data: TextFigkDetailResponse = res.data
    //         if (res.code === 200) {
    //             if (data) {
    //                 setVoteDetail(data)
    //                 setTextEditData({
    //                     id: data.id,
    //                     title: data.title,
    //                     subTitle: data.subTitle,
    //                     content: data.content,
    //                     tags: data.tag ? data.tag.map((i) => i.name).toString() : '',
    //                     link: data.link,
    //                 })
    //             }
    //         }
    //     })
    // }

    const record: Record<string, unknown> = { id: detailId } as { [key: string]: string | undefined; id: string }

    const { data: detailData, refetch: getDetail } = useGetQuery(
        ['@getVoteDetail', record],
        () => API.vote.getVoteResultList({ id: detailId ? parseInt(detailId) : 0 } as SearchParamModel),
        {
            select: (res: GenericResponse) => {
                const data: TextFigkDetailResponse = res.data

                return data
            },
            onSuccess: (data: TextFigkDetailResponse) => {
                setVoteDetail(data)
                setTextEditData({
                    id: data.id,
                    title: data.title,
                    subTitle: data.subTitle,
                    content: data.content,
                    tags: data.tag && data.tag.length > 0 ? data.tag.map((i) => i.name).join(',') : '',
                    link: data.link,
                })
            },
            enabled: false,
        }
    )

    const { mutate: trySave, error: saveError } = useTMutation(
        ['@UpdateVoting', detailId ?? 'U'],
        (data: TextFigkEditRequest) => API.textFigk.updateTextFigk(data),
        (data: GenericResponse) => editSuccessCallback(data)
    )

    const { mutate: tryDel, error: delError } = useTMutation(
        ['@DeleteVoting', detailId ?? 'D'],
        (i: number[]) => API.textFigk.deleteTextFigk(i),
        (data: GenericResponse) => delSuccessCallback(data)
    )

    const delSuccessCallback = (res: GenericResponse) => {
        if (res.code === 200) {
            closeNotice()
            navigate(-1)
        } else {
            alert('error')
        }
    }
    const editSuccessCallback = (res: GenericResponse) => {
        queryClient.invalidateQueries(['@getVoteDetail', record])
        setEdit(false)
        Notice({
            type: 'alert',
            content: res.code === 200 ? '저장이 완료되었어요.' : res.message,
            cancel_button_text: '확인',
        })
    }

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)

    const onChangeInput = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, type: string) => {
        setTextEditData((prev) => ({
            ...prev,
            [type]: e.target.value,
        }))
    }
    // 태그 가져옴
    const getTags = (e: string) => {
        setTextEditData((prev) => ({
            ...prev,
            tags: e,
        }))
    }
    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(!edit)
        ScrollPositionTop()
    }

    const DeleteTextHandler = () => {
        Notice({
            type: 'confirm',
            content: '이 글을 삭제하시겠습니까?',
            confirm_button_text: '삭제하기',
            confirmHandler: () => {
                tryDel([voteDetail?.id ?? -1])
            },
        })
    }

    const save = () => {
        Notice({
            type: 'confirm',
            content: '변경한 내용으로 저장하시겠습니까?',
            confirm_button_text: '저장하기',
            confirmHandler: () => {
                trySave(textEditData)
            },
        })
    }

    useEffect(() => {
        if (detailId) {
            //getVotingDetail()
            getDetail()
        }
    }, [])

    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? '글 수정' : '글 상세'}>
                {EDIT && (
                    <PageInfo>
                        <span>작성일: {voteDetail?.registeredAt}</span>
                        {voteDetail?.updatedAt && <span>수정일: {voteDetail.updatedAt}</span>}
                        {voteDetail?.publishedAt && <span>송고일: {voteDetail.publishedAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>
            <Detail.Container>
                <Detail.Title title='작가 정보' />
                <Detail.Row>
                    <Detail.Col title='필명'>{voteDetail?.authorName}</Detail.Col>
                    <Detail.Col title='조별'>{voteDetail?.groupName}조</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='ID'>{voteDetail?.authorEmail}</Detail.Col>
                </Detail.Row>
            </Detail.Container>
            <Detail.Container>
                <Detail.Title title='글 정보'>
                    <Detail.Summary summary={[{ text: '득표 수', data: `${voteDetail?.totalVote ?? 0}`, data_color: 'positive' }]} />
                </Detail.Title>
                <Detail.Row>
                    <Detail.Col
                        title='회차'
                        color='darkgray'>
                        Vol.{turnNumbering(voteDetail?.week)}
                    </Detail.Col>
                </Detail.Row>
                {/* {EDIT && (
                    <Detail.Row>
                        <Detail.Col
                            title='송고 상태'
                            weight='600'>
                            {isApplied(voteDetail?.contestStatus)}
                        </Detail.Col>
                    </Detail.Row>
                )} */}

                <Detail.Row>
                    <Detail.Col title='제목'>
                        <InputText
                            readOnly={EDIT}
                            name='title'
                            onChange={(e) => onChangeInput(e, 'title')}
                            value={에디터특수기호변경(textEditData.title)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재'>
                        <InputText
                            readOnly={EDIT}
                            name='subject'
                            onChange={(e) => onChangeInput(e, 'subTitle')}
                            defaultValue={에디터특수기호변경(textEditData.subTitle)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='내용'>
                        <Textarea
                            readOnly={EDIT}
                            name='contents'
                            onChange={(e) => onChangeInput(e, 'content')}
                            defaultValue={에디터특수기호변경(textEditData.content)}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col title='소재 URL'>
                        {EDIT ? (
                            <a
                                href={textEditData.link}
                                target='_blank'
                                rel='noreferrer'>
                                {textEditData.link}
                            </a>
                        ) : (
                            <InputText
                                name='url'
                                onChange={(e) => onChangeInput(e, 'link')}
                                defaultValue={textEditData.link || ''}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='태그'
                        color='gray'>
                        {EDIT ? (
                            <>
                                {voteDetail?.tag &&
                                    voteDetail?.tag.map((_, i) => {
                                        return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>
                                    })}
                            </>
                        ) : (
                            <Append
                                // tags 초기값 예외처리
                                tags={textEditData.tags === '' ? [] : textEditData.tags?.split(',') || []}
                                handler={getTags}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>
            {voteDetail?.contestStatus !== 'N' && voteDetail?.contestStatus !== 'E' && (
                <ButtonBox>
                    <Button
                        $style='line_secondary'
                        onClick={DeleteTextHandler}>
                        삭제하기
                    </Button>
                    <Button
                        $style={EDIT ? 'line_primary' : 'solid_primary'}
                        onClick={EDIT ? editToggleHandler : save}>
                        {EDIT ? '수정하기' : '저장하기'}
                    </Button>
                </ButtonBox>
            )}
        </Wrapper>
    )
}

export default FigkVotingDetail
