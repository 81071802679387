import { createGlobalStyle } from 'styled-components'

export const Layout = createGlobalStyle`
 #root {
    display: flex;
    flex-direction: column;
   /* height: 100%; */
   padding-top: ${(p) => p.theme.layout.header_height};
   

    header#globalHeader {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      
      z-index:1100;
    }
    nav#globalNav {
      position: fixed;
      top: ${(p) => p.theme.layout.header_height};
      left: 0;
      width: ${(p) => p.theme.layout.sidebar_width};
      height: ${(p) => `calc(100vh - ${p.theme.layout.header_height})`};
      transition: transform 0.3s;
      z-index:1000;
    }
    main#globalMain {
      display: flex;
      height: 100%;
      /* background-color: ${(p) => p.theme.color.background}; */
      flex: 1;
      #outletWrapper {
        width: ${(p) => ` calc(100% - ${p.theme.layout.sidebar_width})`};
       min-height:  ${(p) => `calc(100dvh - ${p.theme.layout.header_height})`};
       margin-inline-start: auto;
       transition: width 0.3s;
       #outlet{
            padding: 5rem 10rem 4rem;
            min-width:1400px;
            @media screen  and (max-width:1600px){
                padding-inline:5rem;
            }

        }
      }
    }
/* 사이드바 오픈 일때 */
    &:has(.openNav) {
      nav#globalNav {
        transform: translate3d(-100%, 0, 0);
      }
      main#globalMain {
        #outletWrapper {
          width: 100%;
        
        }
      }
    }
  }
`
