import Icon from "components/atoms/Icon";
import { S } from "components/molecules/FilterSearch/index.styled";
import { PropsWithChildren } from "react";
interface FilterSearchProps extends PropsWithChildren {
  onClick: VoidFunction;
}
const FilterSearch = ({ onClick, children }: FilterSearchProps) => {
  return (
    <S.Container>
      {children}
      <S.FilterButton onClick={onClick}>
        <Icon icon={"filter_18x18"} color={"gray8"} /> 필터
      </S.FilterButton>
    </S.Container>
  );
};

export default FilterSearch;
