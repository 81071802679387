import API from "api";
import InputText from "components/atoms/Input/InputText";
import PageTitle from "components/atoms/PageTitle";
import Pagination from "components/atoms/Pagination";
import Select from "components/atoms/Select";
import {
  TBody,
  THead,
  Table,
  TableControlBox,
  Td,
  Th,
  Tr,
} from "components/atoms/Table";
import { Wrapper } from "components/atoms/Wrapper/index.styled";
import LabelBox from "components/molecules/LabelBox";
import TableNoData from "components/molecules/Nodata/Table";
import Filter from "components/oraganisms/Filter";
import RangeDatePicker from "components/oraganisms/RangeDatePicker";
import { useDebounce } from "hook/useDebounce";
import useGetQuery from "hook/useTQuery";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { globalFilter, globalFilterReset } from "recoil/atom/globalFilter";
import { returnMember } from "shared/QueryOption/member";
import { returnGroupInTextFigk } from "shared/QueryOption/textFigk";
import { FIGK_PATH } from "shared/Router/Figk";
import {
  MemberColWidth,
  MemberStatusSelectData,
  MemberTh,
} from "shared/common";
import { isActive, isAppliedColor } from "shared/function";
import { maskingFunc } from "shared/masking";
const FigkWriter = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useRecoilState(globalFilter);
  const resetGlobalFilter = useResetRecoilState(globalFilter);
  const globalReset = useRecoilValue(globalFilterReset);

  const [word, setWord] = useState<string>(filter.word || "");

  const { data: member, refetch: getMember } = useGetQuery(
    ["@getMember"],
    () => API.member.getMember(filter),
    returnMember
  );
  const { data: group } = useGetQuery(
    ["@getTextFigkGroup"],
    () => API.textFigk.getGrouopInTextFigk(),
    returnGroupInTextFigk
  );
  const onChangeSelectFilter = (e: string | null, type: string) => {
    setFilter((prev) => ({
      ...prev,
      page: 1,
      [type]: e,
    }));
  };

  const debounceWordInFilter = useCallback(
    useDebounce(
      (e: string) =>
        setFilter((prev) => ({
          ...prev,
          word: e,
          page: 1,
        })),
      300
    ),
    []
  );

  const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value ?? "";
    setWord(val);

    debounceWordInFilter(val);
  };

  const setPage = (e: number) => {
    setFilter((prev) => ({
      ...prev,
      page: e,
    }));
  };

  useEffect(() => {
    getMember();
  }, [filter]);

  useEffect(() => {
    if (globalReset) {
      setWord("");
      resetGlobalFilter();
    }
  }, [globalReset]);

  return (
    <>
      <PageTitle title="작가 관리" />
      <Wrapper $form="main" $whiteBoard radius={12}>
        <Filter
          search={
            <InputText
              activate_search
              activate_delete
              value={word}
              onChange={onChangeWordInFilter}
              placeholder="검색어를 입력해주세요."
            />
          }
        >
          <LabelBox label="활동 상태">
            <Select
              options={MemberStatusSelectData}
              defaultStatus={filter.isActive}
              handler={(e) => onChangeSelectFilter(e, "isActive")}
            />
          </LabelBox>
          <LabelBox label="조별">
            <Select
              options={group ?? [{ status: null, title: "ALL" }]}
              defaultStatus={filter.groupId}
              handler={(e) => onChangeSelectFilter(e, "groupId")}
            />
          </LabelBox>
          <LabelBox label="기간">
            <RangeDatePicker filterStatus={globalFilter} />
          </LabelBox>
        </Filter>
        <Table form="default">
          <colgroup>
            {MemberColWidth.map((_, i) => {
              return <col width={_} key={i} />;
            })}
          </colgroup>
          <THead>
            <Tr>
              {MemberTh.map((_, i) => {
                return <Th key={`textFigkTh_${i}`}>{_}</Th>;
              })}
            </Tr>
          </THead>
          <TBody>
            {member?.list && member?.list.length > 0 ? (
              member?.list.map((_, i) => {
                return (
                  <Tr
                    key={`${_.id}_${i}`}
                    onClick={() => navigate(`${FIGK_PATH.FIGK_MEMBER}/${_.id}`)}
                  >
                    <Td color={isAppliedColor(_.status)}>
                      {isActive(_.status)}
                    </Td>
                    <Td>{_.groupName}</Td>
                    <Td align="left">{_.email}</Td>
                    <Td>{_.name !== "" ? maskingFunc.name(_.name) : "-"}</Td>
                    <Td>{_.nickname !== "" ? _.nickname : "-"}</Td>
                    <Td>{maskingFunc.phone(_.phone)}</Td>
                    <Td color="date">{_.registeredAt}</Td>
                  </Tr>
                );
              })
            ) : (
              <TableNoData />
            )}
          </TBody>
        </Table>
        <TableControlBox>
          <Pagination
            currentPage={filter.page}
            limit={filter.per}
            setCurrentPage={setPage}
            isLast={member?.isLast || false}
            totalItem={member?.totalCount ?? 0}
          />
        </TableControlBox>
      </Wrapper>
    </>
  );
};
export default FigkWriter;
