import API from 'api'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import Append from 'components/oraganisms/Append'
import { Modal } from 'components/template/GlobalModal/index.styled'
import { useModal } from 'hook/useModal'
import useTMutation from 'hook/useTMutation'
import { GenericResponse } from 'models/Common'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

type TagHandle = {
    tags: string
    // M : MAX , D : Duplicate
    error?: 'M' | 'D' | undefined
}
const ModalTagRegist = () => {
    const queryClient = useQueryClient()
    const { Notice, closeModal, closeNotice } = useModal()

    const [registData, setRegistData] = useState<TagHandle>({
        tags: '',
    })

    const { mutate: tryCTag, error: createTagError } = useTMutation(
        ['@DeleteTag', 'D'],
        (t: string) => API.tag.createTag(t),
        (rs: GenericResponse) => createTagsuccessCallback(rs)
    )

    const createTagsuccessCallback = (res: GenericResponse) => {
        if (res.code === 200) {
            Notice({
                type: 'alert',
                icon: 'notice_checked',
                content: '태그 등록이 완료되었습니다.',
                confirmHandler: () => {
                    closeModal()
                    closeNotice()
                    queryClient.invalidateQueries('@getTagList')
                },
            })
        }
    }

    const getTags = (e: string) => {
        const length = registData.tags.split(',').length
        // insert error status
        setRegistData((prev) => ({
            ...prev,
            tags: e,
            error: length === 4 && prev.tags !== e ? 'M' : prev.tags === e ? 'D' : undefined,
        }))
    }

    // 갯수제한 5개
    return (
        <Modal.Container $hegiht='auto'>
            <Modal.Header>
                <span className='title'>태그 등록하기</span>
                <Icon
                    icon={'modal_close'}
                    color={'gray6'}
                    role='button'
                    onClick={closeModal}
                />
            </Modal.Header>
            <Append
                isRegist={true}
                handler={getTags}
                limit={5}
            />
            <Modal.ButtonBox>
                <Button
                    type='button'
                    $size='36'
                    $style='line_secondary'
                    onClick={closeModal}>
                    취소
                </Button>
                <Button
                    type='button'
                    $size='36'
                    disabled={registData.tags == ''}
                    $style='solid_primary'
                    onClick={() => tryCTag(registData.tags)}>
                    등록하기
                </Button>
            </Modal.ButtonBox>
        </Modal.Container>
    )
}

export default ModalTagRegist
