import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { TermRegistModel, TermUpdateModel } from 'models/Term'
import { apiHeader } from 'shared/function'
const terms = {
    getTerms: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`term${req}`)
        return response.data
    },

    getTermDetail: async (id?: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`term?id=${id}`)
        return response.data
    },

    createTerm: async (data: TermRegistModel) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`term`, data)
        return response.data
    },

    updateTerm: async (data: TermUpdateModel) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`term`, data)
        return response.data
    },

    deleteTerm: async (i: number[]) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`term`, { data: { ids: i } })
        return response.data
    },
}

export default terms
