import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import ImgFileUpload, { AppendImgPreview } from 'components/atoms/Input/ImgFileUpload'
import InputFile from 'components/atoms/Input/InputFile'
import InputText from 'components/atoms/Input/InputText'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import Append from 'components/oraganisms/Append'
import { NOTICE_CONTEXT } from 'components/template/GlobalNotice/context'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { ArtFigkDetailModel, ArtFigkEditModel } from 'models/ArtFigk'
import { GenericResponse } from 'models/Common'
import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { loadingState } from 'recoil/atom/modal'
import { IMG_SIZE_MAX_LIMIT, IMG_URL, VIDEO_SIZE_MAX_LIMIT } from 'shared/common'
import { isPublished, isPublishedColor, ScrollPositionTop, turnNumbering } from 'shared/function'
import { FIGK_PATH } from 'shared/Router/Figk'
//발행완료 일때 조건
const PUBLISHED = false
const ArtFigkDetail = () => {
    const { user } = useFigAuth()
    const { id: detailId } = useParams()

    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()

    const setLoading = useSetRecoilState(loadingState)

    const fileRef = useRef<HTMLInputElement>(document.createElement('input'))
    const imgFileRef = useRef<HTMLInputElement>(document.createElement('input'))

    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(detailId ? false : true)

    const [editData, setEditData] = useState<ArtFigkEditModel>({
        id: parseInt(detailId + ''),
        title: '',
        jacketFileId: null,
        videoFileId: null,
    })

    const editArtFigkSuccesscallback = (res: GenericResponse) => {
        closeNotice()
        navigate(FIGK_PATH.ART_FIGK)
    }

    const publishArtFigkSuccessCallback = (res: GenericResponse) => {
        completedHandler()
    }

    const deleteArtFigkSuccessCallback = (res: GenericResponse) => {
        closeNotice()
        navigate(FIGK_PATH.ART_FIGK)
    }

    const {
        mutate: tryEdit,
        error: editError,
        isLoading,
    } = useTMutation(
        ['@EditArtFigk', detailId ?? 'U'],
        (data: ArtFigkEditModel) => API.artFigk.updateArtFigk(data),
        (data: GenericResponse) => editArtFigkSuccesscallback(data)
    )

    const { mutate: tryPublish, error: publishError } = useTMutation(
        ['@PublishArtFigk', detailId ?? 'U'],
        (id: string) => API.artFigk.publishArtFigk(id),
        (data: GenericResponse) => publishArtFigkSuccessCallback(data)
    )

    const { mutate: tryDel, error: deleteError } = useTMutation(
        ['@DeleteArtFigk', detailId ?? 'U'],
        (id: number[]) => API.artFigk.deleteArtFigk(id),
        (data: GenericResponse) => deleteArtFigkSuccessCallback(data)
    )
    const { data: artFigk, refetch: getArtFigk } = useGetQuery(
        [`@getArtFigkDetail_${detailId + ''}`],
        () => API.artFigk.getArtFigkDetail(detailId ?? ''),
        {
            select: (res: GenericResponse) => {
                const data: ArtFigkDetailModel = res.data

                return data
            },
            onSuccess: (data) => {
                setEditData((prev) => ({
                    ...prev,
                    id: data.id,
                    title: data.title,
                    tags: data.tag.map((i) => i.name).join(',') ?? '',
                }))
            },
        }
    )

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        setEditData((prev) => ({
            ...prev,
            title: val,
        }))
    }

    // 태그 가져옴
    const getTags = (e: string) => {
        setEditData((prev) => ({
            ...prev,
            tags: e,
        }))
    }

    // file controller
    const getFile = (e: File | null, type: 'videoFileId' | 'jacketFileId') => {
        setEditData((prev) => ({
            ...prev,
            [type]: e,
        }))
    }

    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)
    useWarnningGoBack(FIGK_PATH.ART_FIGK, edit)

    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(!edit)
        ScrollPositionTop()
    }

    const deleteHandler = () => {
        Notice({
            type: 'confirm',
            icon: 'notice_bang_mark',
            icon_color: 'primary',
            content: NOTICE_CONTEXT.DELETE,
            confirm_button_text: '삭제하기',
            confirmHandler: () => {
                tryDel([editData.id ?? -1])
            },
        })
    }
    const completedHandler = () => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            icon_color: 'primary',
            content: '발행이 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.ART_FIGK)
            },
        })
    }
    const publishedHandler = () => {
        Notice({
            type: 'confirm',
            icon: 'notice_bang_mark',
            icon_color: 'primary',
            content: (
                <>
                    "{artFigk?.title}"(을)를 <br />
                    <strong style={{ color: '#F4554A' }}>Vol.{turnNumbering(user?.artWeek)}</strong>에 발행하시겠습니까?
                </>
            ),
            confirm_button_text: '발행하기',
            confirmHandler: () => tryPublish(editData.id + ''),
        })
    }

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        getArtFigk()
    }, [])
    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? 'ART FIGK 수정' : 'ART FIGK 상세'}>
                {!edit && (
                    <PageInfo>
                        <span>등록일: {artFigk?.registeredAt}</span>
                        {artFigk?.updatedAt && <span>수정일: {artFigk.updatedAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>

            <Detail.Container>
                {!edit && (
                    <>
                        <Detail.Title title='영상 정보'>
                            {artFigk?.isPublished === 'Y' && (
                                <Detail.Summary
                                    summary={[
                                        { text: '누적 뷰 수', data: artFigk.view + '', data_color: 'darkgray' },
                                        { text: '누적 공유 수', data: artFigk.shared + '', data_color: 'darkgray' },
                                    ]}
                                />
                            )}
                        </Detail.Title>
                        <Detail.Row>
                            <Detail.Col title='관리자 ID'>{artFigk?.registerId}</Detail.Col>
                        </Detail.Row>
                        <Detail.Row>
                            <Detail.Col
                                title='발행 상태'
                                color={isPublishedColor(artFigk?.isPublished)}
                                weight='500'>
                                {isPublished(artFigk?.isPublished)}
                            </Detail.Col>
                        </Detail.Row>
                    </>
                )}

                <Detail.Row>
                    <Detail.Col
                        title='제목'
                        required={edit}>
                        <InputText
                            readOnly={!edit}
                            name='title'
                            onChange={onChangeTitle}
                            value={editData.title}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title={edit ? '영상 업로드' : '영상'}
                        note={edit ? '(200MB이하)' : ''}
                        required={edit}>
                        {!edit ? (
                            // artFigk?.videoUrl && artFigk?.videoUrl.split('/')[1]
                            artFigk?.videoOriginName
                        ) : (
                            <InputFile
                                id='upload'
                                ref={fileRef}
                                fileLimitSize={VIDEO_SIZE_MAX_LIMIT}
                                defaultValue={editData.videoFileId ? undefined : artFigk?.videoUrl}
                                onChange={(e) => getFile(e, 'videoFileId')}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title={edit ? '썸네일 설정' : '썸네일'}
                        note={edit ? '(5MB 이하)' : ''}
                        required={edit}>
                        {!edit ? (
                            <>
                                <AppendImgPreview>
                                    <img
                                        src={IMG_URL + artFigk?.jacketUrl}
                                        alt={`artFigk_jacket_${artFigk?.id}`}
                                    />
                                    {/* <p>{artFigk?.jacketUrl.split('/')[1]}</p> */}
                                    {<p>{artFigk?.jacketOriginName}</p>}
                                </AppendImgPreview>
                            </>
                        ) : (
                            <ImgFileUpload
                                id='imgUpload'
                                fileLimitSize={IMG_SIZE_MAX_LIMIT}
                                ref={imgFileRef}
                                defaultValue={editData.jacketFileId ? undefined : artFigk?.jacketUrl}
                                onChange={(e) => getFile(e, 'jacketFileId')}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='태그'
                        color='gray'
                        required={edit}>
                        {!edit ? (
                            <>
                                {artFigk?.tag.map((_, i) => {
                                    return <Fragment key={`tag_${i}`}>#{_.name} </Fragment>
                                })}
                            </>
                        ) : (
                            <Append
                                // tags 초기값 예외처리
                                tags={editData.tags === '' ? [] : editData.tags?.split(',') || []}
                                handler={getTags}
                            />
                        )}
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>
            <ButtonBox>
                {edit ? (
                    <>
                        <Button
                            $style='line_secondary'
                            onClick={() => {
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: NOTICE_CONTEXT.WARNNING_FOR_PAGE_EXIT,
                                    confirmHandler: () => {
                                        setEdit(false)
                                        closeNotice()
                                    },
                                })
                            }}>
                            돌아가기
                        </Button>

                        <Button
                            $style='solid_primary'
                            $loading={isLoading}
                            onClick={() =>
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: NOTICE_CONTEXT.SAVE,
                                    confirm_button_text: '저장하기',
                                    confirmHandler: () => {
                                        tryEdit(editData)
                                    },
                                })
                            }>
                            저장하기
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            $style='line_secondary'
                            onClick={deleteHandler}>
                            삭제하기
                        </Button>

                        <Button
                            $style='line_primary'
                            onClick={editToggleHandler}>
                            수정하기
                        </Button>
                        {artFigk?.isPublished === 'N' && (
                            <Button
                                $style='solid_primary'
                                onClick={publishedHandler}>
                                발행하기
                            </Button>
                        )}
                    </>
                )}
            </ButtonBox>
        </Wrapper>
    )
}

export default ArtFigkDetail
