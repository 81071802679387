import { styled } from "styled-components";
const Container = styled.div`
  border: 1px solid ${(p) => p.theme.input.line};
  border-radius: 0.4rem;
  margin-block-end: 30px;
`;
const Contents = styled.div`
  padding: 24.5px 30px;
  border-top: 1px solid ${(p) => p.theme.input.line};
  background-color: ${(p) => p.theme.color.background};
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3rem;
    h3 {
      font-weight: 500;
      color: ${(p) => p.theme.grayScale.gray8};
    }
  }
  .filterItem {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;

    .select_component {
      width: 180px;
    }
    .datepicker_component {
      &.period {
        width: 240px;
      }
    }
  }
`;

const Reset = styled.button.attrs((props) => ({
  type: "button",
}))`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.3rem;
  color: ${(p) => p.theme.grayScale.gray5};
`;
export const S = {
  Container,
  Contents,
  Reset,
};
