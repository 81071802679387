import { GenericResponse } from 'models/Common'
import { NoticeList, NoticeResponse } from 'models/Notice'

export const returnNotice = {
    select: (res: GenericResponse) => {
        const data: NoticeResponse = res.data

        return data
    },
}

export const returnNoticeDetail = {
    select: (res: GenericResponse) => {
        const data: NoticeList = res.data

        return data
    },
}
