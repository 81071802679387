import styled, { css } from 'styled-components'
import { StatusColorType, mixin } from 'theme/mixin'

export const StyledTh = styled.th`
    padding-block: 1.1rem;
    background-color: ${(p) => p.theme.table.th_background};
    font-weight: 400;
    font-size: 1.3rem;
    color: ${(p) => p.theme.table.th_color};
    border-block: 1px solid ${(p) => p.theme.table.line};
`

export interface StyledTdProps extends StatusColorType {
    $align?: 'left' | 'right' | 'center'
}

export const StyledTd = styled.td<StyledTdProps>`
    --lineColor: #e9e9e9;
    padding-block: 1.4rem;
    font-weight: 400;
    font-size: 1.3rem;
    text-align: ${(p) => p.$align};
    padding-inline: 1.2rem;
    border-bottom: 1px solid ${(p) => p.theme.table.line};
    ${mixin.StatusColor}
`

export const StyledTr = styled.tr`
    position: relative;
    -webkit-transform: translateZ(0);

    &.nodata {
        height: 48.5px;
        ${StyledTd} {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }
    }
`

export type TableFormType = 'scroll' | 'default'
export interface IStyledTable {
    $form?: TableFormType
}
export const StyledTable = styled.div<IStyledTable>`
    overflow: auto hidden;
    .scrollBox {
        display: flex;
        flex-wrap: nowrap;
        ${(p) =>
            p.$form === 'scroll' &&
            css`
                width: max(1200px, 100%);
            `}
        ${(p) =>
            p.$form === 'default' &&
            css`
                width: 100%;
            `}
        
        table {
            width: 100%;
            table-layout: fixed;
        }
    }
    & + .tableControlBox {
        padding-block-start: 20px;
    }
`
export const StyledTHead = styled.thead``

export const StyledTBody = styled.tbody`
    ${StyledTr} {
        &.cursor_pointer {
            &:hover {
                background-color: ${(p) => p.theme.color.list_hover};
            }
        }
    }
`
