import { Input } from "components/atoms/Input/index.styled";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.layout.dashboard_input_height};
  ${Input.Container} {
    border: 0;
    border-radius: 0;
    border-top-left-radius: 0.4rem;
  }
`;
const FilterButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: inherit;
  /* width: 100px; */
  background-color: #fff;
  border-left: 1px solid ${(p) => p.theme.input.line};
  padding: 0 27.5px;
  font-size: 1.3rem;
  border-top-right-radius: 0.4rem;
  white-space: nowrap;
`;
export const S = {
  Container,
  FilterButton,
};
