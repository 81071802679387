import { Link } from "react-router-dom";
import AdminVoting from ".";
import styled from "styled-components";
import { FIGK_PATH } from "shared/Router/Figk";
import Icon from "components/atoms/Icon";
import { mediaQuery } from "theme/mediaQuery";

const PageContainer = styled.div`
  padding: 0 40px;
  @media screen and (max-width: 480px) {
    padding-inline: 0;
  }
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.grayScale.gray5};
  margin-bottom: 32px;
  gap: 3px;
  svg {
    transform: rotate(180deg);
  }
  ${mediaQuery("mobile")} {
    padding-inline: 16px;
  }
`;
const MobileAdminVoting = () => {
  return (
    <PageContainer>
      <BackButton to={FIGK_PATH.CURRENT_VOTING}>
        <Icon icon={"arrow_20x20"} color={"gray5"} /> 이전 페이지
      </BackButton>
      <AdminVoting />
    </PageContainer>
  );
};

export default MobileAdminVoting;
