import API from "api";
import { AxiosError } from "axios";
import { Chart } from "components/atoms/Chart";
import PageTitle from "components/atoms/PageTitle";
import Select from "components/atoms/Select";
import Summary from "components/atoms/Summary";
import { Table, TBody, Td, Th, THead, Tr } from "components/atoms/Table";
import { Wrapper } from "components/atoms/Wrapper/index.styled";
import { FigkCommon } from "components/common/Figk";
import TableNoData from "components/molecules/Nodata/Table";
import ModalNotVotig from "components/template/Modal/Figk/ModalNotVoting";
import ModalVotig from "components/template/Modal/Figk/ModalVoting";
import { useFigAuth } from "figAuthProvider";
import { useModal } from "hook/useModal";
import useGetQuery from "hook/useTQuery";
import useWidth from "hook/useWidth";
import { GenericResponse } from "models/Common";
import { PastVoteResponse, VoteListModel } from "models/TextFigk/vote";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VotingColWidth, VotingTh, 에디터특수기호변경 } from "shared/common";
import {
  combinationPastVoteSummary,
  createWeek,
  turnNumbering,
} from "shared/function";

const FigkPastVoting = () => {
  const { media } = useWidth();
  const { user } = useFigAuth();
  const { Notice, Modal, closeNotice } = useModal();
  const navigate = useNavigate();

  const [maxWeek, setMaxWeek] = useState<number>(user ? user.textWeek - 1 : 0);
  const [week, setWeek] = useState<number>(user ? user.textWeek - 1 : 0);
  const [list, setList] = useState<PastVoteResponse | undefined>(undefined);

  // 투표중인 리스트 V
  const { refetch: getVoteLog } = useGetQuery(
    ["@getPastVoteList"],
    () => API.vote.getVoteLog(week),
    {
      select: (res: GenericResponse) => {
        const data: PastVoteResponse = res.data;

        return data;
      },
      onSuccess: (data: PastVoteResponse) => {
        setList(data);
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data as GenericResponse;

        Notice({
          icon: "notice_bang_mark",
          type: "alert",
          content: errData.message,
        });
      },
      staleTime: Infinity,
      enabled: false,
    }
  );

  const voteParticipants = () => {
    if (!list) return;

    return Modal({
      content: (
        <ModalVotig
          data={{ totalCount: list?.summary.voter, list: list?.voter }}
        />
      ),
    });
  };

  const notVoteParticipants = () => {
    if (!list) return;

    return Modal({
      content: (
        <ModalNotVotig
          data={{ totalCount: list?.summary.unVoter, list: list?.unVoter }}
        />
      ),
    });
  };

  useEffect(() => {
    if (week && week > 0) getVoteLog();
  }, [week]);

  useEffect(() => {
    if (maxWeek === 0 && user) {
      setWeek(user.textWeek - 1);
      setMaxWeek(user.textWeek - 1);
    }
  }, [user]);

  return (
    <>
      {list && (
        <>
          <PageTitle title="지난 투표 관리">
            {/* <FigkCommon.CurrentRound>
                            <span className='current'>
                                회차 정보:<strong>Vol.{turnNumbering(week)}</strong>
                            </span>
                            {list.summary.group && <span className='group'>{list.summary.group}조</span>}
                        </FigkCommon.CurrentRound> */}
            <Select
              options={createWeek(maxWeek, true, "", false)}
              defaultStatus={week}
              handler={(e) => setWeek(Number(e))}
            />
          </PageTitle>

          <Summary
            summary={combinationPastVoteSummary(
              list?.summary,
              voteParticipants,
              notVoteParticipants
            )}
          />

          <Wrapper $form="main" $whiteBoard radius={12}>
            <Chart.SimpleBar graph={list.graph} chartTitle="참여작 리스트" />
            {}
            <Table form="default">
              <colgroup>
                {VotingColWidth.map((_, i) => {
                  return <col width={_} key={i} />;
                })}
              </colgroup>
              <THead>
                <Tr>
                  {VotingTh.map((_, i) => {
                    return <Th key={`votingTh_${i}`}>{_}</Th>;
                  })}
                </Tr>
              </THead>
              <TBody>
                {list?.list && list?.list.length > 0 ? (
                  list?.list.map((_: VoteListModel, i: number) => {
                    return (
                      <Tr
                        key={`${_.id}_${i}`}
                        onClick={() =>
                          navigate(
                            `/dashboard/figk/voting/past_voiting/detail/${_.id}`
                          )
                        }
                      >
                        <Td>{_.totalVote}</Td>
                        <Td align="left">{에디터특수기호변경(_.title)}</Td>
                        <Td align="left" color="none">
                          {_.tag.map((t) => "#" + t.name).join(" ")}
                        </Td>
                        <Td>{_.authorEmail}</Td>
                        <Td>{_.authorName}</Td>
                        <Td color="date">{_.appliedAt}</Td>
                      </Tr>
                    );
                  })
                ) : (
                  <TableNoData />
                )}
              </TBody>
            </Table>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default FigkPastVoting;
