import ko from 'date-fns/locale/ko'
import { useOutsideClick } from 'hook/useOutsideClick'
import { SearchParamModel } from 'models/Common'
import { useEffect, useMemo, useState } from 'react'
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { globalFilterReset } from 'recoil/atom/globalFilter'
import { dateToStringFormat } from 'shared/function'
import { InputContainerType } from 'theme/mixin'
import Icon from '../../atoms/Icon'
import DatePickerHeader from '../DatePickerHeader'
import { S } from './index.styled'
interface DatePickerProps {
    form?: InputContainerType
    filterStatus: RecoilState<SearchParamModel>
}
interface PeriodState {
    isOpen: boolean
    startDate: Date | null | undefined
    endDate: Date | null | undefined
    placeholder: boolean
}

const RangeDatePicker = ({ form = 'dashboard', filterStatus }: DatePickerProps) => {
    const [filter, setFilter] = useRecoilState(filterStatus)
    const globalReset = useRecoilValue(globalFilterReset)

    const [period, setPeriod] = useState<PeriodState>({
        isOpen: false,
        startDate: filter.startDate ? new Date(filter.startDate) : null,
        endDate: filter.endDate ? new Date(filter.endDate) : null,
        placeholder: true,
    })
    const { isOpen, startDate, endDate, placeholder } = period
    const periodRef = useOutsideClick(() => setPeriod((prev) => ({ ...prev, isOpen: false })))
    const openHandler = () => {
        return setPeriod((prev) => ({ ...prev, isOpen: !isOpen }))
    }

    const DATA_RANGE = useMemo(
        () => `${dateToStringFormat(startDate)} ${startDate || endDate ? '~' : ''} ${dateToStringFormat(endDate)}`,
        [period.startDate, period.endDate]
    )

    useEffect(() => {
        if (globalReset)
            setPeriod({
                isOpen: false,
                startDate: null,
                endDate: null,
                placeholder: true,
            })
    }, [globalReset])

    return (
        <S.Container
            ref={periodRef}
            className='datepicker_component period'
            $form={form}>
            <S.Contents onClick={openHandler}>
                <Icon
                    icon={'calendar_18x18'}
                    color={'gray4'}
                />
                <span>{DATA_RANGE}</span>
            </S.Contents>
            {isOpen && (
                <S.Calendar>
                    <ReactDatePicker
                        locale={ko}
                        dateFormat='yyyy년 MM월 dd일'
                        selectsRange={true}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        onChange={(period) => {
                            setPeriod((prev) => ({
                                ...prev,
                                startDate: period[0],
                                placeholder: false,
                            }))
                            if (period[0]) {
                                setPeriod((prev) => ({
                                    ...prev,
                                    endDate: period[1],
                                }))
                            }
                            if (period[1]) {
                                setPeriod((prev) => ({
                                    ...prev,
                                    isOpen: false,
                                }))
                            }

                            setFilter((prev) => ({
                                ...prev,
                                page: 1,
                                startDate: dateToStringFormat(period[0]),
                                endDate: dateToStringFormat(period[1]),
                            }))
                        }}
                        shouldCloseOnSelect={false}
                        showMonthDropdown
                        useShortMonthInDropdown
                        inline
                        renderCustomHeader={(parmas: ReactDatePickerCustomHeaderProps) => (
                            <DatePickerHeader parmas={parmas} />
                        )}
                    />
                </S.Calendar>
            )}
        </S.Container>
    )
}

export default RangeDatePicker
