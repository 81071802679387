import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { IStyledComponent, styled } from 'styled-components'
import { Substitute } from 'styled-components/dist/types'
import { StatusColorVariation } from 'theme/mixin'
import { IStyledTable, StyledTBody, StyledTHead, StyledTable, StyledTd, StyledTh, StyledTr, TableFormType } from './index.styled'

interface TableProps
    extends ComponentPropsWithoutRef<
        IStyledComponent<'web', Substitute<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, IStyledTable>>
    > {
    form?: TableFormType
}
const Table = ({ form = 'default', ...rest }: TableProps) => {
    return (
        <>
            <StyledTable $form={form}>
                <div className='scrollBox'>
                    <table {...rest} />
                </div>
            </StyledTable>
        </>
    )
}

const TableControlBox = styled.div.attrs((props) => ({
    className: 'tableControlBox',
}))``

const THead = ({ ...rest }: ComponentPropsWithoutRef<'thead'>) => {
    return <StyledTHead {...rest} />
}

const TBody = ({ ...rest }: ComponentPropsWithoutRef<'tbody'>) => {
    return <StyledTBody {...rest} />
}

interface TrProps extends ComponentPropsWithoutRef<'tr'> {
    onClick?: VoidFunction
}
const Tr = ({ onClick, ...rest }: TrProps) => {
    return (
        <StyledTr
            onClick={onClick}
            className={onClick ? 'cursor_pointer' : ''}
            {...rest}
        />
    )
}

const Th = ({ ...rest }: ComponentPropsWithoutRef<'th'>) => {
    return <StyledTh {...rest} />
}

interface TdProps extends ComponentPropsWithoutRef<'td'>, PropsWithChildren {
    color?: StatusColorVariation
    align?: 'left' | 'right' | 'center'
}
const Td = ({ color = 'black', align = 'center', children, ...rest }: TdProps) => {
    return (
        <StyledTd
            className='ellipsis'
            $align={align}
            $color={color}
            {...rest}>
            {children}
        </StyledTd>
    )
}

const col = {
    checkbox: '44px',
    date: '120px',
    date_with_time: '140px',
    period: '160px',
    period_with_time: '240px',

    col100: '100px',
    col120: '120px',
    col140: '140px',
    col160: '160px',
    col180: '180px',
    col200: '200px',
    col220: '220px',
    col240: '240px',
    col260: '260px',
    col280: '280px',
    col300: '300px',
}

export { TBody, THead, Table, TableControlBox, Td, Th, Tr, col }
