import { motion } from "framer-motion";
import styled from "styled-components";

export const Dim = styled(motion.div)<{ $opacity?: number }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => `rgba(0,0,0,${p.$opacity ?? 0.6})`};
`;
