import API from 'api'
import Button from 'components/atoms/Button'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import Pagination from 'components/atoms/Pagination'
import Select from 'components/atoms/Select'
import Summary from 'components/atoms/Summary'
import { Table, TableControlBox, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { FigkCommon } from 'components/common/Figk'
import LabelBox from 'components/molecules/LabelBox'
import TableNoData from 'components/molecules/Nodata/Table'
import Filter from 'components/oraganisms/Filter'
import RangeDatePicker from 'components/oraganisms/RangeDatePicker'
import { useFigAuth } from 'figAuthProvider'
import { useDebounce } from 'hook/useDebounce'
import { useModal } from 'hook/useModal'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { globalContestFilter, globalFilterReset } from 'recoil/atom/globalFilter'
import { SendingAlignSelectData, SendingColWidth, SendingkAppliedSelectData, SendingTh, 에디터특수기호변경 } from 'shared/common'
import { combinationSendingSummary, createWeek, isApplied, isAppliedColor, turnNumbering } from 'shared/function'
import { returnGroupInTextFigk, returnSending } from 'shared/QueryOption/textFigk'
import { FIGK_PATH } from 'shared/Router/Figk'

const TextFigkSending = () => {
    const { user } = useFigAuth()
    const { Notice, closeNotice } = useModal()
    const navigate = useNavigate()

    const [filter, setFilter] = useRecoilState(globalContestFilter)
    const resetGlobalFilter = useResetRecoilState(globalContestFilter)
    const globalReset = useRecoilValue(globalFilterReset)
    const [word, setWord] = useState<string>(filter.word || '')

    const { data: sending, refetch: getTextFigk } = useGetQuery(['@getSendingList'], () => API.textFigk.getTextFigk(filter), returnSending)
    const { data: group } = useGetQuery(['@getSendingGroup'], () => API.textFigk.getGrouopInTextFigk(), returnGroupInTextFigk)

    const { mutate: tryPause, error: pauseError } = useTMutation(
        ['@PauseSending', 'U'],
        (s: 'Y') => API.sending.updateSendPause(s),
        (data: GenericResponse) => pauseSuccessCallback(data)
    )

    const pauseSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            content: <>송고가 중지되었습니다.</>,
            confirm_button_text: '확인',
            confirmHandler: () => closeNotice(),
        })
    }
    const onChangeSelectFilter = (e: string | null, type: string) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            [type]: e,
        }))
    }

    const debounceWordInFilter = useCallback(
        useDebounce(
            (e: string) =>
                setFilter((prev) => ({
                    ...prev,
                    word: e,
                    page: 1,
                })),
            300
        ),
        []
    )

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setWord(val)

        debounceWordInFilter(val)
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,
            page: e,
        }))
    }

    useEffect(() => {
        getTextFigk()
    }, [filter])

    useEffect(() => {
        if (globalReset) {
            setWord('')
            resetGlobalFilter()
        }
    }, [globalReset])

    return (
        <>
            <PageTitle title='송고 관리'>
                <FigkCommon.CurrentRound>
                    <span className='current'>
                        회차 정보:<strong>Vol.{turnNumbering(user?.textWeek)}</strong>
                    </span>
                    <span className='group'>{user?.groupName}조</span>
                </FigkCommon.CurrentRound>
                {/* 중지하기 클릭 후 다음회차 넘어가기 전까지 버튼 비활성> */}
                <Button
                    $size='36'
                    $style='line_secondary'
                    onClick={() =>
                        Notice({
                            type: 'confirm',
                            content: (
                                <>
                                    다음 회차
                                    <strong>(Vol.{turnNumbering(user ? user.textWeek + 1 : 0)})</strong> 송고를 <br />
                                    중지하겠습니까?
                                </>
                            ),
                            confirm_button_text: '중지하기',
                            confirmHandler: () => tryPause('Y'),
                        })
                    }>
                    다음 회차 쉬기
                </Button>
                {user?.processStatus === 'T' && (
                    <Button
                        $size='36'
                        $style='solid_secondary'
                        onClick={() => navigate(`${FIGK_PATH.TURNDOWN}`)}>
                        송고 심사하기
                    </Button>
                )}
            </PageTitle>

            <Summary summary={combinationSendingSummary(sending?.summary)} />

            <Wrapper
                $form='main'
                $whiteBoard
                radius={12}>
                <Filter
                    search={
                        <InputText
                            activate_search
                            activate_delete
                            value={word}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }>
                    <LabelBox label='회차'>
                        <Select
                            options={createWeek(user?.textWeek, true)}
                            defaultStatus={filter.week}
                            handler={(e) => onChangeSelectFilter(e, 'week')}
                        />
                    </LabelBox>
                    <LabelBox label='송고 상태'>
                        <Select
                            defaultStatus={filter.status}
                            options={SendingkAppliedSelectData}
                            handler={(e) => onChangeSelectFilter(e, 'status')}
                        />
                    </LabelBox>
                    <LabelBox label='정렬'>
                        <Select
                            defaultStatus={filter.order}
                            options={SendingAlignSelectData}
                            handler={(e) => onChangeSelectFilter(e, 'orderType')}
                        />
                    </LabelBox>
                    <LabelBox label='조별'>
                        <Select
                            defaultStatus={filter.groupId}
                            options={group ?? [{ status: null, title: '전체' }]}
                            handler={(e) => onChangeSelectFilter(e, 'groupId')}
                        />
                    </LabelBox>
                    <LabelBox label='기간'>
                        <RangeDatePicker filterStatus={globalContestFilter} />
                    </LabelBox>
                </Filter>
                <Table form='default'>
                    <colgroup>
                        {SendingColWidth.map((_, i) => {
                            return (
                                <col
                                    width={_}
                                    key={i}
                                />
                            )
                        })}
                    </colgroup>
                    <THead>
                        <Tr>
                            {SendingTh.map((_, i) => {
                                return <Th key={`sendingTh_${i}`}>{_}</Th>
                            })}
                        </Tr>
                    </THead>
                    <TBody>
                        {sending?.list && sending?.list.length > 0 ? (
                            sending?.list.map((_, i) => {
                                return (
                                    <Tr
                                        key={`${_.id}_${i}`}
                                        onClick={() => navigate(`${FIGK_PATH.SENDING}/${_.id}`)}>
                                        <Td>{`Vol.${turnNumbering(_.week)}`}</Td>
                                        <Td color={isAppliedColor(_.contestStatus)}>{isApplied(_.contestStatus)}</Td>
                                        <Td>{_.totalVote}</Td>

                                        <Td align='left'>{에디터특수기호변경(_.title)}</Td>
                                        <Td>{_.authorEmail}</Td>
                                        <Td>{_.authorNickName || _.authorName}</Td>
                                        <Td>{_.groupName}</Td>
                                        <Td color='date'>{_.appliedAt}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <TableNoData />
                        )}
                    </TBody>
                </Table>
                <TableControlBox>
                    <Pagination
                        currentPage={filter.page}
                        limit={filter.per}
                        setCurrentPage={setPage}
                        isLast={sending?.isLast || false}
                        totalItem={sending?.totalCount ?? 0}
                    />
                </TableControlBox>
            </Wrapper>
        </>
    )
}

export default TextFigkSending
