import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { apiHeader } from 'shared/function'
const sending = {
    getSendingList: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk${req}`)
        return response.data
    },

    updateSendPause: async (s: 'Y') => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`text-figk/pause`, { isPause: s })
        return response.data
    },

    getTurnDownList: async () => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk/turn-down`)
        return response.data
    },

    completeTurnDown: async (ids: Array<number>) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`text-figk/turn-down`, { ids })
        return response.data
    },
}

export default sending
