import API from 'api'
import Icon from 'components/atoms/Icon'
import { SelectState } from 'components/atoms/Select'
import { S } from 'components/template/GlobalHeader/index.styled'
import { useFigAuth } from 'figAuthProvider'
import { useOutsideClick } from 'hook/useOutsideClick'
import useGetQuery from 'hook/useTQuery'
import { ProjectListModel } from 'models/Common'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { toggleMenuValue } from 'recoil/atom'
import { returnProjectList } from 'shared/QueryOption/common'
import { getStorage, setStorage } from 'util/storage'
const FORM = {
    SHOW: 'show',
    HIDE: 'hide',
}
const KEY = 'dashboard_form'
const GlobalHeader = () => {
    const navigate = useNavigate()
    const { user, logout } = useFigAuth()
    const getFormValue = localStorage.getItem(KEY)

    // const [filterProject, setFilterProject] = useState<ProjectListModel[]>([])

    const [activeMenuValue, setAtiveMenuValue] = useRecoilState(toggleMenuValue)

    const [openNav, setOpenNav] = useState<string>(() => getFormValue ?? FORM.SHOW)

    const [select, setSelect] = useState<Omit<SelectState, 'value'> & { value: ProjectListModel }>({
        isOpen: false,
        value: { id: -1, name: '' } ?? null,
    })

    const { isOpen, value } = select

    const selectRef = useOutsideClick(() => setSelect((prev) => ({ ...prev, isOpen: false })))

    const { data: project, refetch: getProjectList } = useGetQuery(['@getProjectList'], () => API.project.getProject(), {
        ...returnProjectList,
        onSuccess: (data) => {
            const getProjectId = Number(getStorage('project')) ?? -1

            setAtiveMenuValue(getProjectId)
        },
        enabled: false,
    })

    const getLayoutFormState = (value: string) => {
        localStorage.setItem(KEY, value)
        setOpenNav(value)
    }

    const layoutFormHandler = () => {
        if (openNav === FORM.SHOW) {
            return getLayoutFormState(FORM.HIDE)
        }
        return getLayoutFormState(FORM.SHOW)
    }

    const setProject = () => {
        const projectId = Number(getStorage('project')) ?? -1
        const projectName = getStorage('pn') ?? ''

        const projectValue: ProjectListModel = { id: projectId, name: projectName }
        setSelect({ isOpen: false, value: projectValue })
    }

    const getValue = (e: ProjectListModel) => {
        setStorage('project', e.id + '')
        setStorage('pn', e.name)
        setSelect({
            isOpen: false,
            value: e,
        })
        setAtiveMenuValue(e.id)
        console.log(e)
        navigate(e.name === 'figk' ? '/dashboard/figk/text_figk/total' : '/dashboard/2023/program/total')
    }

    const filterProject = project && user ? project.filter((i) => user.projectIds.includes(i.id)) : []

    useEffect(() => {
        getProjectList()
        setProject()
    }, [])
    return (
        <header id='globalHeader'>
            <S.Container className={getFormValue === FORM.SHOW ? 'openNav' : ''}>
                <Icon
                    onClick={layoutFormHandler}
                    icon={'header_hamburger'}
                    color={'gray10'}
                />
                <S.Contents>
                    <S.ProjectSelect ref={selectRef}>
                        <S.SelectValueBox onClick={() => setSelect((prev) => ({ ...prev, isOpen: !isOpen }))}>
                            <Icon
                                icon={'header_folder'}
                                color={'gray10'}
                            />
                            <span>{getStorage('pn') ?? 'Click Here'}</span>
                        </S.SelectValueBox>
                        {isOpen && (
                            <S.SelectOptionBox>
                                {filterProject.map((option: ProjectListModel, index: number) => {
                                    return (
                                        <li
                                            key={`menu_${index}`}
                                            className={value?.name === option.name ? 'current' : ''}
                                            onClick={(e) => {
                                                getValue(option)
                                            }}>
                                            <span className='ellipsis'>{option.name}</span>

                                            <Icon
                                                icon={'circle_checked_14x14'}
                                                color='primary'
                                            />
                                        </li>
                                    )
                                })}
                            </S.SelectOptionBox>
                        )}
                    </S.ProjectSelect>
                    <S.LoginBox>
                        <div className='account'>
                            <span>{user?.email}</span>
                        </div>
                        <Icon
                            icon='logout'
                            color='gray5'
                            onClick={logout}
                        />
                    </S.LoginBox>
                </S.Contents>
            </S.Container>
        </header>
    )
}

export default GlobalHeader
