import { styled } from 'styled-components'
import Icon from '../Icon'

const StyledAppendItem = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    height: 3rem;
    border: 1px solid #e9f2fd;
    border-radius: 999px;
    background-color: #f0f6fe;
    padding-inline: 1.3rem 0.8rem;

    span {
        color: ${(p) => p.theme.color.link};
        font-size: 1.2rem;
        line-height: 1;
    }
`

interface AppendItemProps {
    text: string
    onClick?: VoidFunction
}

const AppendItem = ({ text, onClick }: AppendItemProps) => {
    return (
        <StyledAppendItem>
            <span>{text}</span>
            <Icon
                icon={'input_delete'}
                color={'bluegray'}
                onClick={onClick}
            />
        </StyledAppendItem>
    )
}

export default AppendItem
