import { col } from "components/atoms/Table";
import { SearchParamModel } from "models/Common";

export const IMG_URL = "https://figk-dev.s3.ap-northeast-2.amazonaws.com/";
export const PAGE_PER = 11;
export const INFINITY_PER = 999;
export const VOTE_AUDIT_LIMIT = 15;
export const VOTE_AUDIT_LIMIT2 = 12;
export const InputTitleLength = 40;

// 허용가능한 확장자 목록!
export const ALLOW_IMG_FILE_EXTENSION = "jpg,jpeg,png";
export const ALLOW_VIDEO_FILE_EXTENSION = "mp4,avi,mkv";
export const VIDEO_SIZE_MAX_LIMIT = 209715200; // 200MB
export const IMG_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 5MB

export const ArtFigkTh = ["회차", "발행 상태", "제목", "관리자ID", "수정일"];
export const TextFigkTh = [
  "발행 상태",
  "송고 상태",
  "제목",
  "ID",
  "필명",
  "조별",
  "수정일",
];
export const SendingTh = [
  "회차",
  "송고 상태",
  "득표",
  "제목",
  "ID",
  "필명",
  "조별",
  "송고일",
];
export const VotingTh = ["득표", "제목", "태그", "ID", "필명", "송고일"];
export const TagTh = ["태그명", "등록 수", "등록일"];
export const MemberTh = [
  "활동 상태",
  "조별",
  "ID",
  "이름",
  "필명",
  "휴대폰 번호",
  "등록일",
];
export const MemberInfoTh = [
  "발행 상태",
  "송고 상태",
  "제목",
  "작성일",
  "수정일",
];
export const TermsTh = ["버전", "유형", "등록일"];
export const NoticeTh = ["제목", "관리자 ID", "수정일"];

export const SettlementTH = [
  "회차",
  "상태",
  "이름",
  "필명",
  "계좌 정보",
  "내역",
  "총 정산금액",
  "정산 완료일",
];

export const SettlementDetailTH = ["유형", "내역", "총 정산금액", "정산일"];

export const TagColWidth = [col.checkbox, "", col.col260, col.col260];
export const ArtFigkColWidth = [
  col.col100,
  col.col100,
  "",
  col.col200,
  col.period,
];
export const TextFigkColWidth = [
  col.col120,
  col.col120,
  "",
  "",
  col.col120,
  col.col100,
  col.date_with_time,
];
export const SendingColWidth = [
  col.col120,
  col.col120,
  col.col120,
  "",
  "",
  col.col120,
  col.col100,
  col.date_with_time,
];
export const VotingColWidth = [
  col.col100,
  "",
  "",
  "",
  col.col160,
  col.date_with_time,
];
export const MemberColWidth = [
  col.col140,
  col.col140,
  "",
  col.col140,
  col.col140,
  col.col260,
  col.date_with_time,
];
export const MemberInfoColWidth = [
  col.col100,
  col.col100,
  "",
  col.date_with_time,
  col.date_with_time,
];

export const TermsColWidth = [col.col260, "", col.col260];
export const NoticeColWidth = ["", col.col260, col.col260];

export const SettlementColWidth = [
  col.col120,
  col.col120,
  col.col120,
  col.col120,
  "",
  "",
  col.col200,
  col.date_with_time,
];

export const SettlementDetailColWidth = [
  col.col120,
  "",
  col.col200,
  col.date_with_time,
];

export const TagSelectData = [
  { status: null, title: "전체" },
  { status: "1", title: "최신순" },
  { status: "2", title: "오래된순" },
  { status: "3", title: "최다 등록수" },
  { status: "4", title: "최소 등록수" },
];

export const TextFigkPublishSeleceData = [
  { status: null, title: "전체" },
  { status: "Y", title: "발행완료" },
  { status: "N", title: "미발행" },
];

export const MemberStatusSelectData = [
  { status: null, title: "전체" },
  { status: "Y", title: "활성" },
  { status: "N", title: "비활성" },
];

export const TextFigkAppliedSelectData = [
  { status: null, title: "전체" },
  { status: "N", title: "미송고" },
  { status: "E", title: "송고완료" },
  { status: "C", title: "투표마감" },
  { status: "P", title: "당선" },
  { status: "F", title: "낙선" },
  { status: "T", title: "반려" },
];

export const SendingkAppliedSelectData = [
  { status: null, title: "전체" },
  { status: "E", title: "송고완료" },
  { status: "C", title: "투표마감" },
  { status: "P", title: "당선" },
  { status: "F", title: "낙선" },
];

export const SendingAlignSelectData = [
  { status: "A", title: "송고일 순" },
  { status: "V", title: "득표 순" },
];

//약관 유형, 1: 서비스 이용약관, 2: 개인정보 활용 동의서, 3: 저작권 정책, 4: 마케팅 정보 수신동의
export const TermSelectData = [
  { status: "1", title: "서비스 이용약관" },
  { status: "2", title: "개인정보 활용 동의서" },
  { status: "3", title: "저작권 정책" },
  { status: "4", title: "마케팅 정보 수신동의" },
];

export const TextFigkFilterResetData: SearchParamModel = {
  per: PAGE_PER,
  page: 1,
  word: null,
  dateType: null,
  startDate: null,
  endDate: null,
  week: null,
  isPublished: null,
  status: null,
  groupId: null,
  order: null,
  authorId: null,
  isContest: null,
};

export const SendingFilterResetData: SearchParamModel = {
  per: PAGE_PER,
  page: 1,
  word: null,
  dateType: null,
  startDate: null,
  isContest: true,
  endDate: null,
  week: null,
  isPublished: null,
  status: null,
  groupId: null,
  authorId: null,
};

export const 에디터특수기호변경 = (str: string) => {
  return str
    .replace(/&nbsp;/g, "")
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<");
};
