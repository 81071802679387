import allChecked from 'assets/icons/checkbox_all_checked.svg'
import check from 'assets/icons/checkbox_checked.svg'
import { css, styled } from 'styled-components'

export const Checkbox = styled.input.attrs(({ checked, ...props }) => ({
    type: 'checkbox',
}))<{ $all?: boolean; checked?: boolean }>`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${(p) => p.theme.input.line};
    border-radius: 0.4rem;
    background-color: #fff;
    cursor: pointer;
    vertical-align: middle;
    &:checked {
        border-color: ${(p) => p.theme.color.primary};
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: center;
    }
    ${(p) =>
        p.$all &&
        css`
            &:checked {
                border-color: ${(p) => p.theme.color.primary};
                background-color: ${(p) => p.theme.color.primary};
                background-image: url(${allChecked});
                background-repeat: no-repeat;
                background-size: 14px;
                background-position: center;
            }
        `}

    &:disabled {
        background-color: ${(p) => p.theme.input.disabled};
        border: 1px solid ${(p) => p.theme.input.line};
        cursor: not-allowed;
    }
`
