import { css, styled } from 'styled-components'

const menuPadding = css`
    padding-inline: 1.8rem;
    border-radius: 0.8rem;
`

const Container = styled.nav`
    border-right: 1px solid ${(p) => p.theme.line.line1};
    padding: 1.2rem;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(113, 117, 126, 0.04);
`

const MainMenu = styled.div`
    display: flex;
    align-items: center;
    --height: 4.8rem;
    border-radius: 0.8rem;
    height: var(--height);
    color: ${(p) => p.theme.grayScale.gray9};
    font-weight: 400;
    font-size: 1.5rem;
    cursor: pointer;
    ${menuPadding}

    &.active {
        background-color: ${(p) => p.theme.color.primary};
        color: #fff;
        font-weight: 500;
    }
    &:hover:not(.active) {
        background-color: ${(p) => p.theme.color.primary_light_hover};
        > svg path {
            fill: ${(p) => p.theme.grayScale.gray10};
        }
    }
    a {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        width: 100%;
        height: 100%;
        font-weight: inherit;
        line-height: calc(var(--height) + 0.2rem);
        font-size: 1.5rem;
        color: inherit;
    }
`

const SubMenu = styled.ul`
    padding-block: 1.2rem 2rem;
    li {
        display: flex;
        align-items: center;
        height: 3.6rem;
        font-size: 1.4rem;
        color: ${(p) => p.theme.grayScale.gray7};

        ${menuPadding}
        a {
            font-size: inherit;
            color: inherit;
            padding-left: 2.8rem;
            &.active {
                color: ${(p) => p.theme.color.primary};
            }
        }
        &:hover {
            color: ${(p) => p.theme.grayScale.gray10};
        }
    }
`

export const S = {
    Container,
    MainMenu,
    SubMenu,
}
