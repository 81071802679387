import { WrapperTitle } from 'components/atoms/Wrapper/index.styled'
import { VoteGraph } from 'models/TextFigk/vote'
import { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { Chart } from '..'
import { barChartConfig } from '../confit'
import './style.css'

interface ISimpleBar {
    chartTitle: string
    graph: VoteGraph
}

const SimpleBar = ({ chartTitle, graph }: ISimpleBar) => {
    const BAR_COLOR = 'rgba(140,217,255,0.4)'
    const { chart, plotOptions, legend, states, stroke, grid, xaxis, yaxis, dataLabels } = barChartConfig

    // const graphSeries: any = []
    const [graphSeries, setGraphSeries] = useState<any>([])
    const [options, setOptions] = useState<ApexCharts.ApexOptions | undefined>({})

    useEffect(() => {
        const temp: any = []
        for (let i = 0; i < graph.author.length; i++) {
            temp.push({
                x: graph.author[i],
                y: graph.voteCount[i],
                title: graph.figkTitle[i],
                fillColor: BAR_COLOR,
            })
        }

        setGraphSeries(temp)

        setOptions({
            chart,
            plotOptions,
            legend,
            states,
            stroke,
            grid,
            xaxis: { ...xaxis, crosshairs: { show: false } },
            yaxis,
            dataLabels,
            tooltip: {
                shared: true,
                intersect: false,
                custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

                    let html = `
                              <div class="tooltip_container">
                                <div class="tooltip_head">
                                    <span>${data.x}</span>
                                    <span>${data.y}표</span>
                                </div>
                                <p class="ellipsis23">${data.title}</p>
                              </div>`

                    return html
                },
            },
        })
    }, [graph])

    return (
        <Chart.ChartContainer>
            <WrapperTitle>{chartTitle}</WrapperTitle>
            <ApexCharts
                type='bar'
                height={300}
                series={[
                    {
                        data: graphSeries,
                    },
                ]}
                options={options}
            />
        </Chart.ChartContainer>
    )
}

export default SimpleBar
