import API from 'api'
import Button from 'components/atoms/Button'
import { Checkbox } from 'components/atoms/Input/Checkbox/index.styled'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import Pagination from 'components/atoms/Pagination'
import Select from 'components/atoms/Select'
import { Table, TableControlBox, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import { Wrapper, WrapperTitle } from 'components/atoms/Wrapper/index.styled'
import LabelBox from 'components/molecules/LabelBox'
import TableNoData from 'components/molecules/Nodata/Table'
import Filter from 'components/oraganisms/Filter'
import ModalTagRegist from 'components/template/Modal/Figk/ModalTagRegist'
import { useDebounce } from 'hook/useDebounce'
import { useModal } from 'hook/useModal'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { TagResponseModel } from 'models/Tag'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { globalFilter, globalFilterReset } from 'recoil/atom/globalFilter'
import { TagColWidth, TagSelectData, TagTh } from 'shared/common'

const FigkTagManagement = () => {
    const navigate = useNavigate()
    const { Notice, Modal, closeNotice } = useModal()

    const [filter, setFilter] = useRecoilState(globalFilter)
    const resetGlobalFilter = useResetRecoilState(globalFilter)
    const globalReset = useRecoilValue(globalFilterReset)

    const [word, setWord] = useState<string>(filter.word || '')

    // 선택된 전체 체크 리스트
    const [checkList, setCheckList] = useState<number[]>([])

    // 페이지별 리스트
    const [checkListPage, setCheckListPage] = useState<number[]>([])

    const { data: tag, refetch: getTag } = useGetQuery(['@getTagList'], () => API.tag.getTagList(filter), {
        select: (res: GenericResponse) => {
            const data: TagResponseModel = res.data

            return data
        },
        onSuccess: (data) => {
            let temp: number[] = []

            if (checkListPage.length > 0) {
                temp = data.list.map((i) => i.id)
            }

            setCheckListPage(temp)
        },
    })

    const { mutate: tryTag, error: delTagError } = useTMutation(
        ['@DeleteTag', 'D'],
        (ids: number[]) => API.tag.deleteTags(ids),
        (rs: GenericResponse) => deleteTagSuccessCallback(rs)
    )

    const deleteTagSuccessCallback = (res: GenericResponse) => {
        closeNotice()
        resetFilter()
        getTag()
    }
    // 전체 체크 클릭
    const onCheckedAll = (checked: boolean) => {
        if (checked) {
            const temp: number[] = []

            tag?.list.map((i) => i.id).forEach((list) => temp.push(list))

            // 기존 배열 + 추가 배열 합치기
            let merged = checkList.concat(temp)
            let unique = merged.filter((_, i) => merged.indexOf(_) === i)

            setCheckList(unique) //전체 선택된
            setCheckListPage(temp) // 페이지별 선택된 리스트
        } else {
            setCheckList(checkList.filter((i) => !checkListPage.includes(i)))
            setCheckListPage([])
        }
    }

    // 개별 체크 클릭
    const onCheckedElement = (checked: boolean, v: number) => {
        if (checked) {
            setCheckList((prev) => [...prev, v])
            setCheckListPage((prev) => [...prev, v])
        } else {
            setCheckList(checkList.filter((el) => el !== v))
            setCheckListPage(checkListPage.filter((el) => el !== v))
        }
    }

    const debounceWordInFilter = useCallback(
        useDebounce((e: string) => {
            setCheckList([])
            setCheckListPage([])

            setFilter((prev) => ({
                ...prev,
                word: e,
                page: 1,
            }))
        }, 300),
        []
    )

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setWord(val)

        debounceWordInFilter(val)
    }

    const onChangeSelectFilter = (e: string | null) => {
        setCheckList([])
        setCheckListPage([])
        setFilter((prev) => ({
            ...prev,
            page: 1,
            order: e,
        }))
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,
            page: e,
        }))
    }

    const resetFilter = () => {
        setWord('')
        resetGlobalFilter()
        setCheckList([])
        setCheckListPage([])
    }

    const tagDeleteHandler = () => {
        Notice({
            type: 'confirm',
            icon: 'notice_bang_mark',
            title: '선택한 태그를 삭제시겠습니까?',
            content: '해당 태그를 사용한 TEXT FIGK, ART FIGK\n태그 목록에서도 삭제됩니다.',
            confirm_button_text: '삭제하기',
            confirmHandler: () => tryTag(checkList),
        })
    }

    useEffect(() => {
        getTag()
    }, [filter])

    // 페이지 이동시 한 페이지의 체크 리스트가 들어있는 배열 초기화
    useEffect(() => {
        setCheckListPage([])
    }, [filter.page])

    useEffect(() => {
        if (globalReset) {
            resetFilter()
        }
    }, [globalReset])

    return (
        <>
            <PageTitle title='태그 관리'>
                <Button
                    $size='36'
                    $style='solid_secondary'
                    onClick={() =>
                        Modal({
                            content: <ModalTagRegist />,
                        })
                    }>
                    태그 등록하기
                </Button>
            </PageTitle>

            <Wrapper
                $form='main'
                $whiteBoard
                radius={12}>
                <WrapperTitle>
                    등록된 태그 : <span className='color_primary'>{tag?.totalCount}개</span>
                </WrapperTitle>
                <Filter
                    search={
                        <InputText
                            activate_search
                            activate_delete
                            value={word}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }>
                    <LabelBox label='정렬'>
                        <Select
                            options={TagSelectData}
                            defaultStatus={filter.order}
                            handler={onChangeSelectFilter}
                        />
                    </LabelBox>
                </Filter>
                <Table form='default'>
                    <colgroup>
                        {TagColWidth.map((_, i) => {
                            return (
                                <col
                                    width={_}
                                    key={i}
                                />
                            )
                        })}
                    </colgroup>
                    <THead>
                        <Tr>
                            <Th>
                                <Checkbox
                                    $all
                                    name={`${filter.page}_all_name`}
                                    id={`${filter.page}_all_id`}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckedAll(e.target.checked)}
                                    checked={(tag?.list.every((i) => checkList.includes(i.id)) as boolean) ?? 1}
                                />
                            </Th>
                            {TagTh.map((_, i) => {
                                return <Th key={`tagTh_${i}`}>{_}</Th>
                            })}
                        </Tr>
                    </THead>
                    <TBody>
                        {tag?.list && tag?.list.length > 0 ? (
                            tag?.list.map((_) => {
                                return (
                                    <Tr key={`tagList_${_.id}`}>
                                        <Td>
                                            <Checkbox
                                                id={`checked_${_.id}`}
                                                checked={checkList.includes(_.id)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    onCheckedElement(e.target.checked, _.id)
                                                }
                                            />
                                        </Td>
                                        <Td align='left'>#{_.name}</Td>
                                        <Td>{_.cnt}</Td>
                                        <Td color='date'>{_.registeredAt}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <TableNoData />
                        )}
                    </TBody>
                </Table>
                <TableControlBox>
                    <Button
                        $size='26'
                        disabled={checkList.length === 0}
                        $style='line_secondary'
                        onClick={tagDeleteHandler}>
                        선택 삭제
                    </Button>
                    <Pagination
                        currentPage={filter.page}
                        limit={filter.per}
                        setCurrentPage={setPage}
                        isLast={tag?.isLast || false}
                        totalItem={tag?.totalCount ?? 0}
                    />
                </TableControlBox>
            </Wrapper>
        </>
    )
}

export default FigkTagManagement
