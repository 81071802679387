import API from 'api'
import Button from 'components/atoms/Button'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import Pagination from 'components/atoms/Pagination'
import { TBody, THead, Table, TableControlBox, Td, Th, Tr } from 'components/atoms/Table'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import LabelBox from 'components/molecules/LabelBox'
import TableNoData from 'components/molecules/Nodata/Table'
import Filter from 'components/oraganisms/Filter'
import RangeDatePicker from 'components/oraganisms/RangeDatePicker'
import { useDebounce } from 'hook/useDebounce'
import useGetQuery from 'hook/useTQuery'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { globalFilter, globalFilterReset } from 'recoil/atom/globalFilter'
import { returnNotice } from 'shared/QueryOption/notice'
import { FIGK_PATH } from 'shared/Router/Figk'
import { NoticeColWidth, NoticeTh } from 'shared/common'
const FigkNotice = () => {
    const navigate = useNavigate()

    const [filter, setFilter] = useRecoilState(globalFilter)
    const resetGlobalFilter = useResetRecoilState(globalFilter)
    const globalReset = useRecoilValue(globalFilterReset)

    const [word, setWord] = useState<string>(filter.word || '')

    const { data: notice, refetch: getNoticeList } = useGetQuery(
        ['@getNoticeList'],
        () => API.notice.getNotice(filter),
        returnNotice
    )

    const debounceWordInFilter = useCallback(
        useDebounce((e: string) => {
            setFilter((prev) => ({
                ...prev,
                word: e,
                page: 1,
            }))
        }, 300),
        []
    )

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setWord(val)

        debounceWordInFilter(val)
    }

    const onChangeDate = (s: Date | null | undefined, e: Date | null | undefined) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            startDate: s ? s.toLocaleDateString().replaceAll('. ', '-') : null,
            endDate: e ? e.toLocaleDateString().replaceAll('. ', '-') : null,
        }))
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,

            page: e,
        }))
    }

    useEffect(() => {
        getNoticeList()
    }, [filter])

    useEffect(() => {
        if (globalReset) {
            setWord('')
            resetGlobalFilter()
        }
    }, [globalReset])

    return (
        <>
            <PageTitle title='공지 관리'>
                <Button
                    $size='36'
                    $style='solid_secondary'
                    onClick={() => navigate(FIGK_PATH.FIGK_NOTICE_REGIST)}>
                    공지 등록하기
                </Button>
            </PageTitle>

            <Wrapper
                $form='main'
                $whiteBoard
                radius={12}>
                <Filter
                    search={
                        <InputText
                            activate_search
                            activate_delete
                            value={word}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }>
                    <LabelBox label='기간'>
                        <RangeDatePicker filterStatus={globalFilter} />
                    </LabelBox>
                </Filter>
                <Table form='default'>
                    <colgroup>
                        {NoticeColWidth.map((_, i) => {
                            return (
                                <col
                                    width={_}
                                    key={i}
                                />
                            )
                        })}
                    </colgroup>
                    <THead>
                        <Tr>
                            {NoticeTh.map((_, i) => {
                                return <Th key={`notice_Th_${i}`}>{_}</Th>
                            })}
                        </Tr>
                    </THead>
                    <TBody>
                        {notice?.list && notice?.list.length > 0 ? (
                            notice?.list.map((_, i) => {
                                return (
                                    <Tr
                                        key={`${_.id}_${i}`}
                                        onClick={() => navigate(`${FIGK_PATH.FIGK_NOTICE}/${_.id}`)}>
                                        <Td align='left'>{_.title}</Td>
                                        <Td>{_.adminEmail}</Td>
                                        <Td color='date'>{_.updatedAt !== '' ? _.updatedAt : '-'}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <TableNoData />
                        )}
                    </TBody>
                </Table>
                <TableControlBox>
                    <Pagination
                        currentPage={filter.page}
                        limit={filter.per}
                        setCurrentPage={setPage}
                        isLast={notice?.isLast || false}
                        totalItem={notice?.totalCount ?? 0}
                    />
                </TableControlBox>
            </Wrapper>
        </>
    )
}

export default FigkNotice
