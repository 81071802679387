import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { apiHeader } from 'shared/function'
const artFigk = {
    getTagList: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`tag${req}`)
        return response.data
    },

    createTag: async (d: string) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`tag`, { tags: d })
        return response.data
    },

    deleteTags: async (id: number[]) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`tag`, { data: { ids: id } })
        return response.data
    },

    invalidTag: async (tag: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`tag/invalid`, { params: { tag } })
        return response.data
    },
}

export default artFigk
