import { css, styled } from 'styled-components'

export type ButtonHeightType = '26' | '30' | '32' | '36' | '40' | '44' | '52'
export interface IButtonHeight {
    $size?: ButtonHeightType
}
const ButtonHeight = css<IButtonHeight>`
    border-radius: 0.6rem;
    font-weight: 500;
    ${(p) =>
        p.$size === '26' &&
        css`
            height: 2.6rem;
            border-radius: 0.4rem;
            padding-inline: 1rem;
            font-size: 1.2rem;
        `}
    ${(p) =>
        p.$size === '30' &&
        css`
            height: 3rem;
            padding-inline: 1.4rem;
            font-size: 1.2rem;
        `}
${(p) =>
        p.$size === '32' &&
        css`
            height: 3.2rem;
            padding-inline: 1.8rem;
            font-size: 1.3rem;
        `}
${(p) =>
        p.$size === '36' &&
        css`
            height: 3.6rem;
            padding-inline: 2rem;
            font-size: 1.3rem;
        `}
${(p) =>
        p.$size === '40' &&
        css`
            height: 4rem;
            padding-inline: 2rem;
            font-size: 1.4rem;
        `}
${(p) =>
        p.$size === '44' &&
        css`
            height: 4.4rem;
            padding-inline: 3rem;
            font-size: 1.4rem;
        `}

    ${(p) =>
        p.$size === '52' &&
        css`
            height: 5.2rem;
            padding-inline: 3rem;
            font-size: 1.5rem;
        `}
`

export type ButtonStyleType =
    | 'solid_primary'
    | 'solid_secondary'
    | 'solid_darkgray'
    | 'solid_gray'
    | 'solid_negative'
    | 'line_primary'
    | 'line_secondary'
    | 'line_primary_blur'
    | 'line_secondary_blur'
    | 'line_negative'
    | 'line_toggle'
export interface IButtonStyle {
    $style?: ButtonStyleType
}

const SolidDisabled = css`
    &:disabled {
        background-color: ${(p) => p.theme.button.disabled};
        border-color: ${(p) => p.theme.button.disabled};
        color: #fff;
        cursor: not-allowed;
        &:hover {
            background-color: ${(p) => p.theme.button.disabled};
            border-color: ${(p) => p.theme.button.disabled};
        }
    }
`

const LineDisabled = css`
    &:disabled {
        border-color: ${(p) => p.theme.button.disabled};
        color: ${(p) => p.theme.button.disabled};
        cursor: not-allowed;
        &:hover {
            background-color: #fff;
            border-color: ${(p) => p.theme.button.disabled};
            color: ${(p) => p.theme.button.disabled};
        }
    }
`
const ButtonStyle = css<IButtonStyle>`
    border: 1px solid;
    ${(p) =>
        p.$style === 'solid_primary' &&
        css`
            background-color: ${(p) => p.theme.button.primary};
            border-color: ${(p) => p.theme.button.primary};
            color: #fff;
            &:hover {
                background-color: ${(p) => p.theme.button.primary_hover};
                border-color: ${(p) => p.theme.button.primary_hover};
            }
            ${SolidDisabled}
        `}
    ${(p) =>
        p.$style === 'solid_secondary' &&
        css`
            background-color: ${(p) => p.theme.button.black};
            border-color: ${(p) => p.theme.button.black};
            color: #fff;
            &:hover {
                background-color: ${(p) => p.theme.button.black_hover};
                border-color: ${(p) => p.theme.button.black_hover};
            }
            ${SolidDisabled}
        `}
${(p) =>
        p.$style === 'solid_darkgray' &&
        css`
            background-color: ${(p) => p.theme.button.darkgray};
            border-color: ${(p) => p.theme.button.darkgray};
            color: #fff;
            &:hover {
                background-color: ${(p) => p.theme.button.darkgray_hover};
                border-color: ${(p) => p.theme.button.darkgray_hover};
            }
            ${SolidDisabled}
        `}
${(p) =>
        p.$style === 'solid_gray' &&
        css`
            background-color: ${(p) => p.theme.button.gray};
            border-color: ${(p) => p.theme.button.gray};
            color: #fff;
            &:hover {
                background-color: ${(p) => p.theme.button.gray_hover};
                border-color: ${(p) => p.theme.button.gray_hover};
            }
            ${SolidDisabled}
        `}

    ${(p) =>
        p.$style === 'solid_negative' &&
        css`
            background-color: ${(p) => p.theme.button.negative};
            border-color: ${(p) => p.theme.button.negative};
            color: #fff;
            &:hover {
                background-color: ${(p) => p.theme.button.negative_hover};
                border-color: ${(p) => p.theme.button.negative_hover};
            }
            ${SolidDisabled}
        `}

    ${(p) =>
        p.$style === 'line_primary' &&
        css`
            /* background-color: #fff; */
            border-color: ${(p) => p.theme.button.primary};
            color: ${(p) => p.theme.button.primary};
            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                border-color: ${(p) => p.theme.button.primary_hover};
                color: ${(p) => p.theme.button.primary_hover};
            }
            ${LineDisabled}
        `}

    ${(p) =>
        p.$style === 'line_primary_blur' &&
        css`
            /* background-color: #fff; */
            border-color: ${(p) => p.theme.button.primary};
            color: ${(p) => p.theme.button.primary};
            &:hover {
                background-color: ${(p) => p.theme.button.light_primary};
                border-color: ${(p) => p.theme.button.primary_hover};
                color: ${(p) => p.theme.button.primary_hover};
            }
            ${LineDisabled}
        `}

    ${(p) =>
        p.$style === 'line_secondary' &&
        css`
            /* background-color: #fff; */
            border-color: ${(p) => p.theme.grayScale.gray9};
            color: ${(p) => p.theme.grayScale.gray9};
            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                border-color: ${(p) => p.theme.button.black_hover};
                color: ${(p) => p.theme.button.black_hover};
            }
            ${LineDisabled}
        `}
    ${(p) =>
        p.$style === 'line_secondary_blur' &&
        css`
            /* background-color: #fff; */
            border-color: ${(p) => p.theme.button.black};
            color: ${(p) => p.theme.button.black};
            &:hover {
                background-color: ${(p) => p.theme.button.light_gray};
                border-color: ${(p) => p.theme.button.black_hover};
                color: ${(p) => p.theme.button.black_hover};
            }
            ${LineDisabled}
        `}

    ${(p) =>
        p.$style === 'line_negative' &&
        css`
            /* background-color: #fff; */
            color: ${(p) => p.theme.button.negative};
            border-color: ${(p) => p.theme.button.negative};

            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                color: ${(p) => p.theme.button.negative_hover};
                border-color: ${(p) => p.theme.button.negative_hover};
            }
            ${LineDisabled}
        `}

        ${(p) =>
        p.$style === 'line_toggle' &&
        css`
            /* background-color: #fff; */
            color: ${(p) => p.theme.grayScale.gray6};
            border-color: ${(p) => p.theme.grayScale.gray3};

            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                border-color: ${(p) => p.theme.button.gray};
                color: ${(p) => p.theme.grayScale.gray8};
            }
            ${LineDisabled}
        `}
`

export type ButtonDisplayType = 'inline' | 'block'

export interface IButtonDisplay {
    $display?: ButtonDisplayType
}

const ButtonDisplay = css<IButtonDisplay>`
    ${(p) =>
        p.$display === 'block' &&
        css`
            display: block;
            width: 100%;
        `};
    ${(p) =>
        p.$display === 'inline' &&
        css`
            display: inline;
        `};
`

export interface IButtonLoading {
    $loading?: boolean
}
const ButtonLoading = css<IButtonLoading>`
    ${(p) =>
        p.$loading &&
        css`
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                vertical-align: middle;

                height: min(16px, 70%);
                aspect-ratio: 1/1;
                border-radius: 50%;
                background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/3px 3px no-repeat, conic-gradient(#0000 60%, #ffffff);
                -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
                animation: s3 0.8s infinite linear;
            }
            span {
                opacity: 0;
            }
        `}
    @keyframes s3 {
        100% {
            transform: translate3d(-50%, -50%, 0) rotate(1turn);
        }
    }
`

export const StyledButton = styled.button<IButtonHeight & IButtonStyle & IButtonDisplay & IButtonLoading>`
    ${ButtonDisplay}
    ${ButtonHeight}
  ${ButtonStyle}
  ${ButtonLoading}
  position: relative;
    span {
        font-size: inherit;
        color: inherit;
    }
`
