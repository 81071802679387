import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import TextEditor from 'components/atoms/Editor'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import { useFigAuth } from 'figAuthProvider'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { GenericResponse } from 'models/Common'
import { NoticeRegistModel } from 'models/Notice'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FIGK_PATH } from 'shared/Router/Figk'
import { InputTitleLength } from 'shared/common'
import { isEmptyObj } from 'shared/function'
const FigkNoticeRegist = () => {
    const { user } = useFigAuth()
    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)

    const [textEditData, setTextEditData] = useState<NoticeRegistModel>({
        title: '',
        content: '',
    })
    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(true)
    useWarnningGoBack(FIGK_PATH.FIGK_NOTICE, true)

    const { mutate: tryCreate, error: createError } = useTMutation(
        ['@createNotice', 'C'],
        (data: NoticeRegistModel) => API.notice.createNotice(data),
        (res: GenericResponse) => createNoticeSuccessCallback(res)
    )

    const createNoticeSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '등록이 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_NOTICE)
            },
        })
    }

    const onBack = () => {
        Notice({
            type: 'confirm',
            content: '변경한 내용이 저장되지 않습니다.',
            cancel_button_text: '취소',
            confirm_button_text: '확인',
            confirmHandler: () => {
                navigate(FIGK_PATH.FIGK_NOTICE)
                closeNotice()
            },
        })
    }

    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title='공지사항 등록' />

            <Detail.Container>
                {/* <Detail.Title title='공지사항 정보' /> */}

                <Detail.Row>
                    <Detail.Col title='관리자 ID'>{user?.email}</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='제목'
                        required>
                        <InputText
                            name='title'
                            maxLength={InputTitleLength}
                            value={textEditData.title}
                            onChange={(e) =>
                                setTextEditData((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                }))
                            }
                            placeholder='최대 40자 이내'
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='내용'
                        required>
                        <TextEditor
                            disabled={true}
                            value={textEditData.content}
                            onChange={(e) =>
                                setTextEditData((prev) => ({
                                    ...prev,
                                    content: e,
                                }))
                            }
                        />
                        {/* <Textarea
                            name='contents'
                            value={textEditData.content}
                            onChange={(e) => onChangeText(e, 'content')}
                            placeholder='내용을 입력해주세요.'
                        /> */}
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>

            <ButtonBox>
                <Button
                    $style='line_secondary'
                    onClick={() => onBack()}>
                    돌아가기
                </Button>

                <Button
                    $style='solid_primary'
                    disabled={isEmptyObj(textEditData)}
                    onClick={() =>
                        Notice({
                            type: 'confirm',
                            icon: 'notice_bang_mark',
                            content: '작성한 공지사항을 등록할까요?',
                            confirm_button_text: '등록하기',
                            confirmHandler: () => {
                                tryCreate(textEditData)
                            },
                        })
                    }>
                    등록하기
                </Button>
            </ButtonBox>
        </Wrapper>
    )
}

export default FigkNoticeRegist
