import { ArtFigkResponse } from 'models/ArtFigk'
import { GenericResponse } from 'models/Common'

export const returnArtFigk = {
    select: (res: GenericResponse) => {
        const data: ArtFigkResponse = res.data

        return data
    },
}
