import svgPaths from 'components/atoms/Icon/svgPaths.json'
import { ComponentPropsWithRef } from 'react'
import { theme } from 'theme'
import { IconColor, IconName } from './icon'
export interface IconProps extends ComponentPropsWithRef<'svg'> {
    icon: IconName
    color: IconColor
    onClick?: VoidFunction
}

const Icon = ({ icon, color, onClick, ...rest }: IconProps) => {
    const { path, width, height } = svgPaths[icon]
    const IconPalette = Object.assign({}, theme.color, theme.grayScale)
    return (
        // <i style={{ display: "block", width: `${width}px`, height: `${height}px` }}>
        <svg
            width={width}
            height={height}
            fillRule='evenodd'
            clipRule='evenodd'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='none'
            onClick={onClick}
            data-cursor={onClick ? 'pointer' : ''}
            {...rest}>
            <path
                d={path}
                fill={IconPalette[color]}
            />
        </svg>
        // </i>
    )
}

export default Icon
