import { styled } from 'styled-components'

const VotingCounter = styled.div`
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.grayScale.gray6};
    font-size: 1.3rem;
    font-weight: 600;

    span {
        color: ${(p) => p.theme.grayScale.gray9};
        font-size: 1.3rem;
        strong {
            color: ${(p) => p.theme.color.primary};
            font-size: 1.8rem;
            font-weight: 600;
            margin-inline: 1.2rem 0.4rem;
        }
    }
`

interface IListControlBox {
    justifycontent?: 'space-between' | 'flex-end'
}
const ListControlBox = styled.div<IListControlBox>`
    display: flex;
    justify-content: ${(p) => p.justifycontent ?? 'space-between'};
    align-items: center;
    padding-block-end: 2.2rem;
`

const ListBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const AccordionContainer = styled.div`
    padding-inline: 3rem;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.line.line1};
    box-shadow: 0px 0px 8px 0px rgba(113, 117, 126, 0.04);
`

const AccordionHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
    .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: ${(p) => p.theme.grayScale.gray10};
    }
    .right {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        .voted {
            display: inline-block;
            border-radius: 999px;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1;
            color: ${(p) => p.theme.color.positive};
            background-color: ${(p) => p.theme.color.positive_bg};
            padding: 6px 10px;
            margin-inline-end: -8px;
        }
    }
`

const AccordionBody = styled.div`
    padding-block: 0px 20px;

    .subject {
        display: inline-block;
        font-size: 1.3rem;
        color: ${(p) => p.theme.grayScale.gray6};
        padding-block-end: 1.2rem;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .contents {
        /* 393C40 */
        color: ${(p) => p.theme.grayScale.gray9};
        padding-block-end: 2rem;
    }
    .tags {
        font-size: 1.3rem;
        font-weight: 300;
        color: ${(p) => p.theme.grayScale.gray4};
        padding-block-end: 2rem;
    }
    .writer {
        font-size: 1.3rem;
        font-weight: 500;
        color: ${(p) => p.theme.grayScale.gray9};
    }
`

export const S = {
    VotingCounter,
    ListControlBox,
    ListBox,
    AccordionContainer,
    AccordionHead,
    AccordionBody,
}
