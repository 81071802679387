import { GenericResponse } from 'models/Common'
import { TermListModel, TermResponseModel } from 'models/Term'

export const returnTerms = {
    select: (res: GenericResponse) => {
        const data: TermResponseModel = res.data

        return data
    },
}

export const returnTermsDetail = {
    select: (res: GenericResponse) => {
        const data: TermListModel = res.data

        return data
    },
}
