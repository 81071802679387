import API from 'api'
import Icon from 'components/atoms/Icon'
import { col, Table, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import ModalNoData from 'components/molecules/Nodata/Modal'
import { Modal } from 'components/template/GlobalModal/index.styled'
import { useModal } from 'hook/useModal'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'models/Common'
import { IsVotedManModel } from 'models/TextFigk/vote'
import { useEffect, useState } from 'react'

interface Props {
    data?: IsVotedManModel
}

const ModalVotig = ({ data }: Props) => {
    console.log(data)
    const { closeModal } = useModal()
    const [voteList, setVoteList] = useState<IsVotedManModel>()

    const { refetch: getVote } = useGetQuery(['@getVotedMan'], () => API.vote.getVotedMan('O'), {
        select: (res: GenericResponse) => {
            const data: IsVotedManModel = res.data

            return data
        },
        onSuccess: (res: IsVotedManModel) => {
            setVoteList(res)
        },
        enabled: false,
    })

    useEffect(() => {
        if (data) setVoteList(data)
        else getVote()
    }, [])

    return (
        <Modal.Container>
            <Modal.Header>
                <span className='title'>투표 참여 인원 ({voteList?.totalCount})</span>
                <Icon
                    icon={'modal_close'}
                    color={'gray6'}
                    role='button'
                    onClick={closeModal}
                />
            </Modal.Header>
            {voteList && voteList?.totalCount > 0 && (
                <Modal.ModalScollBox>
                    <Table>
                        <colgroup>
                            <col width={col.col200} />
                            <col />
                            <col width={col.date_with_time} />
                        </colgroup>
                        <THead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>필명</Th>
                                <Th>투표 완료일</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {voteList?.list.map((_, i) => {
                                return (
                                    <Tr key={`${_.id}_${i}`}>
                                        <Td align='left'>{_.email} </Td>
                                        <Td>{_.name}</Td>
                                        <Td color='date'>{_.registeredAt}</Td>
                                    </Tr>
                                )
                            })}
                        </TBody>
                    </Table>
                </Modal.ModalScollBox>
            )}
            {!voteList && <ModalNoData text='투표에 참여한 회원이 없습니다.' />}

            {/* <Modal.ButtonBox>
                <Button
                    type='button'
                    $size='26'>
                    BTN
                </Button>{' '}
                <Button
                    type='button'
                    $size='26'>
                    BTN
                </Button>
            </Modal.ButtonBox> */}
        </Modal.Container>
    )
}

export default ModalVotig
