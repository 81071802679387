import { AnimatePresence } from 'framer-motion'

import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import { useModal } from 'hook/useModal'
import { useRecoilValue } from 'recoil'
import { loadingState } from 'recoil/atom/modal'
import { variants } from 'theme/motion'
import { Notice } from './index.styled'

const GlobalNotice = () => {
    const isLoading = useRecoilValue(loadingState)
    const { noticeDataState, closeNotice } = useModal()

    return (
        <AnimatePresence>
            {noticeDataState.isOpen && (
                <Notice.Container
                    variants={variants.fadeInOut}
                    initial='initial'
                    animate='animate'
                    exit='exit'>
                    {/* <Dim  /> */}
                    <Notice.Contents>
                        <Notice.IconBg $bg='primary_bg'>
                            <Icon
                                icon={noticeDataState.icon ?? 'notice_bang_mark'}
                                color={noticeDataState.icon_color ?? 'primary'}
                            />
                        </Notice.IconBg>
                        <span className='title'>{noticeDataState.title}</span>
                        <p>{noticeDataState.content}</p>
                        <Notice.ButtonBox>
                            {noticeDataState.type === 'alert' && (
                                <Button
                                    type='button'
                                    $style={'solid_primary'}
                                    $size={'32'}
                                    $loading={isLoading}
                                    onClick={noticeDataState.confirmHandler ?? closeNotice}>
                                    {noticeDataState.cancel_button_text ?? '확인'}
                                </Button>
                            )}
                            {noticeDataState.type === 'confirm' && (
                                <>
                                    <Button
                                        type='button'
                                        $style={'line_secondary'}
                                        $size={'32'}
                                        onClick={closeNotice}>
                                        {noticeDataState.cancel_button_text ?? '취소'}
                                    </Button>
                                    <Button
                                        type='button'
                                        $style={'solid_primary'}
                                        $size={'32'}
                                        $loading={isLoading}
                                        onClick={noticeDataState.confirmHandler}>
                                        {noticeDataState.confirm_button_text ?? '확인'}
                                    </Button>
                                </>
                            )}
                        </Notice.ButtonBox>
                    </Notice.Contents>
                </Notice.Container>
            )}
        </AnimatePresence>
    )
}

export default GlobalNotice
