import GlobalModal from 'components/template/GlobalModal'
import GlobalNotice from 'components/template/GlobalNotice'
import { FigAuthProvider } from 'figAuthProvider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import PageRouter from './router'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <RecoilRoot>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <FigAuthProvider>
                        {/* <FigPermissionProvider> */}
                        <ThemeProvider theme={theme}>
                            <App />
                            <GlobalModal />
                            <GlobalNotice />
                            <RouterProvider router={PageRouter} />
                        </ThemeProvider>
                        {/* </FigPermissionProvider> */}
                    </FigAuthProvider>
                </RecoilRoot>
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position='bottom-right'
                />
            </QueryClientProvider>
        </React.StrictMode>
    </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
