import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import TextEditor from 'components/atoms/Editor'
import PageTitle from 'components/atoms/PageTitle'
import Select from 'components/atoms/Select'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { GenericResponse } from 'models/Common'
import { TermRegistModel } from 'models/Term'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FIGK_PATH } from 'shared/Router/Figk'
import { TermSelectData } from 'shared/common'
import { isEmptyObj } from 'shared/function'
const FigkTermsRegist = () => {
    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()

    const [regist, setRegist] = useState<TermRegistModel>({
        content: '',
        termType: -1,
    })
    usePreventRefresh()
    useWarnningGoBack(FIGK_PATH.FIGK_TERMS)

    const { mutate: tryCreate, error: createError } = useTMutation(
        ['@createTerm', 'C'],
        (data: TermRegistModel) => API.term.createTerm(data),
        (data: GenericResponse) => createTermSuccessCallback(data)
    )

    const createTermSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '등록이 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_TERMS)
            },
        })
    }

    const onChangeRegistForm = (e: string | null | undefined, type: 'termType' | 'content') => {
        setRegist((prev) => ({
            ...prev,
            [type]: type === 'termType' ? Number(e) : e,
        }))
    }

    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title='약관 등록' />
            <Detail.Container>
                <Detail.Row>
                    <Detail.Col
                        title='유형'
                        required>
                        <Select
                            handler={(e) => onChangeRegistForm(e, 'termType')}
                            options={TermSelectData}
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='내용'
                        required>
                        <TextEditor
                            disabled={true}
                            value={regist.content}
                            onChange={(e) => onChangeRegistForm(e, 'content')}
                        />
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>

            <ButtonBox>
                <Button
                    $style='line_secondary'
                    onClick={() => navigate(FIGK_PATH.FIGK_TERMS)}>
                    돌아가기
                </Button>

                <Button
                    $style='solid_primary'
                    disabled={regist.termType === -1 || isEmptyObj(regist)}
                    onClick={() =>
                        Notice({
                            type: 'confirm',
                            icon: 'notice_bang_mark',
                            content: '작성한 공지사항을 등록할까요?',
                            confirm_button_text: '등록하기',
                            confirmHandler: () => {
                                tryCreate(regist)
                            },
                        })
                    }>
                    등록하기
                </Button>
            </ButtonBox>
        </Wrapper>
    )
}

export default FigkTermsRegist
