import API from 'api'
import Button from 'components/atoms/Button'
import { Detail } from 'components/atoms/DetailTable'
import TextEditor from 'components/atoms/Editor'
import InputText from 'components/atoms/Input/InputText'
import PageTitle, { PageInfo } from 'components/atoms/PageTitle'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import { ButtonBox } from 'components/molecules/ButtonBox/index.styled'
import { useModal } from 'hook/useModal'
import usePreventRefresh from 'hook/usePreventRefresh'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import useWarnningGoBack from 'hook/ussWarnningGoBack'
import { GenericResponse } from 'models/Common'
import { NoticeRegistModel } from 'models/Notice'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { returnNoticeDetail } from 'shared/QueryOption/notice'
import { FIGK_PATH } from 'shared/Router/Figk'
import { InputTitleLength } from 'shared/common'
import { ScrollPositionTop, isEmptyObj } from 'shared/function'
const FigkNoticeDetail = () => {
    const { id: detailId } = useParams()

    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()
    //수정 , 저장 토글 State
    const [edit, setEdit] = useState<boolean>(false)

    const [textEditData, setTextEditData] = useState<NoticeRegistModel & { id: number }>({
        id: -1,
        title: '',
        content: '',
    })

    const { data: notice, refetch: getNoticeDetail } = useGetQuery([`@getNoticeDetail_${detailId + ''}`], () => API.notice.getNoticeDetail(detailId ?? ''), {
        ...returnNoticeDetail,
        onSuccess: (data) => {
            setTextEditData({
                id: data.id,
                title: data.title,
                content: data.content,
            })
        },
    })
    const { mutate: tryUpdate, error: updateError } = useTMutation(
        ['@updateNotice', 'U'],
        (data: NoticeRegistModel & { id: number }) => API.notice.updateNotice(data),
        (res: GenericResponse) => createNoticeSuccessCallback(res)
    )

    const { mutate: tryDel, error: delError } = useTMutation(
        ['@delNotice', 'D'],
        (id: number[]) => API.notice.deleteNotice(id),
        (res: GenericResponse) => deleteNoticeSuccessCallback(res)
    )
    const createNoticeSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '저장이 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_NOTICE)
            },
        })
    }
    const deleteNoticeSuccessCallback = (res: GenericResponse) => {
        Notice({
            type: 'alert',
            icon: 'notice_checked',
            content: '삭제가 완료되었습니다.',
            confirmHandler: () => {
                closeNotice()
                navigate(FIGK_PATH.FIGK_NOTICE)
            },
        })
    }

    const onChangeText = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, type: 'title' | 'content') => {
        setTextEditData((prev) => ({
            ...prev,
            [type]: e.target.value,
        }))
    }
    //수정시 새로고침 경고 알려주기 hook
    usePreventRefresh(edit)
    useWarnningGoBack(FIGK_PATH.FIGK_NOTICE, edit)

    // 수정하기 토글 함수
    const editToggleHandler = () => {
        setEdit(true)
        ScrollPositionTop()
    }

    useEffect(() => {
        getNoticeDetail()
    }, [])
    return (
        <Wrapper
            $form='detail'
            $whiteBoard
            radius={12}>
            <PageTitle title={edit ? '공지사항 수정' : '공지사항 상세'}>
                {!edit && (
                    <PageInfo>
                        <span> 등록일: {notice?.registeredAt}</span>
                        {notice?.updatedAt !== '' && <span>수정일: {notice?.updatedAt}</span>}
                    </PageInfo>
                )}
            </PageTitle>
            <Detail.Container>
                {/* <Detail.Title title='공지사항 정보' /> */}

                <Detail.Row>
                    <Detail.Col title='관리자 ID'>{notice?.adminEmail}</Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='제목'
                        required={edit}>
                        <InputText
                            readOnly={!edit}
                            maxLength={InputTitleLength}
                            value={textEditData.title}
                            onChange={(e) =>
                                setTextEditData((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                }))
                            }
                            name='title'
                        />
                    </Detail.Col>
                </Detail.Row>
                <Detail.Row>
                    <Detail.Col
                        title='내용'
                        required={edit}>
                        <TextEditor
                            disabled={edit}
                            initialValue={notice?.content}
                            value={textEditData.content}
                            onChange={(e) =>
                                setTextEditData((prev) => ({
                                    ...prev,
                                    content: e,
                                }))
                            }
                        />
                    </Detail.Col>
                </Detail.Row>
            </Detail.Container>

            <ButtonBox>
                {edit ? (
                    <>
                        <Button
                            $style='line_secondary'
                            onClick={() => {
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '변경사항이 저장되지 않습니다.',
                                    confirmHandler: () => {
                                        setEdit(false)
                                        closeNotice()
                                    },
                                })
                            }}>
                            돌아가기
                        </Button>

                        <Button
                            $style='solid_primary'
                            disabled={isEmptyObj(textEditData)}
                            onClick={() =>
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '변경한 내용으로 저장할까요?',
                                    confirm_button_text: '저장하기',
                                    confirmHandler: () => {
                                        tryUpdate(textEditData)
                                    },
                                })
                            }>
                            저장하기
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            $style='line_secondary'
                            disabled={isEmptyObj(textEditData)}
                            onClick={() =>
                                Notice({
                                    type: 'confirm',
                                    icon: 'notice_bang_mark',
                                    content: '해당 공지사항을 삭제하시겠습니까?',
                                    confirm_button_text: '삭제하기',
                                    confirmHandler: () => {
                                        tryDel([textEditData.id ?? -1])
                                    },
                                })
                            }>
                            삭제하기
                        </Button>

                        <Button
                            $style='line_primary'
                            onClick={editToggleHandler}>
                            수정하기
                        </Button>
                    </>
                )}
            </ButtonBox>
        </Wrapper>
    )
}

export default FigkNoticeDetail
