import { ComponentPropsWithRef } from 'react'
import { IStyledComponent } from 'styled-components'
import { Substitute } from 'styled-components/dist/types'
import { ButtonDisplayType, ButtonHeightType, ButtonStyleType, IButtonDisplay, IButtonHeight, IButtonStyle, StyledButton } from './index.styled'
interface ButtonProps
    extends ComponentPropsWithRef<
        IStyledComponent<
            'web',
            Substitute<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, IButtonHeight & IButtonStyle & IButtonDisplay>
        >
    > {
    type?: 'button' | 'submit' | 'reset'
    $size?: ButtonHeightType
    $style?: ButtonStyleType
    $display?: ButtonDisplayType
    $loading?: boolean
    as?: string
    children: React.ReactNode
}
const Button = ({ children, as, $style = 'solid_primary', $size = '44', $display = 'inline', $loading, type = 'button', ...rest }: ButtonProps) => {
    return (
        <StyledButton
            as={as}
            type={type}
            $size={$size}
            $style={$style}
            $display={$display}
            $loading={$loading}
            {...rest}>
            <span>{children}</span>
        </StyledButton>
    )
}

export default Button
