import { motion } from 'framer-motion'
import { styled } from 'styled-components'
import { mixin } from 'theme/mixin'
// 글로벌 모달 스타일
const GlobalContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
`

const GlobalContents = styled.div`
    position: relative;
    z-index: 10;
`

export const StyledGlobalModal = {
    GlobalContainer,
    GlobalContents,
}

//모달 컨텐츠 스타일

interface IModalContainer {
    $hegiht?: 'default' | 'auto'
}

const Container = styled.div<IModalContainer>`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 1;
    width: 520px;
    padding: 16px 20px 20px;
    border-radius: 12px;
    height: ${(p) => (p.$hegiht === 'auto' ? 'auto' : '540px')};
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-end: 3rem;
    .title {
        font-size: 1.8rem;
        font-weight: 600;
        color: ${(p) => p.theme.grayScale.gray9};
    }
`
const ModalScollBox = styled.div<{ $maxHeight?: string }>`
    position: relative;
    flex: 1;
    overflow: hidden auto;
    /* height: ${(p) => p.$maxHeight}; */
    ${mixin.ScrollStyle}
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 4rem;
`

export const Modal = {
    Container,
    Header,
    ModalScollBox,
    ButtonBox,
}
