import user from 'assets/img/user_account.svg'
import { styled } from 'styled-components'
import { mixin } from 'theme/mixin'
const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 2.4rem;
    border-bottom: 1px solid ${(p) => p.theme.line.line1};
    height: ${(p) => p.theme.layout.header_height};
    padding-inline: 3rem;
    background-color: #fff;

    box-shadow: 0px 0px 8px 0px rgba(113, 117, 126, 0.04);
`

const Contents = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`
const ProjectSelect = styled.div`
    position: relative;
`
const SelectValueBox = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
    span {
        font-size: 1.8rem;
        font-weight: 700;
        color: ${(p) => p.theme.grayScale.gray10};
    }
`
const SelectOptionBoxWrapper = styled.div``
const SelectOptionBox = styled.ul`
    --listHeight: 36px;
    --boxPaddingBlock: 8px;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 1111;
    width: 240px;
    border-radius: 12px;
    background-color: #fff;
    padding: 8px 4px;
    border: 1px solid ${(p) => p.theme.line.line1};
    box-shadow: 0 4px 8px 0 rgba(82, 86, 92, 0.05);
    overflow: hidden auto;
    max-height: calc(calc(var(--boxPaddingBlock) * 1.5) + calc(var(--listHeight) * 5));
    ${mixin.ScrollStyle}
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        padding-inline: 14px;

        cursor: pointer;

        span {
            font-weight: 400;
            font-size: 1.3rem;
            color: ${(p) => p.theme.grayScale.gray7};
        }
        svg {
            opacity: 0;
            flex-shrink: 0;
        }
        &.current {
            span {
                color: ${(p) => p.theme.grayScale.gray9};
                font-weight: 500;
            }
            svg {
                opacity: 1;
            }
        }
        &:hover {
            background-color: ${(p) => p.theme.color.primary_bg};
            span {
                color: ${(p) => p.theme.color.primary};
            }
        }
    }
`

export const LoginBox = styled.div`
    display: flex;
    align-items: center;
    gap: 2.4rem;
    .account {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-image: url(${user});
            background-position: center;
        }
        span {
            font-size: 1.3rem;
            color: ${(p) => p.theme.grayScale.gray6};
        }
    }
    > svg {
        &:hover {
            path {
                fill: ${(p) => p.theme.grayScale.gray7};
            }
        }
    }
`

export const S = {
    Container,
    Contents,
    ProjectSelect,
    SelectValueBox,
    SelectOptionBox,
    LoginBox,
}
