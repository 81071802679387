import { ComponentPropsWithRef } from 'react'
import { IStyledComponent } from 'styled-components'
import { Substitute } from 'styled-components/dist/types'
import { FontWeightType, FontWeightVariation, StatusColorType, StatusColorVariation } from 'theme/mixin'
import { S } from './index.styled'

interface IDetail extends ComponentPropsWithRef<'div'> {}
const Container = ({ ...rest }: IDetail) => {
    return <S.StyledContainer {...rest} />
}
interface IDetailTitle extends ComponentPropsWithRef<'div'> {
    title: string
    children?: React.ReactNode
}
const Title = ({ title, children, ...rest }: IDetailTitle) => {
    return (
        <S.StyledTitle {...rest}>
            <h3>{title}</h3>
            {children}
        </S.StyledTitle>
    )
}

export type SummaryTypes = {
    text: string
    data: string
    data_color?: StatusColorVariation
}
interface SummaryProps {
    summary: SummaryTypes[]
}
const Summary = ({ summary }: SummaryProps) => {
    return (
        <S.StyledSummary>
            {summary.map((_, index) => (
                <S.StyledSummaryItem
                    key={`sum_${index}`}
                    $color={_.data_color ?? 'darkgray'}>
                    {_.text}&nbsp;:&nbsp;<strong>{_.data}</strong>
                </S.StyledSummaryItem>
            ))}
        </S.StyledSummary>
    )
}

const Row = ({ ...rest }: IDetail) => {
    return (
        <S.StyledRow
            className='detail_row'
            {...rest}
        />
    )
}
interface IDetailCol
    extends ComponentPropsWithRef<
        IStyledComponent<
            'web',
            Substitute<
                React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
                StatusColorType & FontWeightType
            >
        >
    > {
    title: string
    note?: string
    children?: React.ReactNode
    color?: StatusColorVariation
    weight?: FontWeightVariation
    required?: boolean
}
const Col = ({ color = 'black', weight = '300', title, children, required, note, ...rest }: IDetailCol) => {
    return (
        <S.StyledCol
            className='detail_col'
            $color={color}
            $weight={weight}>
            <div className={`head ${required ? 'required' : ''}`}>
                {title} {note && <span className='note'>{note}</span>}{' '}
            </div>
            <div className='desc'>{children}</div>
        </S.StyledCol>
    )
}

export const Detail = {
    Container,
    Row,
    Col,
    Title,
    Summary,
}
