import API from 'api'
import InputText from 'components/atoms/Input/InputText'
import PageTitle from 'components/atoms/PageTitle'
import Pagination from 'components/atoms/Pagination'
import Select from 'components/atoms/Select'
import Summary from 'components/atoms/Summary'
import { Table, TableControlBox, TBody, Td, Th, THead, Tr } from 'components/atoms/Table'
import { Wrapper } from 'components/atoms/Wrapper/index.styled'
import LabelBox from 'components/molecules/LabelBox'
import TableNoData from 'components/molecules/Nodata/Table'
import Filter from 'components/oraganisms/Filter'
import RangeDatePicker from 'components/oraganisms/RangeDatePicker'
import { useDebounce } from 'hook/useDebounce'
import useGetQuery from 'hook/useTQuery'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { globalFilter, globalFilterReset } from 'recoil/atom/globalFilter'
import { TextFigkAppliedSelectData, TextFigkColWidth, TextFigkPublishSeleceData, TextFigkTh, 에디터특수기호변경 } from 'shared/common'
import { combinationSummary, isApplied, isAppliedColor, isPublished, isPublishedColor } from 'shared/function'
import { returnGroupInTextFigk, returnTextFigk } from 'shared/QueryOption/textFigk'
import { FIGK_PATH } from 'shared/Router/Figk'

const TotalTextFigk = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [filter, setFilter] = useRecoilState(globalFilter)
    const resetGlobalFilter = useResetRecoilState(globalFilter)
    const globalReset = useRecoilValue(globalFilterReset)

    const [word, setWord] = useState<string>(filter.word || '')

    const { data: textFigk, refetch: getTextFigk } = useGetQuery(['@getTextFigk'], () => API.textFigk.getTextFigk(filter), returnTextFigk)

    const { data: group } = useGetQuery(['@getTextFigkGroup'], () => API.textFigk.getGrouopInTextFigk(), returnGroupInTextFigk)
    const onChangeSelectFilter = (e: string | null, type: string) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            [type]: e,
        }))
    }

    const debounceWordInFilter = useCallback(
        useDebounce(
            (e: string) =>
                setFilter((prev) => ({
                    ...prev,
                    word: e,
                    page: 1,
                })),
            300
        ),
        []
    )

    const onChangeWordInFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value ?? ''
        setWord(val)

        debounceWordInFilter(val)
    }

    const setPage = (e: number) => {
        setFilter((prev) => ({
            ...prev,
            page: e,
        }))
    }

    useEffect(() => {
        getTextFigk()
    }, [filter])

    useEffect(() => {
        if (globalReset) {
            setWord('')
            resetGlobalFilter()
        }
    }, [globalReset])

    const defaultIndex = group ? group?.findIndex((i) => i.status === filter.groupId) : 0 ?? 0

    return (
        <>
            <PageTitle title='전체 글 관리' />

            <Summary summary={combinationSummary(textFigk?.summary)} />
            <Wrapper
                $form='main'
                $whiteBoard
                radius={12}>
                <Filter
                    search={
                        <InputText
                            activate_search
                            activate_delete
                            value={word}
                            onChange={onChangeWordInFilter}
                            placeholder='검색어를 입력해주세요.'
                        />
                    }>
                    <LabelBox label='발행 상태'>
                        <Select
                            options={TextFigkPublishSeleceData}
                            defaultStatus={filter.isPublished}
                            defaultV={isPublished(filter.isPublished || undefined)}
                            handler={(e) => onChangeSelectFilter(e, 'isPublished')}
                        />
                    </LabelBox>
                    <LabelBox label='송고 상태'>
                        <Select
                            defaultV={isApplied(filter.status || undefined)}
                            defaultStatus={filter.status}
                            options={TextFigkAppliedSelectData}
                            handler={(e) => onChangeSelectFilter(e, 'status')}
                        />
                    </LabelBox>
                    <LabelBox label='조별'>
                        <Select
                            defaultV={group && group[defaultIndex ?? 0] ? group[defaultIndex ?? 0].title ?? undefined : undefined}
                            options={group ?? [{ status: null, title: 'ALL' }]}
                            defaultStatus={filter.groupId}
                            handler={(e) => onChangeSelectFilter(e, 'groupId')}
                        />
                    </LabelBox>
                    <LabelBox label='기간'>
                        <RangeDatePicker filterStatus={globalFilter} />
                    </LabelBox>
                </Filter>
                <Table form='scroll'>
                    <colgroup>
                        {TextFigkColWidth.map((_, i) => {
                            return (
                                <col
                                    width={_}
                                    key={i}
                                />
                            )
                        })}
                    </colgroup>
                    <THead>
                        <Tr>
                            {TextFigkTh.map((_, i) => {
                                return <Th key={`textFigkTh_${i}`}>{_}</Th>
                            })}
                        </Tr>
                    </THead>
                    <TBody>
                        {textFigk?.list && textFigk?.list.length > 0 ? (
                            textFigk?.list.map((_, i) => {
                                return (
                                    <Tr
                                        key={`${_.id}_${i}`}
                                        onClick={() => navigate(`${FIGK_PATH.TOTAL}/${_.id}/${filter.page}`)}>
                                        <Td color={isPublishedColor(_.isPublished)}>{isPublished(_.isPublished)}</Td>
                                        <Td color={isAppliedColor(_.contestStatus)}>{isApplied(_.contestStatus)}</Td>
                                        <Td align='left'>{에디터특수기호변경(_.title)}</Td>

                                        <Td>{_.authorEmail}</Td>
                                        <Td>{_.authorNickName || _.authorName}</Td>
                                        <Td>{_.groupName}</Td>
                                        <Td color='date'>{_.updatedAt}</Td>
                                    </Tr>
                                )
                            })
                        ) : (
                            <TableNoData />
                        )}
                    </TBody>
                </Table>
                <TableControlBox>
                    <Pagination
                        currentPage={filter.page}
                        limit={filter.per}
                        setCurrentPage={setPage}
                        isLast={textFigk?.isLast || false}
                        totalItem={textFigk?.totalCount ?? 0}
                    />
                </TableControlBox>
            </Wrapper>
        </>
    )
}

export default TotalTextFigk
