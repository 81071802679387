import GlobalHeader from 'components/template/GlobalHeader'
import GlobalNav from 'components/template/GlobalNav'
import { useFigAuth } from 'figAuthProvider'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { toggleMenuValue } from 'recoil/atom'
import { styled } from 'styled-components'
const OutletContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1800px;
    margin-inline: auto;
    min-height: 100%;
    flex: 1;
`

const Dashboard = () => {
    const { user, logout } = useFigAuth()
    const setMenuActive = useSetRecoilState(toggleMenuValue)

    // const { data: project, refetch: getProjectList } = useGetQuery(['@getProjectListInDashboard'], () => API.project.getProject(), {
    //     ...returnProjectList,
    //     onSuccess: (data) => {
    //         const getProjectName = user ? data.map((i) => i.name)[0] : 'SelectProject'
    //         const getUserIncludeProject = user ? data.filter((i) => user.projectIds.includes(i.id)).map((i) => i.id) : [0]

    //         setStorage('pn', getProjectName)
    //         setStorage('project', getUserIncludeProject[0] + '')
    //         setMenuActive(getUserIncludeProject[0])
    //     },
    //     enabled: false,
    // })

    // const getValue = (e: ProjectListModel) => {
    //     setStorage('project', e.id + '')
    //     setStorage('pn', e.name)
    //     setSelect({
    //         isOpen: false,
    //         value: e,
    //     })
    //     setAtiveMenuValue(e.id)

    //     navigate('/dashboard')
    // }

    useEffect(() => {
        // getProjectList()
        setMenuActive(-1)
        window.scrollTo({ top: 0 })
    }, [])
    return (
        <>
            <GlobalHeader />
            <GlobalNav />
            <main id='globalMain'>
                <div id='outletWrapper'>
                    <div id='outlet'>
                        <OutletContent>
                            <Outlet />
                        </OutletContent>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Dashboard
