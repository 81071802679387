import { authApi } from 'api/Common'
import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { apiHeader } from 'shared/function'
const vote = {
    getVotingList: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk/vote${req}`)
        return response.data
    },

    getVoteResultList: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk/vote/result${req}`)
        return response.data
    },

    getVoteDetail: async (id: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk/vote?id=${id}`)
        return response.data
    },

    getPublishedList: async (data: SearchParamModel) => {
        const req = createUrlParam(data)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`text-figk/vote/judgeResult${req}`)
        return response.data
    },

    doVote: async (ids: number[]) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`text-figk/vote`, { votedFigk: ids })
        return response.data
    },

    doAudit: async (ids: number[]) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`text-figk/vote/judge`, { ids })
        return response.data
    },

    getVotedMan: async (s: 'O' | 'X') => {
        const response = await authApi.get<GenericResponse>(`text-figk/vote/checkvoter?type=${s}`)
        return response.data
    },

    getVoteLog: async (week: number) => {
        const response = await authApi.get<GenericResponse>(`text-figk/vote/log?week=${week}`)
        return response.data
    },
}

export default vote
