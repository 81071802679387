import API from "api";
import { AxiosError } from "axios";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import PageTitle from "components/atoms/PageTitle";
import { useFigAuth } from "figAuthProvider";
import { useModal } from "hook/useModal";
import useTMutation from "hook/useTMutation";
import useGetQuery from "hook/useTQuery";
import { GenericResponse } from "models/Common";
import { Fragment, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { returnVoting } from "shared/QueryOption/textFigk";
import { FIGK_PATH } from "shared/Router/Figk";
import {
  VOTE_AUDIT_LIMIT,
  VOTE_AUDIT_LIMIT2,
  에디터특수기호변경,
} from "shared/common";
import { turnNumbering } from "shared/function";
import { S } from "../AdminVoting/index.styled";
import useWidth from "hook/useWidth";

const AuditVoting = () => {
  const { media } = useWidth();
  const { user } = useFigAuth();
  const { Notice, closeNotice } = useModal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [active, setActive] = useState<number>(0);

  const [authorList, setAuthorList] = useState<number[]>([]);

  const { data: voteResult, refetch: getVote } = useGetQuery(
    ["@getVoteResultList"],
    () => API.vote.getVoteResultList(),
    returnVoting
  );
  const { mutate: doAudit, isError: voteError } = useTMutation(
    ["@DoAudit", "U"],
    (s: number[]) => API.vote.doAudit(s),
    (data: GenericResponse) => voteSuccessCallback(data),
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 409) {
          Notice({
            icon: "notice_checked",
            type: "alert",
            content: "이미 심사에 참여했습니다.",
          });
        }
      },
    }
  );

  const voteSuccessCallback = (res: GenericResponse) => {
    if (res.code === 200) {
      Notice({
        icon: "notice_checked",
        type: "alert",
        content: "심사가 완료되었습니다.",
        confirmHandler: () => {
          queryClient.invalidateQueries(["@getTextFigk"]);
          closeNotice();
          navigate(`${FIGK_PATH.SENDING}`);
        },
      });
    }
  };

  const tryAudit = () => {
    if (
      voteResult &&
      (authorList.length === VOTE_AUDIT_LIMIT ||
        authorList.length === VOTE_AUDIT_LIMIT2)
    ) {
      doAudit(authorList);
    } else {
      Notice({
        icon: "notice_checked",
        type: "alert",
        content: "당선 수를 다시 확인해 주세요.",
      });
    }
  };
  const listActiveHandler = (idx: number) => {
    setActive(idx);
    if (active === idx) {
      setActive(0);
    }
  };

  const pushToAuthorList = (
    e: React.MouseEvent<HTMLButtonElement>,
    i: number
  ) => {
    e.stopPropagation();
    // if (voteResult && authorList.length >= VOTE_AUDIT_LIMIT) return

    let prev = [...authorList];

    prev.push(i);

    if (authorList.includes(i)) {
      setAuthorList(prev.filter((_) => _ !== i));
      return;
    }

    setAuthorList(prev.filter((v, i) => prev.findIndex((x) => x === v) === i));
  };

  const completeAuditingHandler = () => {
    Notice({
      icon: "notice_checked",
      type: "alert",
      content: "심사결과 반영이 완료되었습니다.",
    });
  };

  return (
    <S.RootContainer>
      <div className="pagetitle__wrapepr">
        <PageTitle
          title={`Vol.${turnNumbering(user?.textWeek)} ${
            user?.groupName
          }조 심사`}
        />
      </div>
      <S.ListControlBox>
        <S.VotingCounter>
          당선작 선택 :
          <span>
            <strong>{authorList.length}</strong> / {VOTE_AUDIT_LIMIT}개
          </span>
        </S.VotingCounter>
        <Button
          $size="36"
          disabled={
            (voteResult && authorList.length === VOTE_AUDIT_LIMIT) ||
            (voteResult && authorList.length === 12)
              ? false
              : true
          }
          $style="solid_secondary"
          onClick={() =>
            Notice({
              type: "confirm",
              title: "심사결과를 반영하시겠습니까?",
              content: "반영 후에는 수정이 불가능합니다.",
              confirm_button_text: "반영하기",
              confirmHandler: () => tryAudit(),
            })
          }
        >
          심사결과 반영하기
        </Button>
      </S.ListControlBox>
      <S.ListBox>
        {voteResult?.list.map((_, i) => {
          const ACTIVE = active === _.id;
          return (
            <S.AccordionContainer key={`vote_result_list_${i}`}>
              <S.AccordionHead onClick={() => listActiveHandler(_.id)}>
                <p className={`title ${ACTIVE ? "" : "ellipsis"}`}>
                  {에디터특수기호변경(_.title)}
                </p>
                <div className="right">
                  <span className="voted">{_.totalVote}표</span>
                  {/* 투표했을때 일때 버튼 $스타일 line_primary */}
                  <Button
                    type="button"
                    $size="30"
                    onClick={(e) => pushToAuthorList(e, _.id)}
                    disabled={
                      voteResult &&
                      authorList.length >= VOTE_AUDIT_LIMIT &&
                      !authorList.includes(_.id)
                    }
                    $style={
                      authorList.includes(_.id) ? "line_primary" : `line_toggle`
                    }
                  >
                    발행하기
                  </Button>
                  <div className="arrow__wrap">
                    <Icon icon={"arrow_14x14"} color="gray8" />
                  </div>
                </div>
              </S.AccordionHead>
              {ACTIVE && (
                <S.AccordionBody>
                  <a
                    href={_.link}
                    target="_blank"
                    rel="noreferrer"
                    className="subject"
                  >
                    {_.subTitle}
                  </a>
                  <p className="contents">{에디터특수기호변경(_.content)}</p>
                  <div className="tags">
                    {_.tag &&
                      _.tag.map((_, i) => {
                        return (
                          <Fragment key={`tag_${i}`}>
                            #{에디터특수기호변경(_.name)}{" "}
                          </Fragment>
                        );
                      })}
                  </div>
                  <span className="writer">
                    {에디터특수기호변경(_.authorName)}
                  </span>
                </S.AccordionBody>
              )}
              {media.tablet && (
                <S.MobileVotingButtonWrap className={ACTIVE ? "open" : ""}>
                  <Button
                    type="button"
                    $size="30"
                    onClick={(e) => pushToAuthorList(e, _.id)}
                    disabled={
                      voteResult &&
                      authorList.length >= VOTE_AUDIT_LIMIT &&
                      !authorList.includes(_.id)
                    }
                    $style={
                      authorList.includes(_.id) ? "line_primary" : `line_toggle`
                    }
                  >
                    발행하기
                  </Button>
                </S.MobileVotingButtonWrap>
              )}
            </S.AccordionContainer>
          );
        })}
      </S.ListBox>
    </S.RootContainer>
  );
};

export default AuditVoting;
