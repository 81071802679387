import axios from 'axios'
import { getCookie, getStorage } from 'util/storage'

// export const BASE_URL = 'http://3.38.254.98/api/'
// export const AUTH_URL = 'http://3.38.254.98/api/figk-admin'

// export const BASE_URL = 'http://localhost:3100/'
// export const AUTH_URL = 'http://localhost:3100/figk-admin'

export const BASE_URL = 'https://api.fig-zone.com/'
export const AUTH_URL = 'https://api.fig-zone.com/figk-admin'
//테스트 서버
// export const BASE_URL = "https://test.fig-zone.com/";
// export const AUTH_URL = "https://test.fig-zone.com/figk-admin";

// export const BASE_URL = 'http://192.168.110.83:3100'
// export const AUTH_URL = 'http://192.168.110.83:3100/figk-admin'

export const baseApi = axios.create({
    baseURL: BASE_URL,
})

export const adminApi = axios.create({
    baseURL: AUTH_URL,
})

export const uploadApi = axios.create({
    baseURL: AUTH_URL,
    headers: {
        Authorization: `Bearer ${getCookie('access') ?? ''}`,
        'Content-Type': 'multipart/form-data',
    },
})
export const authApi = axios.create({
    baseURL: AUTH_URL,
    // withCredentials: true,

    headers: {
        Authorization: `Bearer ${getStorage('refresh') ?? ''}`,
        'Content-Type': 'application/json',
    },
})

export const refreshApi = axios.create({
    baseURL: BASE_URL,
    // withCredentials: true,

    headers: {
        Authorization: `Bearer ${getStorage('refresh') ?? ''}`,
        'Content-Type': 'application/json',
    },
})

export const accessApi = axios.create({
    baseURL: BASE_URL,
    // withCredentials: true,

    headers: {
        Authorization: `Bearer ${getCookie('access') ?? ''}`,
        'Content-Type': 'application/json',
    },
})
