export const maskingFunc = {
    checkNull: function (str: string) {
        return typeof str == 'undefined' || str == null || str == ''
    },
    /*
      ※ 이메일 마스킹
      ex1) 원본 데이터 : abcdefg12345@naver.com
           변경 데이터 : ab**********@naver.com
      ex2) 원본 데이터 : abcdefg12345@naver.com
           변경 데이터 : ab**********@nav******
      */
    email: function (str: string) {
        let originStr = str ?? ''
        let emailStr: any = str.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        let strLength = 0

        if (this.checkNull(originStr) || this.checkNull(emailStr)) {
            return originStr
        } else {
            strLength = emailStr.toString().split('@')[0].length - 3

            // ex1) abcdefg12345@naver.com => ab**********@naver.com
            // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*');

            // ex2) abcdefg12345@naver.com => ab**********@nav******
            return originStr
                .toString()
                .replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*')
                .replace(/.{6}$/, '******')
        }
    },
    /* 
      ※ 휴대폰 번호 마스킹
      ex1) 원본 데이터 : 01012345678, 변경 데이터 : 010****5678
      ex2) 원본 데이터 : 010-1234-5678, 변경 데이터 : 010-****-5678
      ex3) 원본 데이터 : 0111234567, 변경 데이터 : 011***4567
      ex4) 원본 데이터 : 011-123-4567, 변경 데이터 : 011-***-4567
      */
    phone: function (str?: string) {
        let originStr = str ?? ''
        let phoneStr: any = ''
        let maskingStr = ''

        if (this.checkNull(originStr)) {
            return originStr
        }

        if (originStr.toString().split('-').length != 3) {
            // 1) -가 없는 경우
            phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi)
            if (this.checkNull(phoneStr)) {
                return originStr
            }

            if (originStr.length < 11) {
                // 1.1) 0110000000
                maskingStr = originStr
                    .toString()
                    .replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi, '$1-XXXX-$3'))
            } else {
                // 1.2) 01000000000
                maskingStr = originStr
                    .toString()
                    .replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi, '$1-XXXX-$3'))
            }
        } else {
            // 2) -가 있는 경우
            phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi)
            if (this.checkNull(phoneStr)) {
                return originStr
            }

            if (/-[0-9]{3}-/.test(phoneStr)) {
                // 2.1) 00-000-0000
                maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, '-XXX-'))
            } else if (/-[0-9]{4}-/.test(phoneStr)) {
                // 2.2) 00-0000-0000
                maskingStr = originStr
                    .toString()
                    .replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, '-XXXX-'))
            }
        }

        return maskingStr
    },

    name: function (str?: string | null) {
        if (str && str.length > 2) {
            var originName = str.split('')
            originName.forEach(function (name, i) {
                if (i === 0 || i === originName.length - 1) return
                originName[i] = '*'
            })
            var joinName = originName.join()
            return joinName.replace(/,/g, '')
        } else {
            var pattern = /.$/ // 정규식
            return str && str.replace(pattern, '*')
        }
    },
}
