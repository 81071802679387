import { Td, Tr } from 'components/atoms/Table'

const TableNoData = () => {
    return (
        <Tr className='nodata'>
            <Td color='gray'>등록된 데이터가 없습니다.</Td>
        </Tr>
    )
}

export default TableNoData
