import auth from "./Login";

import artFigk from "./ArtFigk";
import member from "./Member";
import notice from "./Notice";
import project from "./Project";
import tag from "./Tag";
import term from "./Term";
import textFigk from "./TextFigk";
import sending from "./TextFigk/Sending";
import vote from "./TextFigk/Vote";
import uploadImg from "./TextFigk/UploadImg";
import settlement from "./Settlement";

const API = {
  auth,
  member,
  notice,
  term,
  project,
  textFigk,
  sending,
  artFigk,
  vote,
  tag,
  uploadImg,
  settlement,
};

export default API;
