import createUrlParam from 'hook/useCreateUrlParam'
import { GenericResponse, SearchParamModel } from 'models/Common'
import { NoticeRegistModel } from 'models/Notice'
import { apiHeader } from 'shared/function'
const notice = {
    getNotice: async (param?: SearchParamModel) => {
        const req = createUrlParam(param)
        const response = await apiHeader(true, 'a').get<GenericResponse>(`notice${req}`)
        return response.data
    },

    getNoticeDetail: async (i?: string) => {
        const response = await apiHeader(true, 'a').get<GenericResponse>(`notice?id=${i}`)
        return response.data
    },

    createNotice: async (req: NoticeRegistModel) => {
        const response = await apiHeader(true, 'a').post<GenericResponse>(`notice`, req)
        return response.data
    },

    updateNotice: async (req: NoticeRegistModel & { id: number }) => {
        const response = await apiHeader(true, 'a').put<GenericResponse>(`notice`, req)
        return response.data
    },

    deleteNotice: async (i: number[]) => {
        const response = await apiHeader(true, 'a').delete<GenericResponse>(`notice`, { data: { ids: i } })
        return response.data
    },
}

export default notice
