import { styled } from 'styled-components'

const Input = styled.input`
    display: none;
`
const Circle = styled.label`
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.2s ease;
    box-shadow: 0 4px 4px 0 rgba(82, 86, 92, 0.05);
    cursor: pointer;
`

const Box = styled.div`
    width: 36px;
    padding: 3px;
    border-radius: 999px;
    background-color: ${(p) => p.theme.button.disabled};

    &:has(input[type='checkbox']:checked) {
        background-color: rgba(123, 228, 73, 1);

        label {
            transform: translate3d(14px, 0, 0);
        }
    }
`

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
    span {
        font-size: 1.2rem;
        color: ${(p) => p.theme.grayScale.gray5};
        line-height: 1;
        transform: translate3d(0, 0.5px, 0);
        &.checked {
            color: ${(p) => p.theme.grayScale.gray7};
        }
    }
`

export const S = {
    Container,
    Box,
    Input,
    Circle,
}
