import { AnimatePresence } from 'framer-motion'
import { useModal } from 'hook/useModal'
// import { variants } from "shared/variants";
import { Dim } from 'components/atoms/Dim'
import { variants } from 'theme/motion'
import { StyledGlobalModal } from './index.styled'

const GlobalModal = () => {
    const { modalDataState, closeModal } = useModal()

    return (
        <AnimatePresence>
            {modalDataState.isOpen && (
                <StyledGlobalModal.GlobalContainer
                    variants={variants.fadeInOut}
                    initial='initial'
                    animate='animate'
                    exit='exit'>
                    <Dim onClick={closeModal} />
                    <StyledGlobalModal.GlobalContents>{modalDataState.content}</StyledGlobalModal.GlobalContents>
                </StyledGlobalModal.GlobalContainer>
            )}
        </AnimatePresence>
    )
}

export default GlobalModal
